import React, { useEffect, useState } from "react";

import { Table } from "ant-table-extensions";
import { Button } from "antd";
import _ from "lodash";
import NumberFormat from "react-number-format";
import styled from "styled-components";
import AddContractItems from "./AddContractItems";
import EditContractItems from "./EditContractItems";

const locale = "pt-BR";
function currencyFormatter(data) {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: "BRL",
  }).format(data);
}

export const PageFooter = styled.div`
  width: 100%;
  height: 100px;
`;

const ContractItemsTable = ({ contractItems }) => {
  const [isModalVisable, setIsModalVisible] = useState(false);
  const [toBeEdit, setToBeEdit] = useState([]);
  const columns = [
    {
      title: "Descrição",
      align: "left",
      render: (data) => (
        <div>
          <div>{data.description}</div>
          <div style={{ fontSize: "12px" }}>{data.details}</div>
          <div style={{ fontSize: "10px" }}>{data.id}</div>
        </div>
      ),
    },
    {
      title: "Adicionado em",
      dataIndex: "addedAt",
      key: "addedAt",
      align: "center",
      render: (data) => <div>{data}</div>,
    },

    {
      title: "Unidade",
      dataIndex: "unit",
      key: "unit",
      align: "center",
    },
    {
      title: "Quantidade",
      dataIndex: "quantity",
      key: "quantity",
      align: "right",
      render: (data) => (
        <NumberFormat
          thousandsGroupStyle="thousand"
          value={data}
          decimalSeparator=","
          thousandSeparator={"."}
          type="text"
          displayType="text"
          decimalScale={2}
          allowNegative={false}
          fixedDecimalScale={true}
        />
      ),
    },
    {
      title: "Realizado",
      dataIndex: "totalInvoicedQuantity",
      key: "totalInvoicedQuantity",
      align: "right",
      render: (data) => (
        <NumberFormat
          thousandsGroupStyle="thousand"
          value={data}
          decimalSeparator=","
          thousandSeparator={"."}
          type="text"
          displayType="text"
          decimalScale={2}
          allowNegative={false}
          fixedDecimalScale={true}
        />
      ),
    },
    {
      title: "Valor Unitário",
      dataIndex: "unitValue",
      key: "unitValue",
      align: "right",
      render: (data) => <div>{currencyFormatter(data)}</div>,
    },
    {
      title: "Valor Total",
      dataIndex: "totalValue",
      key: "totalValue",
      align: "right",
      render: (data) => <div>{currencyFormatter(data)}</div>,
    },
    {
      title: "Saldo de Contrato",
      dataIndex: "balance",
      key: "balance",
      align: "right",
      render: (data) => <div>{currencyFormatter(data)}</div>,
    },
    {
      title: "Action",
      align: "center",
      render: (data) => (
        <Button size="small" onClick={() => handleTriggerEdit(data)}>
          Editar
        </Button>
      ),
    },
  ];

  function handleTriggerEdit(params) {
    setToBeEdit(params);
    setIsModalVisible(true);
  }
  function onEdit() {}
  return (
    <div>
      <div>
        <Table
          columns={columns}
          size="small"
          bordered={true}
          pagination={{ pageSize: 20 }}
          dataSource={contractItems}
        />
      </div>
      <EditContractItems
        toBeEdit={toBeEdit}
        visible={isModalVisable}
        onCreate={onEdit}
        onCancel={() => {
          setIsModalVisible(false);
        }}
      />
      <PageFooter />
    </div>
  );
};

const ContractItems = ({ initialState, contractId }) => {
  const [contractItems, setcontractItems] = useState([]);
  const [addItemVisible, setaddItemVisible] = useState(false);
  useEffect(() => {
    const finalItems = [];
    initialState.forEach((item) => {
      console.log(item);
      const totalInvoicedQuantity = _.sumBy(item.invoiced, "invoicedQuantity");
      const totalValue = item.unitValue * item.quantity;
      const balance = totalValue - totalInvoicedQuantity * item.unitValue;
      item.totalInvoicedQuantity = totalInvoicedQuantity;
      item.key = item.id;
      item.totalValue = totalValue;
      item.balance = balance;
      finalItems.push(item);
    });
    console.log(initialState);
    setcontractItems(finalItems);
  }, [initialState]);

  var headerStyle = {
    display: "flex",
    justifyContent: "right",
    alignItems: "right",
    padding: "15px",
  };

  const handleAddNewItem = () => {
    setaddItemVisible(true);
  };

  return (
    <div>
      <div style={headerStyle}>
        <Button onClick={() => handleAddNewItem()}>Adicionar Novo Item</Button>
      </div>
      <ContractItemsTable contractItems={contractItems} />
      <AddContractItems
        visible={addItemVisible}
        onClose={setaddItemVisible}
        contractId={contractId}
      />
    </div>
  );
};

export default ContractItems;
