import { gql } from "@apollo/client";

export const ADD_CREDIT_CONTRACT = gql`
  mutation AddCreditToContract($entryDate: date!, $credit: Int!, $contractId: uuid!) {
    insertOneImageUsageEntries(object: {entryDate: $entryDate, debit: 0, credit: $credit, contractId: $contractId, description: "creditDescription"}) {
      __typename
      id
    }
  }
`;

export const LOGOUT_ALL_OF_ACCOUNT = gql`
  mutation LogoutAccountUsers($accountId: uuid!) {
    logoutUsers(account_id: $accountId)
  }
`;

export const CREATE_ACCOUNT_WITH_CONTRACT = gql`
  mutation CreateAccountWithContract($object: accounts_insert_input!) {
    insertAccountsOne(object: $object) {
      __typename
      country_code
      created_at
      address_1
      address_2
      city
      created_by_id
      deleted_at
      deleted_by_id
      district
      document_number
      id
      legal_name
      name
      organization_id
      state
      type
      updated_at
      updated_by_id
      zip_code
      organization {
        __typename
        id
        name
      }
    }
  }
`;

export const UPDATE_STATUS = gql`
  mutation UpdateAccountStatus($id: uuid! $accountId: uuid!, $isActive: Boolean!, $reason: String!) {
    updateByPkAccountStatuses(pk_columns: {id: $id}, _set: {deleted_at: "now()"}) {
      deleted_at
      deleted_by_id
    }
    insertAccountStatusesOne(object: {is_active: $isActive, reason: $reason, account_id: $accountId}) {
      __typename
      id
    }
  }
`;

export const ACCOUNTS_SUBSCRIPTION = gql`
  subscription Accounts {
    accounts {
      __typename
      country_code
      created_at
      address_1
      address_2
      city
      created_by_id
      deleted_at
      deleted_by_id
      district
      document_number
      id
      legal_name
      name
      organization_id
      state
      type
      updated_at
      updated_by_id
      zip_code
      organization {
        name
      }
      current_status: accountStatuses(limit: 1, where: {deleted_at: {_is_null: true}}, order_by: {created_at: desc}) {
        __typename
        id
        is_active
        reason
      }
      active_contract: contracts(limit: 1, where: {ended_at: {_is_null: true}, deleted_at: {_is_null: true}}, order_by: {created_at: desc}) {
        __typename
        id
        started_at
        period_in_months
        seats_quota
        identifier
      }
      members_aggregate(where: {deletedAt: {_is_null: true}}) {
        aggregate {
          count
        }
      }
      projects_aggregate {
        aggregate {
          count
        }
      }
      organization {
        __typename
        id
        name
      }
      contracts_aggregate {
        aggregate {
          count
        }
        nodes {
          __typename
          id
          period_in_months
          imageUsageEntries_aggregate {
            aggregate {
              sum {
                credit
              }
            }
          }
        }
      }
    }
  }
`;

export const ACCOUNT_DETAIL_SUBSCRIPTION = gql`
  subscription AccountDetail($id: uuid!) {
    accountsByPk(id: $id) {
      country_code
      created_at
      address_1
      address_2
      city
      created_by_id
      deleted_at
      deleted_by_id
      district
      document_number
      id
      legal_name
      name
      organization_id
      state
      type
      updated_at
      updated_by_id
      zip_code
      members_aggregate(where: {deletedAt: {_is_null: true}}) {
        aggregate {
          count
        }
      }
      projects_aggregate {
        aggregate {
          count
        }
      }
      organization {
        id
        name
      }
      contracts_aggregate {
        aggregate {
          count
        }
      }
      current_status: accountStatuses(limit: 1, where: {deleted_at: {_is_null: true}}, order_by: {created_at: desc}) {
        __typename
        id
        is_active
        reason
      }
      accountStatuses(order_by: {created_at: desc}) {
        __typename
        id
        account_id
        is_active
        reason
        created_at
        deleted_at
        created_by {
          __typename
          id
          name
        }
        deleted_by {
          __typename
          id
          name
        }
      }
      active_contract: contracts(limit: 1, where: {ended_at: {_is_null: true}, deleted_at: {_is_null: true}}, order_by: {created_at: desc}) {
        __typename
        id
        started_at
        period_in_months
        processing_quota
        seats_quota
        identifier
        imageUsageEntries_aggregate(where: {entryDate: {_lte: "now()"}}) {
          __typename
          aggregate {
            sum {
              credit
              debit
            }
          }
        }
      }
      contracts {
        account_id
        created_at
        created_by_id
        deleted_at
        deleted_by_id
        ended_at
        exceeded_image_value
        exceeded_seat_value
        exceeded_storage_value
        id
        identifier
        period_in_months
        processing_quota
        seats_quota
        started_at
        storage_quota
        updated_at
        updated_by_id
        processings {
          cameraModel
          contract_id
          createdAt
          created_by_id
          deletedAt
          deleted_by_id
          flownAt
          gcpCustomHeight
          gcpHorizontalCrs
          gcpVerticalCrs
          hasGcp
          id
          location
          name
          processAfterUpload
          status
          submittedAt
          submitted_by_id
          supportNotes
          totalBytes
          totalImages
          totalPixels
          updatedAt
          updated_by_id
          userLocation
        }
      }
      members {
        accountId
        canExport
        createdAt
        deletedAt
        id
        role
        updatedAt
        userId
        user {
          email
          name
          invitation_accepted_at
        }
      }
    }
  }
`;
