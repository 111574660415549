import React from "react";
import { Table } from "ant-table-extensions";

const GCPsTable = ({ dataSource }) => {
  const columns = [
    {
      dataIndex: "label",
      title: "Label",
    },
    {
      title: "X",
      align: "center",
      dataIndex: "x",
    },
    {
      title: "Y",
      align: "center",
      dataIndex: "y",
    },
    {
      title: "Z",
      align: "center",
      dataIndex: "z",
    },
  ];

  const processGcps = (array) => {
    const finalArr = [];
    array.forEach((element) => {
      element.key = element.id;
      finalArr.push(element);
    });
    return finalArr;
  };
  return (
    <div style={{ marginTop: "10px" }}>
      <Table
        size="small"
        bordered={true}
        dataSource={processGcps(dataSource)}
        columns={columns}
        exportable
      />
    </div>
  );
};

export default GCPsTable;
