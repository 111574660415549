import { gql } from "@apollo/client";

export const MUTATION_REMOVE_INSTALLMENT_CONTRACT_ITEMS = gql`
  mutation MUTATION_REMOVE_INSTALLMENT_CONTRACT_ITEMS($id: uuid) {
    deleteContractInstallmentsItems(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const MUTATION_REMOVE_INSTALLMENT_AND_INSTALLMENT_ITEMS = gql`
  mutation MUTATION_REMOVE_INSTALLMENT_AND_INSTALLMENT_ITEMS($id: uuid) {
    deleteContractInstallmentsItems(
      where: { contractInstallmentId: { _eq: $id } }
    ) {
      affected_rows
    }
    deleteContractInstallments(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const currencyFormat = (value) => {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value);
};
