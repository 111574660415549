import { Button, Popover } from "antd";
import PropTypes from 'prop-types';
import React from 'react';

const IpPopover = ({ ip, locationData }) => {
  const Content = () => {
    return <div>
      <dt>Local</dt>
      <dd>{locationData?.city_name}, {locationData?.region_name}-{locationData?.country_code}</dd>
      <dt>Coordenada</dt>
      <dd>{locationData?.latitude} {locationData?.longitude}</dd>
      <dt>ISP</dt>
      <dd>{locationData?.as}</dd>
      <dt>Proxy ?</dt>
      <dd>{locationData?.is_proxy ? "Yes" : "No"}</dd>

    </div>
  }
  return <Popover content={Content} title={ip} trigger="click">
    <Button type="link">{ip}</Button>
  </Popover>
};

export default IpPopover;

IpPopover.propTypes = {
  ip: PropTypes.string.isRequired,
  locationData: PropTypes.object.isRequired
};
