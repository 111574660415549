import { Button, Col, Divider, Form, Input, InputNumber, Modal, Popconfirm, Row } from "antd";
import React, { useEffect, useState } from "react";

import { useMutation } from "@apollo/client";
import NumberFormat from "react-number-format";
import { EDIT_ITEMS_CONTRACT } from "./UtilsItems";

const EditContractItems = ({ visible, onCreate, onCancel, toBeEdit }) => {
  const [editItemsContract] =
    useMutation(EDIT_ITEMS_CONTRACT);
  const [maxValue, setMaxValue] = useState(0);
  const [minValue, setMinValue] = useState(0);
  const [itemData, setItemData] = useState([]);
  const [itemId, setItemId] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [totalValue, setTotalValue] = useState(0);
  const [mutationLoading, setMutationLoading] = useState(false);
  const [, setAlertState] = useState({
    message: "",
    type: "",
    visible: false,
    description: "",
  });
  const [masterFormControl, setMasterFormControl] = useState({
    enableToEdit: false,
    enableToSubmit: false,
    displayAlert: false,
    message: "",
  });

  useEffect(() => {
    setQuantity(toBeEdit.quantity);
  }, [toBeEdit]);

  useEffect(() => {
    const unitValue = toBeEdit.unitValue;
    setTotalValue(unitValue * quantity);
  }, [quantity, toBeEdit]);

  useEffect(() => {
    var cloned = Object.assign({}, toBeEdit);
    setItemData(cloned);
    setItemId(cloned.id);
    setMaxValue(cloned.quantity);
    setMinValue(cloned.totalInvoicedQuantity);
    if (maxValue === minValue) {
      setMasterFormControl({
        enableToEdit: false,
        enableToSubmit: false,
        displayAlert: true,
        message:
          "Este item não pode ser modificado pois já foi totalmente medido",
      });
    } else {
      if (toBeEdit.quantity === quantity) {
        setMasterFormControl({
          enableToEdit: true,
          enableToSubmit: false,
          displayAlert: false,
          message: "",
        });
      } else {
        setMasterFormControl({
          enableToEdit: true,
          enableToSubmit: true,
          displayAlert: false,
          message: "",
        });
      }
    }
  }, [toBeEdit, maxValue, minValue, quantity, totalValue]);

  function handleCancel() {
    onCancel();
  }

  const confirm = () => {
    var itemModObj = {
      quantity: quantity,
      id: itemId,
    };
    performMutation(itemModObj);
  };

  function performMutation(itemModObj) {
    setMutationLoading(true);
    editItemsContract({ variables: itemModObj })
      .then(({ data }) => {
        var alertObj = {
          message: "Sucesso na Criação",
          type: "success",
          visible: true,
          description: "Item Criado com Sucesso!",
        };
        setAlertState(alertObj);
        setTimeout(() => {
          var alertObj = {
            message: "",
            type: "",
            visible: false,
            description: "",
          };
          setAlertState(alertObj);
          setMutationLoading(false);
          handleCancel();
        }, 3000);
      })
      .catch((error) => {
        console.warn("there was an error sending the query", error);
        var alertObj = {
          message: "Erro na criação do Contato",
          type: "warning",
          visible: true,
          description: error.toString(),
        };
        setAlertState(alertObj);
        setTimeout(() => {
          // var alertObj = {
          //   message: "",
          //   type: "",
          //   visible: false,
          //   description: "",
          // };
          setMutationLoading(false);
        }, 3000);
      });
  }

  return (
    <div>
      <Modal
        title="Editar Item"
        visible={visible}
        width={800}
        onCancel={() => handleCancel()}
        okText="Editar Item"
        cancelText="Cancelar"
        footer={[
          <Button key="back" onClick={() => handleCancel()}>
            Voltar
          </Button>,
          <Popconfirm
            disabled={!masterFormControl.enableToSubmit}
            title="Confirma a modificação do item"
            onConfirm={confirm}
            onVisibleChange={() => console.log("visible change")}
          >
            <Button
              type="primary"
              disabled={!masterFormControl.enableToSubmit}
              loading={mutationLoading}
            >
              Submeter Modificações
            </Button>
          </Popconfirm>,
        ]}
      >
        <div
          style={{
            marginBottom: "15px",
            backgroundColor: "#fff2f0",
            border: "#ffccc7 1px solid",
            padding: "10px",
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
          }}
          hidden={masterFormControl.enableToEdit}
        >
          {masterFormControl.message}
        </div>
        <Form layout={"vertical"} autoComplete="off">
          <Form.Item label="Descrição do Item">
            <Input
              placeholder="Digite a Descrição"
              id={"description"}
              disabled={true}
              value={toBeEdit.description}
            />
          </Form.Item>
          <Row gutter={16}>
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Unit"
                style={{
                  margin: 0,
                }}
              >
                <Input
                  placeholder="Digite a Descrição"
                  id={"description"}
                  disabled={true}
                  value={toBeEdit.unit}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Quantity"
                style={{
                  margin: 0,
                }}
              >
                <InputNumber
                  min={minValue}
                  max={maxValue}
                  value={quantity}
                  disabled={!masterFormControl.enableToEdit}
                  style={{ width: "100%" }}
                  onChange={(e) => setQuantity(e)}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label="Valor Unitário"
                style={{
                  margin: 0,
                }}
                rules={[
                  {
                    required: true,
                    message: `Favor Digitar a quantidade`,
                  },
                ]}
              >
                <NumberFormat
                  id={"unitValue"}
                  customInput={Input}
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={2}
                  disabled={true}
                  value={toBeEdit.unitValue}
                  prefix={"R$ "}
                  style={{ textAlign: "right" }}
                  onValueChange={(values) => {
                    const { value } = values;
                    setItemData({
                      ...itemData,
                      unitValue: parseFloat(value),
                    });
                  }}
                  on
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Input
            style={{ textAlign: "right" }}
            addonBefore=" VALOR TOTAL"
            value={new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(totalValue)}
            disabled
          />
        </Form>
      </Modal>
    </div>
  );
};

export default EditContractItems;
