import { gql } from "@apollo/client";

export const ORGANIZATIONS_SUBSCRIPTION = gql`
  subscription OrganizationsSubscription {
    organizations {
      __typename
      id
      name
      updatedAt
      deletedById
      createdById
      createdAt
      deletedAt
      updatedById
    }
  }
`;

export const ORGANIZATIONS_QUERY = gql`
  query OrganizationsSubscription {
    organizations {
      __typename
      id
      name
      updatedAt
      deletedById
      createdById
      createdAt
      deletedAt
      updatedById
    }
  }
`;

export const CREATE_ORGANIZATION = gql`
  mutation NewOrganization($name: String!) {
    insertOrganizationsOne(object: {name: $name}) {
      __typename
      id
      name
    }
  }
`;
