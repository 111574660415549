import {
  ApartmentOutlined,
  DashboardOutlined,
  DownloadOutlined,
  FileDoneOutlined,
  GroupOutlined,
  UploadOutlined,
  UserOutlined
} from "@ant-design/icons";
import { Button, Dropdown, Layout, Menu, Space } from "antd";
import React, { useEffect, useState } from "react";
import {
  Link,
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
  useLocation
} from "react-router-dom";

import { DownOutlined } from "@ant-design/icons";
import { useAuth } from "./Auth";
import LogoMaply from "./assets/LogoMaply.svg";
import Logotipo from "./assets/Logotipo.svg";
import AccountDetail from "./components/Accounts/AccountDetail/AccountDetail";
import Accounts from "./components/Accounts/Accounts";
import AdminUserSettings from "./components/AdminUserSettings/AdminUserSettings";
import ContractDetail from "./components/Contracts/ContractDetails/ContractDetail";
import Dashboard from "./components/Dashboard/Dashboard";
import Exports from "./components/Exports/Exports";
import ExportsDetail from "./components/Exports/ExportsDetail/ExportsDetail";
import FlightPlans from "./components/FlightPlans/FlightPlans";
import Organizations from "./components/Organizations/Organizations";
import ProcessingDetail from "./components/ProcessingDetail/ProcessingDetail";
import ProcessingLogs from "./components/ProcessingLogs/ProcessingLogs";
import ProcessingMap from "./components/ProcessingMap/ProcessingMap";
import ProcessingMonitoring from "./components/ProcessingMonitoring/ProcessingMonitoring";
import Users from "./components/Users/Users";

const { Header, Sider } = Layout;
// const { SubMenu } = Menu;

const LogoMaplyComponent = ({ collapsed }) => {
  return (
    <div className="logo">
      <div>
        {!collapsed && (
          <div>
            <img
              src={LogoMaply}
              alt="React Logo"
              style={{
                width: "100px",
                verticalAlign: "middle",
                lineHeight: "normal",
                marginLeft: "auto",
                marginRight: "auto",
                display: "block",
                padding: "5px",
              }}
            />
          </div>
        )}
        {collapsed && (
          <div>
            <img
              src={Logotipo}
              alt="React Logo"
              style={{
                width: "25px",
                verticalAlign: "middle",
                lineHeight: "normal",
                marginLeft: "auto",
                marginRight: "auto",
                display: "block",
                paddingTop: "3px",
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const ProfileDropDown = ({ loggedUser, setVisible }) => {
  const { logout } = useAuth();
  const [userEmail, setuserEmail] = useState("");
  const menu = (
    <Menu>
      <Menu.Item key="adminUserSettings" onClick={() => setVisible(true)}>
        Admin User Settings
      </Menu.Item>
      <Menu.Item key="logOut" danger onClick={() => logout()}>
        Log Out
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    var email;
    try {
      email = loggedUser.email;
    } catch (e) {
      email = "";
    }
    setuserEmail(email);
  }, [loggedUser]);
  return (
    <div>
      <Space>
        <Dropdown overlay={menu}>
          <Button>
            {userEmail}
            <DownOutlined></DownOutlined>
          </Button>
        </Dropdown>
      </Space>
    </div>
  );
};

const MainPage = ({ loggedUser }) => {
  const [visible, setVisible] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [, setPathname] = useState("");
  const location = useLocation();

  useEffect(() => {
    setPathname(location.pathname);
  }, [location, collapsed]);

  return (
    <div>
      <Router>
        <Layout style={{ minHeight: "100vh" }}>
          <Sider
            collapsible
            width={280}
            collapsed={collapsed}
            onCollapse={() => setCollapsed(!collapsed)}
          >
            <LogoMaplyComponent collapsed={collapsed} />
            <Menu theme="dark" mode="inline" defaultSelectedKeys={["4"]}>
              <Menu.Item key="menu_dashboard" icon={<UserOutlined />}>
                <Link to="/dashboard">Dashboard</Link>
              </Menu.Item>
              <Menu.Item
                key="menu_processing_monutoring"
                icon={<DashboardOutlined />}
              >
                <Link to="/processingmonitoring"> Processing Monitoring</Link>
              </Menu.Item>
              <Menu.Item key="menu_processing_logs" icon={<UploadOutlined />}>
                <Link to="/processinglogs">Processing Logs</Link>
              </Menu.Item>
              <Menu.Item key="menu_flight_plans" icon={<GroupOutlined />}>
                <Link to="/flightplans"> Flight Plans</Link>
              </Menu.Item>
              <Menu.Item key="menu_users" icon={<UserOutlined />}>
                <Link to="/users">Users</Link>
              </Menu.Item>
              <Menu.Item key="menu_accounts" icon={<FileDoneOutlined />}>
                <Link to="/accounts">Accounts</Link>
              </Menu.Item>
              <Menu.Item key="menu_organizations" icon={<ApartmentOutlined />}>
                <Link to="/organizations">Organizations</Link>
              </Menu.Item>
              <Menu.Item key="menu_exports" icon={<DownloadOutlined />}>
                <Link to="/exports">Exports</Link>
              </Menu.Item>
            </Menu>
          </Sider>
          <AdminUserSettings
            visible={visible}
            setVisible={setVisible}
            userData={loggedUser}
          />
          <Layout className="site-layout">
            <Header className="site-layout-background" style={{ padding: 0 }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "right",
                  alignItems: "right",
                  marginRight: "25px",
                }}
              >
                <ProfileDropDown
                  loggedUser={loggedUser}
                  setVisible={setVisible}
                />
              </div>
            </Header>
            <Switch>
              <Route
                exact
                path="/"
                render={() => {
                  return loggedUser.email === "" ? (
                    <Redirect to="/login" />
                  ) : (
                    <Redirect to="/dashboard" />
                  );
                }}
              />
              <Route path="/dashboard" component={Dashboard} />
              <Route
                path="/processingmonitoring"
                component={ProcessingMonitoring}
              />
              <Route path="/processinglogs" component={ProcessingLogs} />
              <Route path="/flightplans" component={FlightPlans} />
              <Route path="/users" component={Users} />
              <Route path="/accounts" component={Accounts} />
              <Route path="/organizations" component={Organizations} />
              <Route path="/exports" component={Exports} />
              <Route
                path="/view-processing-details/:id"
                component={ProcessingDetail}
              />
              <Route
                path="/view-account-details/:id"
                component={AccountDetail}
              />
              <Route
                path="/view-processing-map/:id"
                component={ProcessingMap}
              />
              <Route
                path="/view-export-details/:id"
                component={ExportsDetail}
              />
              <Route
                path="/view-contract-details/:id"
                component={ContractDetail}
              />
            </Switch>
          </Layout>
        </Layout>
      </Router>
    </div>
  );
};

export default MainPage;
