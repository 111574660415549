import "antd/dist/antd.min.css";

import React from "react";
import { Redirect, Route, BrowserRouter as Router, Switch } from "react-router-dom";

import { ConfigProvider } from "antd";
import { AuthProvider, LoadingApp } from "./Auth";
import GraphqlProvider from "./Auth/GraphQLProvider";
import Login from "./Login";
import MainPage from "./MainPage";

import * as firebase from "firebase/app";
import "firebase/auth";
import Config from './config';
import RequireAuthentication from './routes/RequireAuthentication';
import RequireUnauthentication from './routes/RequireUnauthentication';
import { AUTH, HOME, LOGIN, ROOT } from './routes/constants';

ConfigProvider.config({
  theme: {
    primaryColor: "#007AFF",
    errorColor: "#ff4d4f",
    warningColor: "#faad14",
    successColor: "#52c41a",
    infoColor: "#1890ff",
  },
});

const firebaseApp = firebase.initializeApp(Config.FIREBASE_CONFIG);

const App = () => {

  return firebaseApp ?
    <ConfigProvider direction="ltr">
      <GraphqlProvider firebaseApp={firebaseApp}>
        {props => (
          <AuthProvider {...props} firebaseApp={firebaseApp}>
            {({ user, token, loading }) => {
              return (<Router>
                <Switch>
                  <Route
                    path={AUTH}
                    render={() => (
                      <RequireUnauthentication>
                        <Switch>
                          <Route exact path={LOGIN}>
                            <Login />
                          </Route>
                          <Route exact path={AUTH} render={() => <Redirect to={LOGIN} />} />
                        </Switch>
                      </RequireUnauthentication>
                    )}
                  />
                  <Route
                    path={ROOT}
                    render={(props) => {
                      const { location } = props;
                      const queryParams = new URLSearchParams(location.search);
                      const from = queryParams.get('next') || location;

                      if (loading) {
                        return <LoadingApp />
                      }

                      return (
                        <RequireAuthentication redirectTo={LOGIN} from={from}>
                          <Route path={HOME} render={() => <div>
                            <MainPage loggedUser={user} />
                          </div>} />
                        </RequireAuthentication>
                      )
                    }}
                  />
                </Switch>
              </Router>)
            }}
          </AuthProvider>
        )}
      </GraphqlProvider>
    </ConfigProvider>
  :
    <div style="height: 100%; width: 100%; display: flex; align-items: center; justify-content: center;">Carregando...</div>;
};

export default App;
