import React, { useContext } from 'react';
import { Redirect } from "react-router";
import { AuthContext } from '../Auth';

function RequireAuthentication({ children, redirectTo, from }) {
  const { currentUser, pending } = useContext(AuthContext);

  if (pending) {
    return null;
  }

  return !!currentUser ? children : <Redirect
    to={{
      pathname: redirectTo,
      state: { from },
    }}
  />;
}

export default RequireAuthentication;
