import { useState } from "react";
import ReactMapGL, { Layer, Source } from "react-map-gl";

import React from "react";

const MAPBOX_ACCESS_TOKEN =
  "pk.eyJ1IjoiZ2Vvcmdpb3MtdWJlciIsImEiOiJjanZidTZzczAwajMxNGVwOGZrd2E5NG90In0.gdsRu_UeU_uPi9IulBruXA";
const MapExports = ({ dataSource }) => {
  const [viewport, setViewport] = useState({
    latitude: -3.89259309160519,
    longitude: -38.4796039651976,
    zoom: 16,
    bearing: 0,
    pitch: 0,
  });

  const dataLayer = {
    id: "data",
    type: "fill",
    paint: {
      "fill-color": "blue",
      "fill-opacity": 0.4,
      "fill-outline-color": "red",
    },
  };

  return (
    <div>
      <ReactMapGL
        {...viewport}
        width="100%"
        height="800px"
        mapStyle="mapbox://styles/mapbox/satellite-v9"
        onViewportChange={setViewport}
        mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
      >
        <Source type="geojson" data={dataSource.cropArea}>
          <Layer {...dataLayer} />
        </Source>
      </ReactMapGL>
    </div>
  );
};

export default MapExports;
