import { Col, Row, Skeleton } from "antd";
import moment from "moment";
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from "react";
import { durationAsString } from "./ProcessingsUtils";

const ElapseTimeComponent = ({ dateTimeStarted, realtime = false, dateTimeFinished = null }) => {
  const [elapsedTime, setElapsedTime] = useState("");
  const [loaded, setLoaded] = useState(false);
  const interval = useRef();

  const setTime = (started, finished) => {
    const etime = durationAsString(started, finished);
    setElapsedTime(etime);
    setLoaded(true);
  }

  useEffect(() => {
    if (!dateTimeFinished) {
      if (realtime) {
        interval.current = setInterval(() => {
          setTime(moment(dateTimeStarted), moment());
        }, 1000);
      } else {
        setTime(moment(dateTimeStarted), moment());
      }
    } else {
      setTime(moment(dateTimeStarted), moment());
    }
    return () => {
      clearInterval(interval.current);
    };
  }, [dateTimeStarted, dateTimeFinished, realtime]);
  return (
    <div>
      {!loaded && (
        <Skeleton.Input style={{ width: 200 }} active={true} size={"small"} />
      )}
      {loaded && (
        <Row style={{ width: "180px" }} justify={"center"}>
          <Col span={6} sm>
            {elapsedTime.d} d
          </Col>
          <Col span={6} sm>
            {elapsedTime.h} h
          </Col>
          <Col span={6} sm>
            {elapsedTime.m} m
          </Col>
          <Col span={6} sm>
            {elapsedTime.s} s
          </Col>
        </Row>
      )}
    </div>
  );
};

ElapseTimeComponent.propTypes = {
  dateTimeStarted: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]).isRequired,
  realtime: PropTypes.bool,
  dateTimeFinished: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ])
}

export default ElapseTimeComponent;
