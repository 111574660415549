import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import {
  Button, Col, Descriptions, Divider,
  Drawer,
  Input, Modal, Row, Skeleton
} from "antd";

import { useQuery } from "@apollo/client";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { ORGANIZATION_DETAIL_SUBSCRIPTION } from "./OrganizationDetailQueries";

const DrawerContent = ({ organizationData }) => {
  const [nameEditDisabled, setNameEditDisabled] = useState(true);
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [newName, setNewName] = useState("");
  const handleToggleEdit = () => {
    console.log(nameEditDisabled);
    setNameEditDisabled(!nameEditDisabled);
  };
  const handleCancelEdit = () => {
    setNewName(organizationData.name);
    setNameEditDisabled(!nameEditDisabled);
  };
  const handleEditSubmitConfirmation = () => {
    setNameEditDisabled(!nameEditDisabled);
    setOpen(true);
  };

  useEffect(() => {
    console.log(organizationData);
    setNewName(organizationData.name);
  }, [organizationData]);

  const handleOk = () => {
    /// Aqui será disparada a Mutation
    var nameChangeObj = {
      id: organizationData.id,
      oldName: organizationData.name,
      newName: newName,
    };
    if (nameChangeObj.oldName === nameChangeObj.newName) {
      alert("Nothing to edit");
      setTimeout(() => {
        console.log();
        setOpen(false);
        setConfirmLoading(false);
      }, 500);
    } else {
      setConfirmLoading(true);
      setTimeout(() => {
        console.log();
        setOpen(false);
        setConfirmLoading(false);
      }, 2000);
    }
  };
  const handleCancel = () => {
    console.log("Clicked cancel button");
    setOpen(false);
  };

  return (
    <div>
      <div>Name</div>
      <Row>
        <Col span={18}>
          <Input
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            disabled={nameEditDisabled}
          />
        </Col>
        <Col span={6}>
          <Button
            hidden={!nameEditDisabled}
            onClick={(nameEditDisabled) => handleToggleEdit(!nameEditDisabled)}
          >
            Edit
          </Button>
          <Button
            icon={<CloseOutlined />}
            hidden={nameEditDisabled}
            onClick={(nameEditDisabled) => handleCancelEdit(!nameEditDisabled)}
          />
          <Button
            icon={<CheckOutlined />}
            type="primary"
            onClick={(nameEditDisabled) =>
              handleEditSubmitConfirmation(!nameEditDisabled)
            }
            hidden={nameEditDisabled}
          />
        </Col>
      </Row>
      <Divider></Divider>
      <Descriptions size="small" column={1} bordered={true}>
        <Descriptions.Item label={"Id"}>
          {organizationData.id}
        </Descriptions.Item>
        <Descriptions.Item label={"Created At"}>
          {moment(organizationData.created_at).format("DD/MM/YYYY - HH:mm:ss")}
        </Descriptions.Item>
      </Descriptions>
      <Modal
        title="Confirm changes in organization Name?"
        visible={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <div>{newName}</div>
      </Modal>
    </div>
  );
};

const OrganizationDetail = ({
  isDrawerVisible,
  setDrawerVisible,
  organizationId,
}) => {
  const { loading, data } = useQuery(ORGANIZATION_DETAIL_SUBSCRIPTION, {
    variables: { id: organizationId },
  });
  const onClose = () => {
    setDrawerVisible(false);
    // setdataLoaded(false);
  };

  return (
    <div>
      <Drawer
        title="Organization Detailed Information"
        placement="right"
        onClose={onClose}
        width={640}
        visible={isDrawerVisible}
      >
        {loading && (
          <div>
            <Skeleton />
          </div>
        )}
        {!loading && <DrawerContent organizationData={data.organizations[0]} />}
      </Drawer>
    </div>
  );
};

export default OrganizationDetail;
