import { gql } from "@apollo/client";

export const ORGANIZATION_DETAIL_SUBSCRIPTION = gql`
  query OrganizationDetailSubscription($id: uuid) {
    organizations(where: { id: { _eq: $id } }) {
      id
      name
      updated_at
      deleted_by_id
      created_by_id
      created_at
      deleted_at
      updated_by_id
    }
  }
`;
