import { gql, useMutation } from "@apollo/client";
import { Button, InputNumber, Popconfirm } from "antd";
import React, { useEffect, useState } from "react";

import { Table } from "ant-table-extensions";
import { message, notification } from "antd";
import Modal from "antd/lib/modal/Modal";
import Select from "react-select";
import { MUTATION_REMOVE_INSTALLMENT_CONTRACT_ITEMS } from "./InstallmentsUtils";

const INSERT_INSTALLMENT_ITEM = gql`
  mutation INSERT_INSTALLMENT_ITEM(
    $scheduledQuantity: numeric
    $contractItemId: uuid
    $contractInstallmentId: uuid
    $contractId: uuid
  ) {
    insertContractInstallmentsItems(
      objects: {
        contractId: $contractId
        contractInstallmentId: $contractInstallmentId
        contractItemId: $contractItemId
        scheduledQuantity: $scheduledQuantity
      }
    ) {
      affected_rows
    }
  }
`;

const currencyFormat = (value) => {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value);
};

const InstallmentContractItemsTable = ({ dataSource }) => {
  // const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const [removeInstallmentContractItems] =
    useMutation(MUTATION_REMOVE_INSTALLMENT_CONTRACT_ITEMS);

  const resetStates = () => {};

  function cancel(e) {
    console.log(e);
    message.error("Click on No");
  }
  const HandleRemoveRow = (data) => {
    setConfirmLoading(true);
    var deleteObj = { id: data.id };
    removeInstallmentContractItems({ variables: deleteObj })
      .then(({ data }) => {
        setTimeout(() => {
          setVisible(false);
          setConfirmLoading(false);
        }, 2000);
      })
      .catch((error) => {
        console.warn("there was an error sending the query", error);
        resetStates();
      });
  };
  const columns = [
    {
      title: "Descrição",
      align: "left",
      render: (data) => (
        <div>
          <div>{data.contractItem.description}</div>
          <div style={{ fontSize: "12px" }}>{data.contractItem.details}</div>
          <div style={{ fontSize: "10px" }}>{data.contractItem.id}</div>
        </div>
      ),
    },
    {
      title: "Quantidade Agendada",
      align: "center",
      render: (data) => <div>{data.scheduledQuantity}</div>,
    },
    {
      title: "Valor Agendado",
      align: "center",
      render: (data) => (
        <div>
          {currencyFormat(data.scheduledQuantity * data.contractItem.unitValue)}
        </div>
      ),
    },
    {
      title: "Ações",
      align: "center",
      render: (data) => (
        <div>
          <Popconfirm
            title="Tem certeza que deseja remover este item?"
            visible={visible}
            onConfirm={() => HandleRemoveRow(data)}
            okButtonProps={{ loading: confirmLoading }}
            onCancel={cancel}
          >
            <button onClick={() => setVisible(true)}>Remover</button>
            {/* <a href="#" onClick={() => setVisible(true)}>Remover</a> */}
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Table
        bordered={true}
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        size="small"
      />
    </div>
  );
};

const InstallmentContractItems = ({
  installmentObject,
  contractItems,
  contractId
}) => {
  const [insertInstallmentItem] = useMutation(
    INSERT_INSTALLMENT_ITEM
  );
  const [mutationLoading, setMutationLoading] = useState(false);

  const [selectedOption, setSelectedOption] = useState([]);
  const [options, setOptions] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [maxValue, setMaxValue] = useState(0);
  const [minValue, setMinValue] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [contractItemId, setContractItemId] = useState("");
  const [, setSubmitFormControl] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const resetStates = () => {
    setMutationLoading(false);
    setMaxValue(1);
    setQuantity();
    setSelectedOption([]);
    setMinValue(1);
  };

  const openNotification = (data) => {
    notification.open({
      message: "Sucesso!",
      type: "success",
      description: "A parcela foi inserida com sucesso!",
      onClick: () => {},
    });
  };
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    resetStates();
  };

  const handleQuantityChange = (e) => {
    setQuantity(e);
  };

  useEffect(() => {
    var finalItems = [];
    console.log(contractItems);
    contractItems.forEach((item) => {
      if (item.maxValue !== 0) {
        finalItems.push({
          label: `${item.description} - ${item.details}`,
          value: item.id,
          key: item.id,
        });
      }
    });
    setOptions(finalItems);
  }, [contractItems]);

  useEffect(() => {
    if (selectedOption.length === 0) {
      setSubmitDisabled(true);
    } else {
      setSubmitDisabled(false);
      var contractId = selectedOption.value;
      var filteredItem = contractItems
        .filter((item) => item.id === contractId)
        .map((item) => item)[0];
      if (filteredItem.maxValue === 0) {
        setSubmitFormControl(true);
      } else {
        setSubmitFormControl(false);
      }
      setMaxValue(filteredItem.maxValue);
      setMinValue(filteredItem.minValue);
      setQuantity(filteredItem.minValue);
      setContractItemId(filteredItem.id);
    }
  }, [selectedOption, contractItems]);

  const submitMutation = (insertObj) => {
    setMutationLoading(true);

    insertInstallmentItem({ variables: insertObj })
      .then(({ data }) => {
        setTimeout(() => {
          setMutationLoading(false);
          setIsModalVisible(false);
          resetStates();
          openNotification(data);
        }, 500);
      })
      .catch((error) => {
        console.warn("there was an error sending the query", error);
        resetStates();
      });
  };

  const submitInstallmentItem = () => {
    var insertObj = {
      contractId: contractId,
      contractInstallmentId: installmentObject.id,
      contractItemId: contractItemId,
      scheduledQuantity: quantity,
    };
    submitMutation(insertObj);
  };
  const FieldWrapperStyle = { marginBottom: "20px" };
  const TitleStyle = { marginBottom: "10px", fontWeight: 500 };
  return (
    <div>
      <Button onClick={() => showModal()}>Inserir Item</Button>
      <InstallmentContractItemsTable
        dataSource={installmentObject.scheduledItems}
      ></InstallmentContractItemsTable>
      <Modal
        title="Inserir item na parcela"
        visible={isModalVisible}
        onCancel={() => handleCancel()}
        footer={[
          <Button key="back" onClick={() => handleCancel()}>
            Return
          </Button>,

          <Button
            loading={mutationLoading}
            onClick={() => submitInstallmentItem()}
            disabled={submitDisabled}
            type="primary"
          >
            Inserir item
          </Button>,
        ]}
      >
        <div style={FieldWrapperStyle}>
          <div style={TitleStyle}>Selecione o item desejado</div>

          <Select
            style={{ width: "100%" }}
            options={options}
            value={selectedOption}
            onChange={(e) => setSelectedOption(e)}
          />
        </div>
        <div style={FieldWrapperStyle}>
          <div style={TitleStyle}>Selecione a quantidade desta parcela:</div>
          <InputNumber
            min={minValue}
            max={maxValue}
            value={quantity}
            disabled={submitDisabled}
            style={{ width: "100%" }}
            onChange={(e) => handleQuantityChange(e)}
          />
        </div>
      </Modal>
    </div>
  );
};

export default InstallmentContractItems;
