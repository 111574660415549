import { Divider, Table } from "antd";
import React, { useEffect, useState } from "react";
import {
  defineCycleStatus,
  getStatusInfo
} from "./ConsumptionTableUtils";

import _ from "lodash";
import moment from "moment";

const TableComponent = ({ dataSource, isLoading }) => {
  const expandedRowRender = (data) => {
    const periodProcessings = (params) => {
      return params.periodProcessings;
    };

    const nestedTableColumns = [
      {
        title: "Processing Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Total Images",
        align: "center",
        width: 120,
        render: (data) => (
          <div>{data.totalImages}</div>
        ),
      },
      {
        title: "Flown At",
        dataIndex: "flownAt",
        align: "center",
        width: 120,
        key: "flownAt",
        render: (data) => moment(data).format("DD/MM/YYYY"),
      },
      {
        title: "Submitted At",
        dataIndex: "submittedAt",
        align: "center",
        width: 120,
        key: "submittedAt",
        render: (data) => moment(data).format("DD/MM/YYYY"),
      },
      {
        title: "Status",
        align: "center",
        render: (data) => <div>{data.status}</div>,
      },
    ];
    return (
      <div>
        <Divider />
        <Table
          columns={nestedTableColumns}
          dataSource={periodProcessings(data)}
          size="small"
          pagination={false}
        />
      </div>
    );
  };
  const columns = [
    {
      title: "PARCELA",
      width: 50,
      align: "center",
      dataIndex: "installmentNumber",
      key: "installmentNumber",
    },
    {
      title: "INÍCIO",
      width: 180,
      align: "center",
      dataIndex: "periodStart",
      key: "periodStart",
      render: (data) => <div>{moment(data).format("DD-MM-YYYY")}</div>,
    },
    {
      title: "TÉRMINO",
      width: 180,
      align: "center",
      dataIndex: "periodEnd",
      key: "periodEnd",
      render: (data) => <div>{moment(data).format("DD-MM-YYYY")}</div>,
    },
    {
      title: "STATUS PERÍODO",
      dataIndex: "cycleStatus",
      width: 180,
      align: "center",
      key: "cycleStatus",
      render: (data) => <div>{data}</div>,
    },
    {
      title: "NÚMERO DE VOOS PROCESSADOS",
      width: 100,
      align: "center",
      dataIndex: "totalPeriodProcessings",
      key: "totalPeriodProcessings",
    },
    {
      title: "PACOTE DE IMAGENS CONTRATADAS",
      width: 100,
      align: "center",
      dataIndex: "imageQuota",
      key: "imageQuota",
    },
    {
      title: "NÚMERO DE IMAGENS PROCESSADAS",
      width: 100,
      align: "center",
      dataIndex: "totalProcessedPeriod",
      key: "totalProcessedPeriod",
    },
    {
      title: "IMAGENS EXCEDENTES",
      width: 100,
      align: "center",
      dataIndex: "exceedingImages",
      key: "exceedingImages",
    },
    {
      title: "SALDO IMAGENS",
      width: 100,
      align: "center",
      dataIndex: "totalBalance",
      key: "totalBalance",
    },
  ];

  return (
    <div>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        bordered
        loading={isLoading}
        expandable={{ expandedRowRender }}
        size="small"
      />
    </div>
  );
};

function checkProcessingPeriod(processingDate, start_date, end_date) {
  if (start_date <= processingDate && processingDate <= end_date) {
    var isBetween = true;
  } else {
    isBetween = false;
  }
  return isBetween;
}

const ContractConsumption = ({ contractRawData }) => {
  const [finalInst, setfinalInst] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [processingsData, setProcessingsData] = useState([]);
  const [contractData, setContractData] = useState([]);
  const [installmentsData, setInstallmentsData] = useState([]);

  // const clearStates = () => {
  //   setContractData([]);
  //   setProcessingsData([]);
  //   setInstallmentsData([]);
  //   setfinalInst([]);
  // };

  const processInstallments = (installmentsData, processingsData) => {
    const finalInstallments = [];
    var creditsBalance = [];
    var totalBalance = 0;
    for (var i = 0; i < installmentsData.length; i++) {
      var installment = installmentsData[i];
      installment.key = installment.id;
      const periodStart = installment.periodStart;
      const periodEnd = installment.periodEnd;
      try {
        const contractItem = installment.scheduledItems
          .filter((item) => item.contractItem.isSubscription === true)
          .map((item) => item)[0].contractItem;
        installment.imageQuota = contractItem.imageQuota;
      } catch (error) {
        console.log(error);
        installment.imageQuota = 0;
      }
      var imageQuota = installment.imageQuota;
      const cycleStatus = defineCycleStatus(
        moment(periodStart, "YYYY-MM-DD"),
        moment(periodEnd, "YYYY-MM-DD")
      );
      installment.cycleStatus = cycleStatus;
      const periodProcessings = [];
      processingsData.forEach((processing) => {
        var processingDate = moment(
          processing.submittedAt.split("T")[0].trim(),
          "YYYY-MM-DD"
        );
        var isBetween = checkProcessingPeriod(
          processingDate,
          moment(periodStart, "YYYY-MM-DD"),
          moment(periodEnd, "YYYY-MM-DD")
        );
        if (isBetween) {
          var isBillable = getStatusInfo(processing.status)[0].billable;
          console.log(isBillable);
          processing.isBillable = isBillable;
          periodProcessings.push(processing);
        } else {
        }
      });
      installment.periodProcessings = periodProcessings;
      const totalProcessedPeriod = _.sumBy(
        installment.periodProcessings,
        (item) => {
          return item.isBillable
            ? item.totalImages
            : 0;
        }
      );
      const countTotalPeriodProcessings = _.countBy(
        installment.periodProcessings,
        (item) => {
          return item.isBillable;
        }
      );
      const totalPeriodProcessings = countTotalPeriodProcessings.true;
      installment.totalPeriodProcessings =
        totalPeriodProcessings === undefined ? 0 : totalPeriodProcessings;
      installment.totalProcessedPeriod = totalProcessedPeriod;
      const periodBalance = imageQuota - totalProcessedPeriod;
      installment.periodBalance = periodBalance;
      creditsBalance.push(periodBalance);
      var creditsBalanceSum = _.sum(creditsBalance);
      installment.creditsBalanceSum = creditsBalanceSum;
      if (periodBalance < 0) {
        // ver se tem crédito no balance para cobrir
        if (Math.abs(periodBalance) >= totalBalance) {
          var exceedingImages = totalBalance + periodBalance;
          totalBalance = 0;
          var periodCredits = 0;
        } else {
          periodCredits = periodBalance;
          exceedingImages = 0;
          totalBalance = totalBalance + periodBalance;
        }
      } else {
        exceedingImages = 0;
        periodCredits = periodBalance;
        totalBalance = totalBalance + periodBalance;
      }
      installment.exceedingImages = exceedingImages;
      installment.totalBalance = totalBalance;
      installment.periodCredits = periodCredits;
      finalInstallments.push(installment);
    }
    setfinalInst(finalInstallments);
    setisLoading(false);
  };

  useEffect(() => {
    setProcessingsData(_.orderBy(contractRawData.processings, "submittedAt"));
    setContractData(contractRawData);
    setInstallmentsData(
      _.orderBy(contractRawData.contractInstallments, "periodStart")
    );
  }, [contractRawData]);

  useEffect(() => {
    console.log(processingsData.length);
    console.log(contractData);
    if (processingsData.length >= 1 && installmentsData.length >= 1) {
      console.log("passei");
      processInstallments(installmentsData, processingsData);
    } else {
      setisLoading(false);
    }
  }, [contractData, installmentsData, processingsData]);

  return (
    <div>
      <Divider />
      <TableComponent dataSource={finalInst} isLoading={isLoading} />
      <Divider />
    </div>
  );
};

export default ContractConsumption;

