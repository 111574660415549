import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import { useSubscription } from "@apollo/client";
import { Table } from "ant-table-extensions";
import { Button, Divider, Dropdown, Input, Menu, PageHeader, Skeleton, Space, Table as Table2, Tag, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";
import _ from "lodash";
import moment from 'moment';
import React, { useMemo, useRef, useState } from "react";
import Highlighter from 'react-highlight-words';
import { Link } from "react-router-dom";
import { useAuth } from '../../Auth';
import { ACCOUNTS_SUBSCRIPTION } from "./AccountsQueries";
import NewAccount from "./NewAccount/NewAccount";

const brCurrencyFormat = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' });

const { Text } = Typography;
const { Summary } = Table2;

const AccountsTable = ({ dataSource }) => {
  const {currentUser: user} = useAuth();
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  // console.log(user);

  const handleSearch = (
    selectedKeys,
    confirm,
    dataIndex,
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={e => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${Array.isArray(dataIndex) ? dataIndex.join(' ') : dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys)[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => {
      let field = record;
      if (Array.isArray(dataIndex)) {
        dataIndex.forEach(element => {
          field = field ? field[element] : null;
        });
      } else {
        field = field[dataIndex];
      }
      return field === null ?
        true
      :
        field.toLowerCase()
          .includes((value).toLowerCase())
    },
    onFilterDropdownOpenChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: text => {
      const highlight = (Array.isArray(searchedColumn) ? searchedColumn.join('.') : searchedColumn) ===
        (Array.isArray(dataIndex) ? dataIndex.join('.') : dataIndex);
      return highlight ?
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      :
        text
    }
  });

  // console.log(getColumnSearchProps('organization.name'));

  const columns = [
    {
      title: "Organization",
      dataIndex: ['organization', 'name'],
      ...getColumnSearchProps(['organization', 'name']),
      sorter: (a, b) => {
        console.log(a.organization?.name, b.organization?.name);
        const nameA = a?.organization?.name?.toUpperCase(); // ignore upper and lowercase
        const nameB = b?.organization?.name?.toUpperCase(); // ignore upper and lowercase
        if (nameA === undefined) {
          return -1;
        }
        if (nameB === undefined) {
          return 1;
        }
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: "Name",
      dataIndex: "name",
      ...getColumnSearchProps('name'),
      sorter: (a, b) => {
        const nameA = a.name?.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name?.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: "Contracts",
      align: "center",
      width: 100,
      render: (data) => <div>{data.contracts_aggregate.aggregate.count}</div>,
      sorter: (a, b) => a.contracts_aggregate.aggregate.count - b.contracts_aggregate.aggregate.count,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: "Members", //
      align: "center",
      width: 100,
      render: (data) => {
        // if (data?.active_contract.length) {
        //   console.log(data?.active_contract[0]);
        // }
        return <div>{data.members_aggregate.aggregate.count} / {data?.active_contract.length > 0 ? data?.active_contract[0]?.seats_quota : 0}</div>
      },
      sorter: (a, b) => a.members_aggregate.aggregate.count - b.members_aggregate.aggregate.count,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: "Period",
      key: "contract_period",
      // dataIndex: ["active_contract", "period_in_months"],
      align: "center",
      render: (data) => {
        return data?.active_contract.length > 0 ?
          moment.duration(data?.active_contract[0]?.period_in_months, 'month').asMonths() + " months"
        :
          "-"
      },
      sorter: (a, b) => a?.active_contract[0]?.period_in_months ?? 0 - b?.active_contract[0]?.period_in_months ?? 0,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: "Start - End",
      align: "center",
      render: (data) => {
        return data?.active_contract.length > 0 ?
          moment(data?.active_contract[0].started_at).format("DD/MM/YYYY") + " - " +
          moment(data?.active_contract[0].started_at).add(data?.active_contract[0].period_in_months, 'months').format("DD/MM/YYYY")
        :
          "-"
      }
    },
    {
      title: "Status",
      align: "center",
      render: (data) => {
        const active = data?.current_status.length > 0 ? data?.current_status[0].is_active : false;
        return <Tag color={active ? "success" : "error"}>{active ? "Ativo" : "Inativo"}</Tag>
      }
    },
    {
      title: "Action",
      align: "center",
      width: 100,
      render: (data) => (
        <Button size="small" style={{ fontSize: "12px" }}>
          <Link
            to={{
              pathname: `/view-account-details/${data.id}`,
              state: { contract: data.id },
            }}
          >
            More Info
          </Link>
        </Button>
      ),
    },
  ];

  // console.log(columns);

  if (user.uid == '6806b03f-75b9-44e1-9f60-87b83ff43fb8') {
    columns.splice(6, 0,
      {
        title: "Total Images",
        align: "center",
        render: (data) => {
          // console.log(data?.contracts_aggregate?.nodes);
          const sumCredit = data?.contracts_aggregate?.nodes?.reduce((acu, node) => {
            return acu + node?.imageUsageEntries_aggregate?.aggregate?.sum?.credit ?? 0;
          }, 0);
          return sumCredit;
        }
      },
      {
        title: "Total Contract",
        align: "center",
        render: (data) => {
          // console.log(data?.contracts_aggregate?.nodes);
          const sumCredit = data?.contracts_aggregate?.nodes?.reduce((acu, node) => {
            return acu + node?.imageUsageEntries_aggregate?.aggregate?.sum?.credit ?? 0;
          }, 0);
          return brCurrencyFormat.format(sumCredit * 2.5);
        }
      },
      {
        title: "Per Month",
        align: "center",
        render: (data) => {
          // console.log(data?.contracts_aggregate?.nodes);
          const obj = data?.contracts_aggregate?.nodes?.reduce((acu, node) => {
            acu.sumCredit += (node?.imageUsageEntries_aggregate?.aggregate?.sum?.credit ?? 0);
            acu.periodTotal += (node?.period_in_months ?? 0);
            return acu;
          }, { sumCredit: 0, periodTotal: 0 });
          return brCurrencyFormat.format((obj.sumCredit * 2.5) / obj.periodTotal);
        }
      }
    );
  }

  // console.log(columns);

  const summaryData = (pageData) => {
    let totalAllContracts = 0;
    let amountAllContracts = 0;
    let totalMonthlyAllContracts = 0;

    // console.log(pageData)

    pageData.forEach(acc => {
      const total = acc?.contracts_aggregate?.nodes?.reduce((obj, node) => {
        obj.sumCredit += node?.imageUsageEntries_aggregate?.aggregate?.sum?.credit ?? 0;;
        obj.periodTotal += node?.period_in_months ?? 0;
        return obj;
      }, { sumCredit: 0, periodTotal: 0 });
      console.log(total);
      totalAllContracts += total.sumCredit;
      amountAllContracts += (total.sumCredit * 2.5);
      totalMonthlyAllContracts += (total.sumCredit * 2.5) / total.periodTotal;
    });

    return (
      <>
        <Summary.Row>
          <Summary.Cell index={0} colSpan={6} align="right">Total</Summary.Cell>
          <Summary.Cell index={6} align="center">
            <Text type="danger">{totalAllContracts}</Text>
          </Summary.Cell>
          <Summary.Cell index={7} align="center">
            <Text>{brCurrencyFormat.format(amountAllContracts)}</Text>
          </Summary.Cell>
          <Summary.Cell index={7} align="center">
            <Text>{brCurrencyFormat.format(totalMonthlyAllContracts)}</Text>
          </Summary.Cell>
        </Summary.Row>
      </>
    );
  }

  return (
    <div>
      <Table
        dataSource={dataSource}
        columns={columns}
        size="small"
        pagination={{ pageSize: "50" }}
        rowKey="id"
        summary={(user.uid == '6806b03f-75b9-44e1-9f60-87b83ff43fb8') ? summaryData : null}
      />
    </div>
  );
};

const Accounts = () => {
  const sectionTitle = "Accounts";
  const [newAccountModalVisible, setNewAccountModalVisible] = useState(false);
  const { loading, data } = useSubscription(ACCOUNTS_SUBSCRIPTION);

  const accounts = useMemo(() => _.orderBy(data?.accounts || [], ["createdAt"], ["desc"]), [data]);

  const menu = (
    <Menu>
      <Menu.Item key="rule_assingment">
        <div onClick={() => setNewAccountModalVisible(true)}>
          Adicionar Nova Conta
        </div>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="other_rule" disabled>
        Example（disabled）
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      <Content style={{ margin: "0 16px" }}>
        <div
          className="site-page-header-ghost-wrapper"
          style={{ marginTop: "10px" }}
        >
          <PageHeader
            ghost={false}
            onBack={() => window.history.back()}
            title={sectionTitle}
            extra={[
              <Dropdown overlay={menu} key="drop">
                <Button
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}
                >
                  Ações <DownOutlined />
                </Button>
              </Dropdown>,
            ]}
          >
            <Divider />
            {loading ? <Skeleton />
            : <AccountsTable dataSource={accounts} />}
          </PageHeader>
        </div>
        <NewAccount
          newAccountModalVisible={newAccountModalVisible}
          setNewAccountModalVisible={setNewAccountModalVisible}
          totalContracts={0}
        />
      </Content>
    </div>
  );
};

export default Accounts;
