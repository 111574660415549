import {
  Button,
  Col,
  Divider,
  Progress,
  Row,
  Statistic
} from "antd";
import React, { useCallback, useEffect, useState } from "react";

import { Table } from "ant-table-extensions";
import { Tag } from "antd";
import _ from "lodash";
import AddInstallmentModal from "./AddInstallmentModal";
import InstallmentContractItems from "./InstallmentContractItems";
import InstallmentOptions from "./InstallmentOptions";

// const { Title } = Typography;

const currencyFormat = (value) => {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value);
};

const TotalInstallmentValue = ({ data }) => {
  const [state, setstate] = useState();

  useEffect(() => {
    var arrValues = [];
    data.forEach((element) => {
      arrValues.push(
        element.scheduledQuantity * element.contractItem.unitValue
      );
    });
    var totalValue = _.sum(arrValues);
    setstate(totalValue);
  }, [data]);
  return <div>{currencyFormat(state)}</div>;
};

const ContractInstallments = ({
  initialInstallments,
  initialContractItems,
  contractId,
}) => {
  const [invoicesInstallments, setinvoicesInstallments] = useState([]);
  const [contractItems, setcontractItems] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [totalContractValue, setTotalContractValue] = useState(0);
  const [totalValueScheduled, setTotalValueScheduled] = useState(0);
  // function processScheduledItems(scheduledItems, contractItems) {
  //   var subTotalsArray = [];
  //   var subTotalsArrayScheduled = [];
  //   var newContractItems = [];

  //   var group = _.groupBy(scheduledItems, "contractItemId");

  //   contractItems.forEach((item) => {
  //     var itemId = item.id;
  //     var schedulesItem = group[itemId];
  //     var totalScheduled = _.sumBy(schedulesItem, "scheduledQuantity");
  //     var subTotal = item.quantity * item.unitValue;
  //     subTotalsArray.push(subTotal);
  //     var subTotalScheduled = totalScheduled * item.unitValue;
  //     subTotalsArrayScheduled.push(subTotalScheduled);
  //     var maxValue = item.quantity - totalScheduled;
  //     item.maxValue = maxValue;
  //     item.minValue = 1;
  //     newContractItems.push(item);
  //   });
  //   //setTotalContractValue(_.sum(subTotalsArray));
  //   //setTotalValueScheduled(_.sum(subTotalsArrayScheduled));
  //   return newContractItems;
  // }

  const processedScheduledItms = (initialInstallments, contractItems) => {
    const allItems = [];
    var subTotalsArray = [];
    var subTotalsArrayScheduled = [];
    var newContractItems = [];
    initialInstallments.forEach((installment) => {
      const scheduledItems = installment.scheduledItems;
      scheduledItems.forEach((item) => {
        allItems.push(item);
      });
    });
    var grouped = _.groupBy(allItems, "contractItemId");
    contractItems.forEach((item) => {
      var itemId = item.id;
      var schedulesItem = grouped[itemId];
      var totalScheduled = _.sumBy(schedulesItem, "scheduledQuantity");
      var subTotal = item.quantity * item.unitValue;
      subTotalsArray.push(subTotal);
      var subTotalScheduled = totalScheduled * item.unitValue;
      subTotalsArrayScheduled.push(subTotalScheduled);
      var maxValue = item.quantity - totalScheduled;
      item.maxValue = maxValue;
      item.minValue = 1;
      newContractItems.push(item);
    });
    setTotalContractValue(_.sum(subTotalsArray));
    setTotalValueScheduled(_.sum(subTotalsArrayScheduled));
    return newContractItems;
  };

  useEffect(() => {
    const newInstallments = [];
    if (initialInstallments.length === 0) {
      setinvoicesInstallments([]);
    } else {
      console.log(initialInstallments);
      initialInstallments.forEach((element) => {
        element.key = element.id;
        newInstallments.push(element);
      });
      const newContractItems = processedScheduledItms(
        initialInstallments,
        initialContractItems
      );

      setinvoicesInstallments(newInstallments);
      setcontractItems(newContractItems);
    }
  }, [initialContractItems, initialInstallments]);

  const columns = [
    {
      title: "Installment",
      dataIndex: "invoiceCode",
      key: "invoiceCode",
      align: "center",
    },
    {
      title: "Start",
      dataIndex: "periodStart",
      key: "periodStart",
      width: 120,
      align: "center",
    },
    {
      title: "End",
      dataIndex: "periodEnd",
      key: "periodEnd",
      width: 120,
      align: "center",
    },
    {
      title: "Period",
      dataIndex: "period",
      key: "period",
      width: 200,
      align: "center",
    },
    {
      title: "Total Value",
      key: "period",
      width: 200,
      align: "center",
      render: (data) => <TotalInstallmentValue data={data.scheduledItems} />,
    },
    {
      title: "Status",
      key: "status",
      width: 200,
      align: "center",
      render: (data) => (
        <Tag color={data.status.color}>{data.status.description}</Tag>
      ),
    },
    {
      title: "Actions",
      key: "period",
      width: 160,
      align: "center",
      render: (data) => (
        <InstallmentOptions
          dataSource={data}
          contractData={initialInstallments}
        />
      ),
    },
  ];
  const callBackNestedTable = useCallback((data) => {
    console.log(data);
  }, []);
  // const style = { background: "#0092ff", padding: "8px 0" };
  return (
    <div>
      <Divider />

      <Row gutter={16}>
        <Col className="gutter-row" span={6}>
          <div
            style={{
              display: "flex",
              justifyContent: "left",
              alignItems: "left",
            }}
          >
            <Button onClick={() => setIsModalVisible(true)}>
              Add Installment
            </Button>
          </div>
        </Col>
        <Col className="gutter-row" span={6}></Col>
        <Col className="gutter-row" span={6}></Col>
        <Col className="gutter-row" span={6}>
          <Statistic
            title="Total Agendado"
            value={currencyFormat(totalValueScheduled)}
          />
          <Progress
            style={{ width: "90%" }}
            percent={(
              (totalValueScheduled / totalContractValue) *
              100
            ).toPrecision(3)}
            size="small"
          />
        </Col>
      </Row>
      <Divider />
      <Table
        dataSource={invoicesInstallments.sort(
          (a, b) => a.installmentNumber - b.installmentNumber
        )}
        bordered={true}
        columns={columns}
        pagination={false}
        size="small"
        className="components-table-demo-nested"
        expandable={{
          expandedRowRender: (record) => (
            <InstallmentContractItems
              installmentObject={record}
              parentCallBack={callBackNestedTable}
              contractItems={contractItems}
              contractId={contractId}
            />
          ),
          rowExpandable: (record) => record.name !== "Not Expandable",
        }}
      />
      <Divider />
      <AddInstallmentModal
        isModalVisible={isModalVisible}
        contractItems={contractItems}
        setIsModalVisible={setIsModalVisible}
        invoicesInstallments={invoicesInstallments}
        contractId={contractId}
      />
    </div>
  );
};

export default ContractInstallments;
