import { DownOutlined } from '@ant-design/icons';
import { useMutation, useSubscription } from "@apollo/client";
import { Table } from "ant-table-extensions";
import {
  Button,
  Col,
  Divider,
  Dropdown,
  Input,
  Menu,
  PageHeader,
  Popconfirm,
  Row,
  Skeleton,
  Statistic,
  Tooltip
} from "antd";
import { Content } from "antd/lib/layout/layout";
import Fuse from "fuse.js";
import _ from "lodash";
import moment from "moment";
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from "react";
import UserDetail from "./UsersDetail/UserDetail";
import { LOGOUT_ALL_USERS, LOGOUT_USERS, USERS_SUBSCRIPTION } from "./UsersQueries";

const AccountsCell = ({ data }) => {
  const ToolTipContainer = (data) => {
    return (
      <div>
        {data.data.userAccounts.map((item) => (
          <React.Fragment key={item?.account?.id}>
            <dt>{item?.account?.name}</dt>
          </React.Fragment>
        ))}
      </div>
    );
  };

  return (
    <div>
      <Tooltip trigger='click' title={<ToolTipContainer data={data}  />}>
        <Button type="link">{data?.userAccounts_aggregate?.aggregate?.count ?? 0}</Button>
      </Tooltip>
    </div>
  );
};

AccountsCell.propTypes = {
  data: PropTypes.object.isRequired
};

const UsersTable = ({ dataSource, handleClickedUser }) => {
  const [filterString, setFilterString] = useState("");
  const [users, setUsers] = useState([]);
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [logoutUsers, resultLogout] = useMutation(LOGOUT_USERS);
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Accounts",
      align: "center",
      width: 150,
      render: (data) => <AccountsCell data={data} />,
    },
    {
      title: "Login IP",
      dataIndex: "current_sign_in_ip",
      key: "current_sign_in_ip",
      align: "center",
      width: 120,
    },
    {
      title: "Logged At",
      dataIndex: "current_sign_in_at",
      align: "center",
      width: 200,
      render: (data) => data ? moment(data).utcOffset('-0600').format("DD/MM/YYYY - HH:mm:ss") : "-",
    },
    // {
    //   title: "Created At",
    //   dataIndex: "createdAt",
    //   align: "center",
    //   width: 200,
    //   render: (data) => moment(data).format("DD/MM/YYYY - HH:mm:ss"),
    // },
    {
      title: "Action ",
      align: "center",
      width: 120,
      render: (data) => (
        <Button size="small" onClick={() => handleClickedUser(data.id)}>
          More Info
        </Button>
      ),
    },
  ];

  useEffect(() => {
    if (filterString === "") {
      setUsers(dataSource);
    } else {
      const options = {
        threshold: 0.2,
        distance: 100,
        keys: ["email"],
      };
      const pattern = filterString;
      const fuse = new Fuse(dataSource, options);
      const newList = fuse.search(pattern);
      const filteredArray = newList.map((item) => item.item);
      setUsers(filteredArray);
    }
  }, [dataSource, filterString]);

  const logoutUsersAction = useCallback(() => {
    logoutUsers({
      variables: {
        userIds: selectedUserIds
      }
    })
  }, [selectedUserIds, logoutUsers]);

  return (
    <div>
      <Row gutter={16}>
        <Col className="gutter-row" span={6}>
          <Popconfirm
            title="Você quer mesmo fazer o logout destes usuários?"
            onConfirm={(e) => selectedUserIds.length > 0 && logoutUsersAction()}
            okText="Sim"
            cancelText="Não"
          >
            <Button danger disabled={selectedUserIds.length === 0}>Logout Selected Users</Button>
          </Popconfirm>
        </Col>
        <Col className="gutter-row" span={6}></Col>
        <Col className="gutter-row" span={6}></Col>
        <Col className="gutter-row" span={6}>
          <div>Search User</div>
          <Input
            value={filterString}
            onChange={(e) => setFilterString(e.target.value)}
          ></Input>
        </Col>
      </Row>
      <Table
        dataSource={users}
        columns={columns}
        size="small"
        bordered={true}
        pagination={{ pageSize: "50" }}
        rowSelection={{
          type: "checkbox",
          onChange: (selectedRowKeys, selectedRows) => {
            setSelectedUserIds(selectedRowKeys)
            // console.log(`selectedRowKeys: ${selectedRowKeys}`, "selectedRows: ", selectedRows);
          },
          // getCheckboxProps: (record) => ({
          //   disabled: record.name === "Disabled User",
          //   name: record.name,
          // }),
        }}
      />
    </div>
  );
};

UsersTable.propTypes = {
  dataSource: PropTypes.array.isRequired,
  handleClickedUser: PropTypes.func.isRequired
};

const Users = () => {
  const sectionTitle = "Users";
  const [isDrawerVisible, setDrawerVisible] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const { loading, data } = useSubscription(USERS_SUBSCRIPTION);
  const [logoutAllUsers, resultLogout] = useMutation(LOGOUT_ALL_USERS);

  const users = useMemo(
    () =>
      _.orderBy(
        data?.users.map((user) => ({ ...user, key: user.id })),
        ["submittedAt"],
        ["desc"]
      ),
    [data]
  );

  const handleClickedUser = (params) => {
    setSelectedUserId(params);
    setDrawerVisible(true);
  };

  const menu = (
    <Menu>
      <Menu.Item key="rule_assingment" danger>
        <Popconfirm
          title="Você quer mesmo fazer o logout de todos os usuários?"
          onConfirm={(e) => logoutAllUsers()}
          okText="Sim"
          cancelText="Não"
          danger
        >
          Logout All Users
        </Popconfirm>

      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      <Content style={{ margin: "0 16px" }}>
        <div
          className="site-page-header-ghost-wrapper"
          style={{ marginTop: "10px" }}
        >
          <PageHeader
            ghost={false}
            onBack={() => window.history.back()}
            title={sectionTitle}
            extra={[
              <Dropdown overlay={menu} key="drop">
                <Button
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}
                >
                  Ações <DownOutlined />
                </Button>
              </Dropdown>,
            ]}
          >
            {loading && <Skeleton />}
            {!loading && (
              <Statistic value={users.length} title={"Total Users"} />
            )}

            <Divider />
            {loading && <Skeleton />}
            {!loading && (
              <UsersTable
                dataSource={users}
                handleClickedUser={handleClickedUser}
              />
            )}
          </PageHeader>
        </div>
        {selectedUserId && (
          <UserDetail
            isDrawerVisible={isDrawerVisible}
            setDrawerVisible={setDrawerVisible}
            userId={selectedUserId}
          />
        )}
      </Content>
    </div>
  );
};

export default Users;
