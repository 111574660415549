import React from 'react';

export const mappingImagesProcessing = (data) => {
  var layer = data
    .filter((item) => item.layerType === "MAPPING_IMAGES")
    .map((item) => item)[0];
  const mappingImages = layer.data.images;
  const markersArr = [];
  mappingImages.forEach((element) => {
    const mappingImageObj = {
      latitude: element.geometry.coordinates[1],
      longitude: element.geometry.coordinates[0],
      width: element.properties.width,
      height: element.properties.height,
      camera_maker: element.properties.camera_maker,
      url: element.properties.url,
      filename: element.properties.filename,
      camera_model: element.properties.camera_model,
      altitude: element.properties.altitude,
      captured_at: element.properties.captured_at,
    };
    markersArr.push(mappingImageObj);
  });
  return markersArr;
};

export const gcpsProcessing = (data) => {
  var layer = data
    .filter((item) => item.layerType === "GCPS")
    .map((item) => item)[0];
  const gcps = layer.data.gcps.features;
  const markersArr = [];
  gcps.forEach((element) => {
    const gcpObj = {
      latitude: element.geometry.coordinates[1],
      longitude: element.geometry.coordinates[0],
      elevation: element.geometry.coordinates[2],
      label: element.properties.label,
    };
    markersArr.push(gcpObj);
  });
  return markersArr;
};

export function ImageInfo(props) {
  const { info } = props;
  return (
    <div>
      <div>
        <b>Image File: </b>
        {info.filename}
      </div>

      <div>
        <b>Camera Maker: </b>
        {info.camera_maker}
      </div>

      <div>
        <b>Camera Model: </b>
        {info.camera_model}
      </div>
      <div>
        <b>Captured At: </b>
        {info.captured_at}
      </div>
      <div>
        <b>Image Size: </b>
        {info.width} x {info.height}
      </div>
      <div>
        <b>Latitude: </b>
        {info.latitude}
      </div>
      <div>
        <b>Longitude: </b>
        {info.longitude}
      </div>
      <div>
        <b>Height (AGL): </b>
        {info.altitude}
      </div>
    </div>
  );
}

export function GCPInfo(props) {
  const { info } = props;
  return (
    <div>
      <div>
        <b>GCP label: </b>
        {info.label}
      </div>
      <div>
        <b>Latitude: </b>
        {info.latitude}
      </div>
      <div>
        <b>Longitude: </b>
        {info.longitude}
      </div>
      <div>
        <b>Elevation: </b>
        {info.elevation}
      </div>
    </div>
  );
}
