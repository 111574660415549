import { DownOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import {
  Badge,
  Button,
  Card,
  Col,
  Divider,
  Dropdown,
  Input,
  Menu,
  Modal,
  Progress,
  Row
} from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { PROCESSINGS_MUTATION } from "./ProcessingMonitoringQueries";
import ElapseTimeComponent from "./ProcessingMonitoringShared";
import { roundDecimals } from "./ProcessingsUtils";
// const { Step } = Steps;

// const IconText = ({ icon, text }) => (
//   <Space>
//     {React.createElement(icon)}
//     {text}
//   </Space>
// );

const { TextArea } = Input;

const ProcessingCard = ({ item }) => {

  const [updateProcessing] = useMutation(PROCESSINGS_MUTATION);
  // const [reason, setReason] = useState(null);
  const { confirm } = Modal;
  const pTask = item.processingTasks.length > 0 && item.processingTasks[0];

  console.log(pTask);

  const canCancelProcessing = (status) => ['PROCESSING_QUEUE', 'PROCESSING'].indexOf(status) > -1;
  const canDeclareFailure = (status) => ['PROCESSING_FAILED', 'INTERVENTION'].indexOf(status) > -1;

  const updateStatusOfProcessing = (id, status, reason) => {
    return updateProcessing({
      variables: {
        id,
        pId: pTask.id,
        status,
        reason
      }
    })
  };

  const handleStatusUpdate = (id, typeOfHandle) => {
    console.log("handleStatusUpdate")

    const status = typeOfHandle === 'cancel' ? "PROCESSING_CANCELED" : "DECLARE_FAILURE"
    const title = typeOfHandle === 'cancel' ? "Deseja cancelar este processamento?" : "Deseja declarar a falha deste processamento?";
    const placeholder = typeOfHandle === 'cancel' ? "Motivo do cancelamento" : "Motivo da Falha no processamento";
    const desc = typeOfHandle === 'cancel' ?
                 'CUIDADO!! Ao cancelar o processamento destas imagens, as execuções do workflow serão encerradas sem o devido término!' :
                 'Ao declarar falha para o processamento destas imagens, o saldo não será retornado à conta do cliente!';

    let modal = null;
    let reason = null;

    const onChange = (e) => {
      console.log('Change:', e.target.value);
      reason = e.target.value;
      modal.update({ okButtonProps: { disabled: e.target.value.length <= 3 } })
    };

    modal = confirm({
      title: title,
      icon: <ExclamationCircleOutlined />,
      content: <>{desc}<br /><TextArea rows={4} placeholder={placeholder} onChange={onChange} /></>,
      okText: 'SIM',
      okType: 'danger',
      cancelText: 'Não',
      okButtonProps: { disabled: true },
      onOk: () => {
        return new Promise((resolve, reject) => {
          updateStatusOfProcessing(id, status, reason).then(() => { resolve() }).catch(() => { reject() });
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel: () => { },
    });
  };

  const menu = (id, status) => {
    console.log(status, id);
    return (
      <Menu onClick={({key}) => handleStatusUpdate(id, key)}>
        <Menu.Item key="cancel" danger disabled={status !== 'can_cancel'}>Cancel Processing</Menu.Item>
        <Menu.Item key="failed" danger disabled={status !== 'can_declare_fail'}>Declare Failure</Menu.Item>
      </Menu>
    )
  };

  return (
    <div>
      <Card
        headStyle={{ backgroundColor: "#007AFF", border: 0 }}
        key={item.id}
        title={
          <div>
            <div
              style={{
                fontSize: "16px",
                fontWeight: 600,
                color: "white",
              }}
            >
              {item.name}
            </div>
            <div
              style={{
                fontSize: "11px",
                fontWeight: 500,
                color: "white",
              }}
            >
              {item.id}
            </div>
          </div>
        }
        extra={
          canCancelProcessing(item.status) || canDeclareFailure(item.status) ?
            <Dropdown.Button size="small" style={{ fontSize: "12px" }}
              icon={<DownOutlined />}
              overlay={ menu(item.id, canCancelProcessing(item.status) ? "can_cancel" : "can_declare_fail") }
            >
              <Link
                to={{
                  pathname: `/view-processing-details/${item.id}`,
                  state: { contract: item.id },
                }}
              >
                More Info
              </Link>
            </Dropdown.Button>
          :
            <Button size="small" style={{ fontSize: "12px" }}>
              <Link
                to={{
                  pathname: `/view-processing-details/${item.id}`,
                  state: { contract: item.id },
                }}
              >
                More Info
              </Link>
            </Button>
        }
        style={{
          width: "100%",
          marginBottom: "5px",
          backgroundColor: "#f3f3f3",
          border: "1px solid #999999",
        }}
        size="small"
      >
        <div>
          <Row>
            <Col span={12}>
              <div>Elapse Time</div>
              <ElapseTimeComponent dateTimeStarted={item.submittedAt} realtime={false} />
            </Col>
            <Col span={12} style={{ paddingLeft: "40px" }}>
              <div>Upload Progress</div>
              <div>
                <div>
                  {item.imageFiles_aggregate.aggregate.count} /{" "}
                  {item.totalImages} images
                </div>
                <Progress
                  percent={roundDecimals(
                    (item.imageFiles_aggregate.aggregate.count /
                      item.totalImages) *
                      100
                  )}
                />
              </div>
            </Col>
          </Row>

          <Divider style={{ margin: "8px" }} />

          <div>{item.hasGcp}</div>

          <Divider style={{ margin: "8px" }} />
          <div style={{ fontWeight: 500, marginBottom: "10px" }}>
            Processing Steps
          </div>
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <Badge status="success" text="Pre-Processing" />
            </Col>
            <Col span={6}>
              <Badge status="success" text="Step 1 - Calibation" />
            </Col>
            <Col span={6}>
              <Badge status="success" text="Step 2 - Densification" />
            </Col>
            <Col span={6}>
              <Badge status="success" text="Step 3 - Rasters" />
            </Col>
          </Row>
          <Divider style={{ margin: "8px" }} />
          <div>{item.contract.account.name}</div>
          <div>{item.contract.account.legal_name}</div>
        </div>
      </Card>
    </div>
  );
};

export default ProcessingCard;
