import { gql } from "@apollo/client";

export const USER_DETAIL_SUBSCRIPTION = gql`
  query UserDetailSubscription($id: uuid) {
    users(where: { id: { _eq: $id } }) {
      allow_password_change
      appearance
      avatar
      coordinateSystem
      createdAt
      created_by_id
      current_sign_in_at
      current_sign_in_ip
      dateFormat
      deletedAt
      deleted_by_id
      email
      encrypted_password
      failed_attempts
      id
      inclination_type
      industry
      invitation_accepted_at
      invitation_created_at
      invitation_limit
      invitation_sent_at
      invitations_count
      invitation_token
      invited_by_type
      invited_by_id
      jti
      last_sign_in_at
      language
      last_sign_in_ip
      locked_at
      name
      remember_created_at
      reset_password_sent_at
      reset_password_token
      role
      sign_in_count
      systemUnit
      timeFormat
      timezone
      unlock_token
      updatedAt
      updated_by_id
      userAccounts {
        role
        account {
          name
          organization_id
          legal_name
          id
          document_number
          district
        }
      }
      trackings(order_by: {createdAt: desc}) {
        createdAt
        currentIp
        id
        updatedAt
        locationData
      }
    }
  }
`;
