import { Table } from "ant-table-extensions";
import { Tag } from "antd";
import _ from "lodash";
import moment from "moment";
import React from 'react';

const AccountStatuses = ({ sourceData }) => {
  const columns = [
    {
      title: "Active?",
      render: (data) => <div><Tag color={data.is_active ? "success" : "error"}>{data.is_active ? "Yes" : "No"}</Tag></div>,
    },
    {
      title: "Reason",
      render: (data) => <div>{data.reason}</div>,
    },
    {
      title: "Start - End",
      align: "center",
      render: (data) => {
        return moment(data?.created_at).format("DD/MM/YYYY") + " - " + (data?.deleted_at ? moment(data?.deleted_at).format("DD/MM/YYYY") : "Present Day")
      }
    },
    {
      title: "Made By",
      align: "center",
      render: (data) => {
        return data?.created_by?.name ?? 'System'
      }
    },
  ];

  const setOrder = (paramsArr) => {
    const ordered = _.orderBy(paramsArr, ["created_at"], ["desc"]);
    return ordered;
  };

  return (
    <div>
      <div>
        <Table
          dataSource={setOrder(sourceData)}
          columns={columns}
          size="small"
          bordered={true}
          pagination={{ pageSize: "50" }}
          rowKey={(data) => data?.created_at}
        />
      </div>
    </div>
  );
};

export default AccountStatuses;
