import { CheckCircleTwoTone, CloseCircleOutlined } from "@ant-design/icons";
import { Badge, Descriptions } from "antd";
import moment from "moment";
import PropTypes from 'prop-types';
import React from 'react';

const TaskInfo = ({ taskData }) => {
  return (
    <div>
      <Descriptions title="Task Info" size="small" bordered>
        <Descriptions.Item label="Created At" span={2}>
          {moment(taskData.createdAt).format("DD/MM/YYYY HH:mm:ss")}
        </Descriptions.Item>
        <Descriptions.Item label="Started At" span={2}>
          {moment(taskData.startedAt).format("DD/MM/YYYY HH:mm:ss")}
        </Descriptions.Item>
        <Descriptions.Item label="Ended At" span={1}>
          {taskData.endedAt === null
            ? "-"
            : moment(taskData.endedAt).format("DD/MM/YYYY HH:mm:ss")}
        </Descriptions.Item>
        <Descriptions.Item label="GCP" span={1}>
          {taskData.hasGcp ? (
            <CheckCircleTwoTone twoToneColor="#52c41a" />
          ) : (
            <CloseCircleOutlined />
          )}
        </Descriptions.Item>
        <Descriptions.Item label="GSD" span={2}>
          {taskData.gsd === null ? "-" : taskData.gsd} cm/px
        </Descriptions.Item>

        <Descriptions.Item label="RMSE" span={2}>
          <Badge
            status={taskData.rmsX === null ? "error" : "success"}
            text={taskData.rmsX === null ? "-" : taskData.rmsX}
          />
          <br />
          <Badge
            status={taskData.rmsY === null ? "error" : "success"}
            text={taskData.rmsY === null ? "-" : taskData.rmsY}
          />
          <br />
          <Badge
            status={taskData.rmsZ === null ? "error" : "success"}
            text={taskData.rmsZ === null ? "-" : taskData.rmsZ}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Coord. System" span={2}>
          <Badge
            status={taskData.crsImages === null ? "error" : "success"}
            text={taskData.crsImages === null ? "-" : taskData.crsImages}
          />
          <br />
          <Badge
            status={taskData.crsOutput === null ? "error" : "success"}
            text={taskData.crsOutput === null ? "-" : taskData.crsOutput}
          />
          <br />
          <Badge
            status={taskData.crsGcp === null ? "error" : "success"}
            text={taskData.crsGcp === null ? "-" : taskData.crsGcp}
          />
        </Descriptions.Item>
      </Descriptions>
      <div style={{ fontSize: "10px" }}>{taskData.gsd}</div>
    </div>
  );
};

TaskInfo.propTypes = {
  taskData: PropTypes.object.isRequired
};

export default TaskInfo;
