import { useMutation } from "@apollo/client";
import { Button, Col, Form, Input, Modal, Popconfirm, Row } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useMemo, useState } from "react";
import { useAuth } from "../../../Auth";
import { UPDATE_STATUS } from "../AccountsQueries";

const ChangeStatusForm = ({ form, onFinish, onFailed, desc }) => {
  return (
    <Form
      layout={"vertical"}
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFailed}
      initialValues={{
        reason: null,
      }}
    >
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <Form.Item label="Motivo" name="reason"
            rules={[
              {
                required: true,
                message: 'Favor explicar o motivo da mudança',
              },
            ]}
          >
            <Input
              placeholder={`Motivo da ${desc.toLowerCase()} da conta`}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>

  );
};

const ChangeAccountStatusModal = ({ isOpen, setIsOpen, account, newStatus }) => {
  const [mutationLoading, setMutationLoading] = useState(false);
  const [updateStatus, updateStatusResult] = useMutation(UPDATE_STATUS);
  const [form] = useForm();
  const { currentUser: user } = useAuth();

  const description = useMemo(() => newStatus ? "reativar" : "desativar", [newStatus]);
  const title = useMemo(() => newStatus ? "Reativação" : "Desativação", [newStatus]);

  const handleSubmit = (mutationVariables) => {
    setMutationLoading(true);
    updateStatus({
      variables: mutationVariables,
    }).then(() => {
      console.log('success')
    }).finally(() => {
      setMutationLoading(false);
      setIsOpen(null);
    });
  };

  const formFinished = (formValue) => {
    // setIsOpen(false);
    const mutationVariables = {
      id: account.current_status[0].id,
      accountId: account.id,
      isActive: newStatus,
      reason: formValue.reason
    };
    handleSubmit(mutationVariables);
  };

  const formFailed = (...args) => {
    console.log(args);
    setIsOpen(null);
  };

  return (
    <div>
      <Modal
        title={`${title} da Conta`}
        visible={isOpen}
        onCancel={() => setIsOpen(null)}
        footer={[
          <Button key="back" onClick={() => setIsOpen(null)}>
            Cancelar
          </Button>,
          <Popconfirm key="action" disabled={!form.validateFields()}
            title={`Você deseja ${description} esta conta?`}
            onConfirm={() => form.validateFields() && form.submit()}
            okText="Sim"
            cancelText="Não"
          >
            <Button
              type="primary"
              loading={mutationLoading}
            >
              Salvar
            </Button>
          </Popconfirm>,
        ]}
      >
        <ChangeStatusForm
          form={form}
          onFailed={formFailed}
          onFinish={formFinished}
          desc={title}
        />
      </Modal>
    </div>
  );
};

export default ChangeAccountStatusModal;
