import { useMutation } from "@apollo/client";
import {
  Button,
  Collapse,
  DatePicker,
  Descriptions,
  Divider,
  Drawer,
  Form,
  Input,
  InputNumber, Modal,
  Typography,
  notification
} from "antd";
import "antd/dist/antd.min.css";
import moment from "moment";
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useAuth } from "../../../Auth";
import { CREATE_CONTRACT, DESTROY_CONTRACT } from "../ContractsQueries";

const { Title } = Typography;
const { Panel } = Collapse;

const openNotification = (data) => {
  var contractName = data?.insertContractsOne.name;
  notification.open({
    message: "Contrato Criado com Sucesso!",
    type: "success",
    description: contractName,
    onClick: () => {
      console.log("Notification Clicked!");
    },
  });
};

const singOrPlural = (count, sing, plural) => count > 1 ? plural : sing;

const NewContract = ({
  newContractModalVisible,
  setNewContractModalVisible,
  account
}) => {
  const [mutationLoading, setMutationLoading] = useState(false);
  const { currentUser: user } = useAuth();
  const formRef = useRef();
  const [createNewContract] = useMutation(CREATE_CONTRACT, { variables: { accountId: account?.id } });

  const [confirmationModalVisible, setConfirmationModalVisible] = useState(null);

  const currentContract = useMemo(() => account?.active_contract?.length > 0 && account?.active_contract[0], [account]);
  const currentStatus = useMemo(() => account?.current_status?.length > 0 && account?.current_status[0], [account]);

  const [destroyContract] = useMutation(DESTROY_CONTRACT, { variables: { statusId: currentStatus?.id, accountId: account?.id, id: currentContract?.id}});

  useEffect(() => {
    resetStates();
    console.log(account);
  }, )


  const resetStates = () => {
    // console.log(formRef);
    formRef?.current?.resetFields();
  };

  const onFinish = (values) => {
    const payload = {
      started_at: values.started_at,
      period_in_months: values.period_in_months,
      processing_quota: values.processing_quota,
      seats_quota: values.seats_quota,
      identifier: values.identifier
    }
    setConfirmationModalVisible(payload);
  };

  const onFinishFailed = (...args) => {
    console.log(args);
  }

  async function submitMutation(payload) {
    setMutationLoading(true);
    const newContractPayload = {
      "account_id": account?.id,
      "identifier": payload.identifier,
      "processing_quota": payload.processing_quota,
      "storage_quota": 0,
      "seats_quota": payload.seats_quota,
      "period_in_months": payload.period_in_months,
      "exceeded_image_value": 0,
      "exceeded_seat_value": 0,
      "exceeded_storage_value": 0,
      "started_at": payload.started_at.toISOString(),
      "imageUsageEntries": {
        "data": Array.from(Array(payload.period_in_months)).map((nothing, index) => {
          return {
            "entryDate": moment(payload.started_at).add(index, 'months').format('YYYY-MM-DD'),
            "updatedAt": "now()",
            "createdAt": "now()",
            "credit": payload.processing_quota,
            "debit": 0,
            "description": "creditDescription"
          };
        })
      }
    };
    let newStatusId = null;
    if (account?.active_contract?.length > 0) {
      const { data: { insertAccountStatusesOne } } = await destroyContract();
      newStatusId = insertAccountStatusesOne?.id;
      // console.log(data);
    } else {
      newStatusId = currentStatus?.id;
    }

    try {
      const { data } = await createNewContract({ variables: { object: newContractPayload, statusId: newStatusId } })
      openNotification(data);
      setTimeout(() => {
        setConfirmationModalVisible(false);
        setNewContractModalVisible(false);
      }, 1500);
    } catch (error) {
      alert("there was an error sending the query")
      console.warn("there was an error sending the query", error);
    }

    setMutationLoading(false);
    resetStates();
  }

  return (
    <div>
      <Drawer
        title="Criar novo Contrato"
        placement="right"
        visible={newContractModalVisible}
        onClose={() => setNewContractModalVisible(false)}
        width={700}
      >
        <Form
          ref={formRef}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{
            started_at: moment(),
            period_in_months: 1,
            processing_quota: 1000,
            seats_quota: 20,
            identifier: "CTRL0001"
          }}>
          <Title level={5}>Contrato</Title>
          <Form.Item
            rules={[{ required: true }]}
            label="Identificador"
            name="identifier">
            <Input
              style={{ maxWidth: '250px' }}
              maxLength="12"
              placeholder="CTRL0001"
            />
          </Form.Item>
          <Form.Item
            rules={[{ required: true }]}
            label="Data de Inicio"
            name="started_at">
            <DatePicker
              style={{ minWidth: '250px' }}
              showToday={true}
              format={"DD/MM/YYYY"}
            />
          </Form.Item>
          <Form.Item
            rules={[{ required: true }]}
            label="Duração (meses)"
            name="period_in_months">
            <InputNumber
              style={{ minWidth: '250px' }}
              min={1}
              max={99}
            />
          </Form.Item>
          <Form.Item
            rules={[{ required: true }]}
            label="Qtde Imagens"
            name="processing_quota">
            <InputNumber
              style={{ minWidth: '250px' }}
              min={1}
              max={99999}
            />
          </Form.Item>
          <Form.Item
            rules={[{ required: true }]}
            label="Qtde Assentos"
            name="seats_quota">
            <InputNumber
              style={{ minWidth: '250px' }}
              min={1}
              max={50}
            />
          </Form.Item>

          <Divider />
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
            >
              Adicionar Novo contrato
            </Button>
          </Form.Item>
        </Form>

      </Drawer>
      <Modal
        destroyOnClose={true}
        title="Confirmação da Criação de Contrato"
        visible={!!confirmationModalVisible}
        afterClose={() => setConfirmationModalVisible(null)}
        width={600}
        footer={[
          <Button key="back" onClick={() => setConfirmationModalVisible(null)}>
            Retornar
          </Button>,
          <Button
            key="create_contract"
            loading={mutationLoading}
            type={"primary"}
            onClick={() => submitMutation(confirmationModalVisible)}
          >
            Criar Conta
          </Button>,
        ]}
      >
        <Descriptions title="Informações da conta" bordered size="small">
          <Descriptions.Item label="Nome Projeto" span={3}>
            {account?.name}
          </Descriptions.Item>
          <Descriptions.Item label="Empresa" span={3}>
            <div>
              <div>{account?.organization?.name}</div>
            </div>
          </Descriptions.Item>
        </Descriptions>
        <br/>
        <Collapse defaultActiveKey={['newContract']} onChange={(e) => console.log(e)}>
          {currentContract && <Panel header={`O Contrato ${currentContract.identifier} será encerrado`} key="oldContract" style={{ backgroundColor: '#ffccc7'}}>
            <Descriptions bordered size="small">
              <Descriptions.Item label="Vigência Contrato" span={3}>
                {moment(currentContract.started_at).format("DD/MM/YYYY")} até {moment(currentContract.started_at).add(currentContract.period_in_months, 'months').format("DD/MM/YYYY")}
              </Descriptions.Item>
              <Descriptions.Item label="Período" span={3}>
                {currentContract.period_in_months} {singOrPlural(currentContract.period_in_months, "Mês", "Meses")}
              </Descriptions.Item>
              <Descriptions.Item label="Imagens por período" span={3}>
                {currentContract.processing_quota} Imagens
              </Descriptions.Item>
              <Descriptions.Item label="Número de Assentos" span={3}>
                {currentContract.seats_quota} Assentos
              </Descriptions.Item>
            </Descriptions>
          </Panel>}
          <Panel header={`O contrato ${confirmationModalVisible?.identifier} será criado`} key="newContract">
            <Descriptions bordered size="small">
              <Descriptions.Item label="Vigência Contrato" span={3}>
                {moment(confirmationModalVisible?.started_at).format("DD/MM/YYYY")} até {moment(confirmationModalVisible?.started_at).add(confirmationModalVisible?.period_in_months, 'months').format("DD/MM/YYYY")}
              </Descriptions.Item>
              <Descriptions.Item label="Período" span={3}>
                {confirmationModalVisible?.period_in_months} {singOrPlural(confirmationModalVisible?.period_in_months, "Mês", "Meses")}
              </Descriptions.Item>
              <Descriptions.Item label="Imagens por período" span={3}>
                {confirmationModalVisible?.processing_quota} Imagens
              </Descriptions.Item>
              <Descriptions.Item label="Número de Assentos" span={3}>
                {confirmationModalVisible?.seats_quota} Assentos
              </Descriptions.Item>
            </Descriptions>
          </Panel>
        </Collapse>
      </Modal>
    </div>
  );
};

NewContract.propTypes = {
  newContractModalVisible: PropTypes.bool,
  setNewContractModalVisible: PropTypes.func,
  account: PropTypes.object
}

export default NewContract;
