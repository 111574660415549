import { Badge, Col, Collapse, Divider, PageHeader, Row } from "antd";
import React, { useState } from "react";

import { useSubscription } from "@apollo/client";
import { Skeleton } from "antd";
import { Content } from "antd/lib/layout/layout";
import _ from 'lodash';
import ProcessingCard from "./ProcessingCard";
import { PROCESSINGS_MUTATION_MONITORING } from "./ProcessingMonitoringQueries";
import { initialProcessing } from "./ProcessingsUtils";

const { Panel } = Collapse;

// const MonitoringCards = () => {
//   return <div></div>;
// };

const ProcessingMonitoring = () => {
  const [, setProcessingsData] = useState([]);
  const [groupedData, setGroupedData] = useState([]);
  const [dataProcessed, setDataProcessed] = useState(false);
  const { loading } = useSubscription(
    PROCESSINGS_MUTATION_MONITORING,
    {
      onSubscriptionData: ({ subscriptionData: { data } }) => {
        setProcessingsData(data.processings);
        console.log(data.processings);
        const grouped = initialProcessing(
          _.groupBy(data.processings, "status")
        );
        setGroupedData(grouped);
        console.log(grouped);
        setDataProcessed(true);
      },
    }
  );
  const sectionTitle = "Processing Monitoring";
  function callback(key) {
    console.log(key);
  }

//   const text = `
//   Paused
//   Uploading
//   Upload Finished
//   Processing Queue
//   Pre Processing
//   Intervention
//   Processing
//   Processing Failed
//   Quality Assurance

// `;

  const AccordionHeader = ({ data, title }) => {
    return (
      <Row gutter={24} style={{ width: "100%" }}>
        <Col span={6}>
          <div style={{ fontWeight: 500 }}>{title}</div>
        </Col>
        <Col span={6}></Col>
        <Col span={6}></Col>
        <Col span={6} align={"right"}>
          <Badge count={data.length} />
        </Col>
      </Row>
    );
  };

  const handleMoreInfo = (params) => {
    console.log(params);
  };

  return (
    <div>
      <Content style={{ margin: "0 16px" }}>
        <div
          className="site-page-header-ghost-wrapper"
          style={{ marginTop: "10px" }}
        >
          <PageHeader
            ghost={false}
            onBack={() => window.history.back()}
            title={sectionTitle}
          >
            <Divider />
            {!dataProcessed && <Skeleton />}
            {dataProcessed && !loading && (
              <div>
                <Collapse
                  defaultActiveKey={["UPLOAD_PAUSED"]}
                  onChange={callback}
                >
                  <Panel
                    style={{ width: "100%" }}
                    header={
                      <AccordionHeader
                        data={groupedData.UPLOAD_PAUSED}
                        title={"UPLOAD PAUSED"}
                      />
                    }
                    key="UPLOAD_PAUSED"
                  >
                    <div className="site-card-wrapper">
                      <Row gutter={16}>
                        {groupedData.UPLOAD_PAUSED.map((item) => (
                          <React.Fragment key={item.id}>
                            <Col span={8}>
                              <ProcessingCard
                                item={item}
                                handleMoreInfo={handleMoreInfo}
                              />
                            </Col>
                          </React.Fragment>
                        ))}
                      </Row>
                    </div>
                  </Panel>
                  <Panel
                    style={{ width: "100%" }}
                    header={
                      <AccordionHeader
                        data={groupedData.UPLOADING}
                        title={"UPLOADING"}
                      />
                    }
                    key="UPLOADING"
                  >
                    <div className="site-card-wrapper">
                      <Row gutter={16}>
                        {groupedData.UPLOADING.map((item) => (
                          <React.Fragment key={item.id}>
                            <Col span={8}>
                              <ProcessingCard
                                item={item}
                                handleMoreInfo={handleMoreInfo}
                              />
                            </Col>
                          </React.Fragment>
                        ))}
                      </Row>
                    </div>
                  </Panel>
                  <Panel
                    style={{ width: "100%" }}
                    header={
                      <AccordionHeader
                        data={groupedData.UPLOAD_FINISHED}
                        title={"UPLOAD FINISHED"}
                      />
                    }
                    key="UPLOAD_FINISHED"
                  >
                    <div className="site-card-wrapper">
                      <Row gutter={16}>
                        {groupedData.UPLOAD_FINISHED.map((item) => (
                          <React.Fragment key={item.id}>
                            <Col span={8}>
                              <ProcessingCard
                                item={item}
                                handleMoreInfo={handleMoreInfo}
                              />
                            </Col>
                          </React.Fragment>
                        ))}
                      </Row>
                    </div>
                  </Panel>
                  <Panel
                    style={{ width: "100%" }}
                    header={
                      <AccordionHeader
                        data={groupedData.PROCESSING_QUEUE}
                        title={"PROCESSING QUEUE"}
                      />
                    }
                    key="PROCESSING_QUEUE"
                  >
                    <div className="site-card-wrapper">
                      <Row gutter={16}>
                        {groupedData.PROCESSING_QUEUE.map((item) => (
                          <React.Fragment key={item.id}>
                            <Col span={8}>
                              <ProcessingCard
                                item={item}
                                handleMoreInfo={handleMoreInfo}
                              />
                            </Col>
                          </React.Fragment>
                        ))}
                      </Row>
                    </div>
                  </Panel>
                  {/* <Panel
                    style={{ width: "100%" }}
                    header={
                      <AccordionHeader
                        data={groupedData.PRE_PROCESSING}
                        title={"PRE PROCESSING"}
                      />
                    }
                    key="PRE_PROCESSING"
                  >
                    <div className="site-card-wrapper">
                      <Row gutter={16}>
                        {groupedData.PRE_PROCESSING.map((item) => (
                          <React.Fragment key={item.id}>
                            <Col span={8}>
                              <ProcessingCard
                                item={item}
                                handleMoreInfo={handleMoreInfo}
                              />
                            </Col>
                          </React.Fragment>
                        ))}
                      </Row>
                    </div>
                  </Panel> */}
                  <Panel
                    style={{ width: "100%" }}
                    header={
                      <AccordionHeader
                        data={groupedData.INTERVENTION}
                        title={"INTERVENTION"}
                      />
                    }
                    key="INTERVENTION"
                  >
                    <div className="site-card-wrapper">
                      <Row gutter={16}>
                        {groupedData.INTERVENTION.map((item) => (
                          <React.Fragment key={item.id}>
                            <Col span={8}>
                              <ProcessingCard
                                item={item}
                                handleMoreInfo={handleMoreInfo}
                              />
                            </Col>
                          </React.Fragment>
                        ))}
                      </Row>
                    </div>
                  </Panel>
                  <Panel
                    style={{ width: "100%" }}
                    header={
                      <AccordionHeader
                        data={groupedData.PROCESSING}
                        title={"PROCESSING"}
                      />
                    }
                    key="PROCESSING"
                  >
                    <div className="site-card-wrapper">
                      <Row gutter={16}>
                        {groupedData.PROCESSING.map((item) => (
                          <React.Fragment key={item.id}>
                            <Col span={8}>
                              <ProcessingCard
                                item={item}
                                handleMoreInfo={handleMoreInfo}
                              />
                            </Col>
                          </React.Fragment>
                        ))}
                      </Row>
                    </div>
                  </Panel>
                  <Panel
                    style={{ width: "100%" }}
                    header={
                      <AccordionHeader
                        data={groupedData.PROCESSING_FAILED}
                        title={"PROCESSING FAILED"}
                      />
                    }
                    key="FAILED"
                  >
                    <div className="site-card-wrapper">
                      <Row gutter={16}>
                        {groupedData.PROCESSING_FAILED.map((item) => (
                          <React.Fragment key={item.id}>
                            <Col span={8}>
                              <ProcessingCard
                                item={item}
                                handleMoreInfo={handleMoreInfo}
                              />
                            </Col>
                          </React.Fragment>
                        ))}
                      </Row>
                    </div>
                  </Panel>
                  {/* <Panel
                    style={{ width: "100%" }}
                    header={
                      <AccordionHeader
                        data={groupedData.QUALITY_ASSURANCE}
                        title={"QUALITY_ASSURANCE"}
                      />
                    }
                    key="QUALITY_ASSURANCE"
                  >
                    <div className="site-card-wrapper">
                      <Row gutter={16}>
                        {groupedData.QUALITY_ASSURANCE.map((item) => (
                          <React.Fragment key={item.id}>
                            <Col span={8}>
                              <ProcessingCard
                                item={item}
                                handleMoreInfo={handleMoreInfo}
                              />
                            </Col>
                          </React.Fragment>
                        ))}
                      </Row>
                    </div>
                  </Panel> */}
                </Collapse>
              </div>
            )}
          </PageHeader>
        </div>
      </Content>
    </div>
  );
};

export default ProcessingMonitoring;
