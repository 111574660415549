import { Button, PageHeader, Skeleton, Tag, Tooltip } from "antd";
import React, { useState } from "react";

import { useSubscription } from "@apollo/client";
import { Table } from "ant-table-extensions";
import { Content } from "antd/lib/layout/layout";
import _ from "lodash";
import moment from "moment";
import { Link } from "react-router-dom";
import { EXPORTS_SUBSCRIPTION } from "./ExportsQueries";
import { getStatusString } from "./ExportUtils";

const ExportsStatusCell = ({ data }) => {
  return (
    <div>
      <Tag color={data.tagColor}>{data.statusStringDisplay}</Tag>
    </div>
  );
};

const RecepientsCell = ({ data }) => {
  const ToolTipContainer = ({ data }) => {
    return (
      <div>
        {data.map((item) => (
          <React.Fragment key={item}>
            <dt>{item}</dt>
          </React.Fragment>
        ))}
      </div>
    );
  };

  return (
    <div>
      <Tooltip title={<ToolTipContainer data={data} />}>
        <div>{data.length}</div>
      </Tooltip>
    </div>
  );
};

const ExportsTable = ({ sourceData, filterSourceData }) => {
  const columns = [
    {
      title: "Processing",
      render: (data) => (
        <div>
          <div>{data.processing.name}</div>
          <div>{data.processing.id}</div>
        </div>
      ),
    },
    {
      title: "ID",
      render: (data) => <div>{data.id}</div>,
    },
    {
      title: "Account/Contract",
      filters: filterSourceData.filterAccounts,
      onFilter: (value, record) =>
        record.processing.contract.account.name.startsWith(value),
      filterSearch: true,
      render: (data) => (
        <div>
          <div>
            <b>ACC:</b> {data.processing.contract.account.name}
          </div>
          <div>
            <b>ORG:</b> {data.processing.contract.account.organization.name}
          </div>
          <div>
            <b>CTR:</b> {data.processing.contract.identifier}
          </div>
        </div>
      ),
    },
    {
      title: "Data",
      render: (data) => (
        <div>
          <div>
            <div>
              <b>Type:</b> {data.data}
            </div>
            <div>
              <b>Format:</b> {data.format}
            </div>
            <div>
              <b>Res:</b> {data.resolution}
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      render: (data) => <div>{moment(data).format("DD-MM-YYYY HH:mm:ss")}</div>,
    },
    {
      title: "Requested By",
      filters: filterSourceData.filterEmails,
      onFilter: (value, record) => record.requestedBy.email.startsWith(value),
      filterSearch: true,
      render: (data) => <div>{data.requestedBy.email}</div>,
    },
    {
      title: "Status",
      width: 190,
      align: "center",
      render: (data) => (
        <ExportsStatusCell data={getStatusString(data.status)} />
      ),
    },
    {
      title: "Recepients",
      width: 190,
      align: "center",
      render: (data) => <RecepientsCell data={data.emails} />,
    },
    {
      title: "Action",
      render: (data) => (
        <Button size="small" style={{ fontSize: "12px" }}>
          <Link
            to={{
              pathname: `/view-export-details/${data.id}`,
              state: { contract: data.id },
            }}
          >
            More Info
          </Link>
        </Button>
      ),
    },
  ];
  return (
    <div>
      <Table
        dataSource={sourceData}
        columns={columns}
        size="small"
        bordered={true}
        searchable={true}
        pagination={{ pageSize: "50" }}
      />
    </div>
  );
};

const PageContent = ({ sourceData, filterSourceData }) => {
  // const [clickedExport, setClickedExport] = useState(
  //   "b914d275-ee48-44d6-8218-0d9c4c90b6fc"
  // );

  const initialStyle = { padding: "24px" };

  return (
    <div style={initialStyle}>
      <ExportsTable
        sourceData={sourceData}
        filterSourceData={filterSourceData}
      />
    </div>
  );
};

const Exports = () => {
  const sectionTitle = "Exports";
  const [dataProcessed, setDataProcessed] = useState(false);
  const [filterData, setFilterData] = useState(false);
  const [exportsData, setExportsData] = useState([]);
  useSubscription(EXPORTS_SUBSCRIPTION, {
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      const processedData = initialProcessing(data.exports);
      console.log(processedData);
      setExportsData(processedData);
      var flData = processFilterData(data.exports);
      setFilterData(flData);
      setDataProcessed(true);
    },
  });

  const initialProcessing = (data) => {
    const newRecords = [];
    data.forEach((item) => {
      item.key = item.id;
      console.log(item);
      newRecords.push(item);
    });
    const ordered = _.orderBy(newRecords, ["createdAt"], ["desc"]);
    return ordered;
  };

  const processFilterData = (arr) => {
    var filterAccounts = [];
    var filterEmails = [];
    arr.forEach((item) => {
      var filterAccountObj = {
        text: item.processing.contract.account.name,
        value: item.processing.contract.account.name,
        key: item.processing.contract.account.name,
      };
      filterAccounts.push(filterAccountObj);
      var filterEmailObj = {
        text: item.requestedBy.email,
        value: item.requestedBy.email,
        key: item.requestedBy.email,
      };
      filterEmails.push(filterEmailObj);
    });
    var filterPayload = {
      filterAccounts: _.uniqBy(filterAccounts, "text"),
      filterEmails: _.uniqBy(filterEmails, "text"),
    };
    return filterPayload;
  };
  return (
    <div>
      <div>
        <Content style={{ margin: "0 16px", backgroundColor: "white" }}>
          <div
            className="site-page-header-ghost-wrapper"
            style={{ marginTop: "10px" }}
          >
            <PageHeader
              ghost={false}
              onBack={() => window.history.back()}
              title={sectionTitle}
            ></PageHeader>
            {!dataProcessed && (
              <div style={{ padding: "24px" }}>
                <Skeleton active={true} />
              </div>
            )}
            {dataProcessed && (
              <PageContent
                sourceData={exportsData}
                filterSourceData={filterData}
              />
            )}
          </div>
        </Content>
      </div>
    </div>
  );
};

export default Exports;
