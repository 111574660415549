import { Drawer } from "antd";
import React, { useEffect, useState } from "react";

const AdminUserSettings = ({ visible, setVisible, userData }) => {
  const [email, setemail] = useState("");
  // const showDrawer = () => {
  //   setVisible(true);
  // };

  const onClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    console.log(userData);
    if (userData === null) {
    } else {
      setemail(userData.email);
    }
  }, [userData]);
  return (
    <div>
      <Drawer
        title="Admin User Settings"
        placement="right"
        onClose={onClose}
        visible={visible}
      >
        <div> Logged as {email}</div>
      </Drawer>
    </div>
  );
};

export default AdminUserSettings;
