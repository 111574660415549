// import { gql, useQuery } from "@apollo/client";

import { useMutation, useQuery } from "@apollo/client";
import _ from "lodash";
import React, { useMemo, useState } from 'react';
import Creatable from 'react-select/creatable';
import { CREATE_ORGANIZATION, ORGANIZATIONS_QUERY } from "./OrganizationsQuery";

// const QUERY_SELECT = gql`
//   query MyQuery {
//     empresa {
//       id
//       razao_social
//       cnpj
//     }
//   }
// `;

// const customStyles = (danger = false) => ({
//   control: (provided, _) => ({
//     ...provided,
//     borderRadius: "2px",
//     // fontSize: "12px",
//     borderColor: danger ? provided.borderColor : ,
//   }),
//   option: (provided, _) => ({
//     ...provided,
//     borderRadius: "2px",
//     // fontSize: "12px",
//   }),
//   menuList: (provided, _) => ({
//     ...provided,
//     // fontSize: "12px",
//   }),
// });

export const OrganizationsSelector = ({ id, value, onChange }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [ createHasError, setCreateHasError ] = useState(false);
  const { loading, data, refetch } = useQuery(ORGANIZATIONS_QUERY);
  const [ createOrganization, ] = useMutation(CREATE_ORGANIZATION);
  const organizations = useMemo(() => _.orderBy(data?.organizations ?? [], ["createdAt"], ["desc"]).map(item => ({ value: item.id, label: item.name, id: item.id })), [data]);

  const isAllLoading = useMemo(() => isLoading || loading, [isLoading, loading]);

  const selectedOrganization = useMemo(() => value ? organizations?.find(item => item.id === value.id) : null, [value, organizations]);

  const handleCreate = (value) => {
    const name = value?.trim() ?? null;
    if (!name) return;
    setIsLoading(true);
    createOrganization({
      variables: {name},
    }).then(({data, error}) => {
      if (data) {
        refetch().then(() => {
          onChange(data.insertOrganizationsOne);
        });
      } else if (error) {
        setCreateHasError(true);
      }
    }).finally(() => {
      setIsLoading(false);
    });
  }

  return (
    <Creatable
      id={id}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          borderColor: createHasError ? 'red' : baseStyles.borderColor,
          borderRadius: "2px",
        }),
      }}
      isClearable
      isLoading={isAllLoading}
      isDisabled={isAllLoading}
      options={organizations}
      placeholder="Selecione ou crie a Organização"
      // styles={customStyles}
      value={selectedOrganization}
      // formatOptionLabel={formatOptionLabel}
      onChange={(e) => onChange(e)}
      onCreateOption={handleCreate}
    ></Creatable>
  );
};
