import { useMutation } from "@apollo/client";
import { Button, Col, DatePicker, Form, Input, Modal, Popconfirm, Row } from "antd";
import { useForm } from "antd/lib/form/Form";
import moment from "moment";
import React, { useState } from "react";
import { ADD_CREDIT_CONTRACT } from "../AccountsQueries";

const AddCreditForm = ({ form, onFinish, onFailed }) => {
  return (
    <Form
      layout={"vertical"}
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFailed}
      initialValues={{
        credit: 1000,
        entry_date: moment(),
      }}
    >
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <Form.Item label="Qtde de Imagens" name="credit">
            <Input
              placeholder="Qtde de Imagens"

            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Data do Crédito" name="entry_date">
            <DatePicker
              style={{ width: '100%' }}
              showToday={true}
              format={"DD/MM/YYYY"}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>

  );
};

const AddCreditModal = ({ isOpen, setIsOpen, contractId }) => {
  const [mutationLoading, setMutationLoading] = useState(false);
  const [addCreditByInsert,] = useMutation(ADD_CREDIT_CONTRACT);
  const [form] = useForm();
  // const clearStates = () => {
  //   setOrganizationName("");
  // };

  if (!contractId && isOpen) {
    throw new Error("No contractID in props");
  }

  const handleSubmit = (mutationVariables) => {
    setMutationLoading(true);
    addCreditByInsert({
      variables: mutationVariables,
    }).then(() => {
      console.log('success')
    }).finally(() => {
      setMutationLoading(false);
      setIsOpen(false);
    });
  };

  const formFinished = (formValue) => {
    setIsOpen(false);
    const mutationVariables = {
      contractId,
      credit: formValue.credit,
      entryDate: formValue.entry_date
    };
    handleSubmit(mutationVariables);
  };

  const formFailed = (...args) => {
    console.log(args);
    setIsOpen(false);
  };
  return (
    <div>
      <Modal
        title="Adicionar Crédito"
        visible={isOpen}
        onCancel={() => setIsOpen(false)}
        footer={[
          <Button key="back" onClick={() => setIsOpen(false)}>
            Cancelar
          </Button>,
          <Popconfirm key="action"
            title="Você quer adicionar crédito para esta conta?"
            onConfirm={() => form.submit()}
            okText="Sim"
            cancelText="Não"
          >
            <Button
              type="primary"
              loading={mutationLoading}
            >
              Salvar
            </Button>
          </Popconfirm>,
        ]}
      >
        <AddCreditForm
          form={form}
          onFailed={formFailed}
          onFinish={formFinished}
        />
      </Modal>
    </div>
  );
};

export default AddCreditModal;
