import {
  DatabaseOutlined,
  UploadOutlined,
  UserOutlined
} from "@ant-design/icons";
import { gql, useSubscription } from "@apollo/client";
import { Col, PageHeader, Row, Statistic } from "antd";

import { Content } from "antd/lib/layout/layout";
import React from "react";

const UsersStatistic = () => {
  const USERS_COUNT_SUBSCRIPTION = gql`
    subscription users {
      usersAggregate {
        aggregate {
          count
        }
      }
    }
  `;
  const { data, loading } = useSubscription(USERS_COUNT_SUBSCRIPTION);

  return (
    <Statistic
      title="Active Users"
      prefix={<UserOutlined />}
      value={loading === true ? "" : data.usersAggregate.aggregate.count}
      loading={loading}
    />
  );
};

const ProcessingStatistic = () => {
  const PROCESSING_COUNT_SUBSCRIPTION = gql`
    subscription processings {
      processingsAggregate {
        aggregate {
          count
        }
      }
    }
  `;
  const { data, loading } = useSubscription(
    PROCESSING_COUNT_SUBSCRIPTION
  );

  return (
    <Statistic
      title="Total Processings"
      prefix={<UploadOutlined />}
      value={loading === true ? "" : data.processingsAggregate.aggregate.count}
      loading={loading}
    />
  );
};

function bytesToSize(bytes) {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Byte";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
}

const StorageStatistic = () => {
  const STORAGE_SUM_SUBSCRIPTION = gql`
    subscription users {
      processingsAggregate {
        aggregate {
          sum {
            totalBytes
            totalImages
            totalPixels
          }
        }
      }
    }
  `;
  const { data, loading } = useSubscription(STORAGE_SUM_SUBSCRIPTION);

  return (
    <Statistic
      title="Total Storage"
      prefix={<DatabaseOutlined />}
      value={
        loading === true
          ? ""
          : bytesToSize(data.processingsAggregate.aggregate.sum.totalBytes)
      }
      loading={loading}
    />
  );
};

const PageContent = () => {
  const initialStyle = { padding: "24px" };
  const itemStyle = {
    padding: "10px",
    border: "1px solid #d9d9d9",
    width: "150px",
  };

  return (
    <div style={initialStyle}>
      <Row gutter={16}>
        <Col className="gutter-row">
          <div style={itemStyle}>
            <UsersStatistic />
          </div>
        </Col>
        <Col className="gutter-row">
          <div style={itemStyle}>
            <ProcessingStatistic />
          </div>
        </Col>{" "}
        <Col className="gutter-row">
          <div style={itemStyle}>
            <StorageStatistic />
          </div>
        </Col>
      </Row>
    </div>
  );
};

const Dashboard = () => {
  const sectionTitle = "Dashboard";
  return (
    <div>
      <Content style={{ margin: "0 16px", backgroundColor: "white" }}>
        <div
          className="site-page-header-ghost-wrapper"
          style={{ marginTop: "10px" }}
        >
          <PageHeader
            ghost={false}
            onBack={() => window.history.back()}
            title={sectionTitle}
          ></PageHeader>
          <PageContent />
        </div>
      </Content>
    </div>
  );
};

export default Dashboard;
