import { CheckCircleTwoTone, CloseCircleOutlined } from "@ant-design/icons";
import {
  Badge,
  Button,
  Card,
  Descriptions,
  Divider,
  Dropdown,
  Layout,
  Menu,
  PageHeader,
  Progress,
  Row,
  Skeleton,
  Tabs,
  Tag,
  Tooltip
} from "antd";

import { CopyOutlined, DownOutlined } from "@ant-design/icons";
import { useSubscription } from "@apollo/client";
import { message } from "antd";
import moment from "moment";
import Rainbow from "rainbowvis.js";
import React, { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useLocation } from "react-router-dom";
import { ReactComponent as GCSLogo } from "../../assets/custom-icons/gcs.svg";
import ElapseTimeComponent from "../ProcessingMonitoring/ProcessingMonitoringShared";
import { roundDecimals } from "../ProcessingMonitoring/ProcessingsUtils";
import GCPsTable from "./GCPsTable";
import { PROCESSING_MUTATION } from "./ProcessingDetailQueries";
import ProcessingTasks from "./ProcessingTasks";

const { TabPane } = Tabs;
const { Content } = Layout;
const colormap = new Rainbow();

colormap.setSpectrum("#52c41a", "#ffec3d", "#ff4d4f");

const PageContent = ({ state }) => {
  const [, setTabState] = useState();
  const initialStyle = { padding: "24px" };
  function callbackTabsChange(key) {
    setTabState(key);
  }
  return (
    <div style={initialStyle}>
      <div>
        <Tabs
          defaultActiveKey="1"
          onChange={callbackTabsChange}
          style={{ borderTop: "1px solid #d9d9d9" }}
        >
          <TabPane tab="Tasks" key="tasks">
            <ProcessingTasks sourceData={state}></ProcessingTasks>
          </TabPane>
          <TabPane tab="GCPs" key="gcps">
            <GCPsTable dataSource={state.processingGcps} />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

const PageHeaderComponent = ({ state }) => {
  const info = (data) => {
    message.info(data);
  };

  const parseProjection = (crsRaw) => {
    try {
      var srtext = crsRaw.srtext;
      const regexp = new RegExp(/PROJCS\["(.*)",GEOGCS/);
      var crs = srtext.match(regexp)[1];
    } catch (e) {
      crs = "Erro";
    }
    return crs;
  };

  return (
    <div>
      <Row>
        <Descriptions size="small" column={3} colon={false}>
          <Descriptions.Item label={state.id}>
            <CopyToClipboard text={state.id}>
              <Button
                icon={<CopyOutlined />}
                size="small"
                onClick={() =>
                  info(`O ID do contrato foi copiado com sucesso!`)
                }
              />
            </CopyToClipboard>
          </Descriptions.Item>
        </Descriptions>
      </Row>
      <Divider />
      <Card title={"General Information"}>
        <Descriptions size="small" bordered>
          <Descriptions.Item label="Images Upload Status" span={5}>
            <Badge
              status={
                state.imageFiles_aggregate.aggregate.count /
                  state.totalImages !==
                1
                  ? "warning"
                  : "success"
              }
              text={`${state.imageFiles_aggregate.aggregate.count} / ${state.totalImages}`}
            />
            <br />
            <Tooltip
              title={
                (
                  roundDecimals(
                    state.imageFiles_aggregate.aggregate.count /
                      state.totalImages
                  ) * 100
                ).toString() + " %"
              }
            >
              <Progress
                showInfo={false}
                size="small"
                trailColor="#cbcbcb"
                strokeColor={`#${colormap.colourAt(
                  (
                    state.imageFiles_aggregate.aggregate.count /
                    state.totalImages
                  ).progress * 100 || 0
                )}`}
                percent={
                  (state.imageFiles_aggregate.aggregate.count /
                    state.totalImages) *
                  100
                }
              />
            </Tooltip>
          </Descriptions.Item>
          <Descriptions.Item label="Submitted At">
            {moment(state.submittedAt).format("DD/MM/YYYY HH:mm:ss")}
            <br />
          </Descriptions.Item>
          <Descriptions.Item label="Elapsed Time">
            <ElapseTimeComponent dateTimeStarted={state.submittedAt} />
          </Descriptions.Item>
          <Descriptions.Item label="Has GCP">
            {!state.hasGcp && <CloseCircleOutlined />}
            {state.hasGcp && (
              <div>
                <CheckCircleTwoTone twoToneColor="#52c41a" /> GCPs Number :{" "}
                {state.processingGcps.length}
              </div>
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Location">
            {state.location}
          </Descriptions.Item>

          <Descriptions.Item label="Horiz. Coordinate System">
            {parseProjection(state.spatial_ref_sys)}
          </Descriptions.Item>
          <Descriptions.Item label="Start Process After Upload">
            {!state.processAfterUpload && <CloseCircleOutlined />}
            {state.processAfterUpload && (
              <CheckCircleTwoTone twoToneColor="#52c41a" />
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Submitted By">
            {state.submittedBy.email}
          </Descriptions.Item>
          <Descriptions.Item label="Submitted By">
            {state.submittedBy.email}
          </Descriptions.Item>
          <Descriptions.Item label="Reference Links">
            <Button size="small">Open</Button>
          </Descriptions.Item>
        </Descriptions>
      </Card>
    </div>
  );
};

const ProcessingDetail = () => {
  const processingId = useLocation().pathname.replace(
    "/view-processing-details/",
    ""
  );

  // const [elapseTime, setElapseTime] = useState("");
  const [state, setstate] = useState([]);
  const [processing, setProcessing] = useState(true);
  const [setIsVisible] = useState(false);
  const { loading } = useSubscription(PROCESSING_MUTATION, {
    variables: { id: processingId },
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      const processing = data.processings[0];
      console.log(processing);
      setstate(processing);
      setProcessing(false);
    },
  });

  const menu = (
    <Menu>
      <Menu.Item
        key="rule_assingment"
        icon={<GCSLogo style={{ width: "20px", height: "20px" }} />}
      >
        <div onClick={() => setIsVisible(true)}>Open GCS Folder</div>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="other_rule" disabled>
        3rd menu item（disabled）
      </Menu.Item>
    </Menu>
  );

  const HandleChangeStatus = () => {};
  return (
    <div>
      {processing === true ? (
        <Content style={{ margin: "0 16px" }}>
          <div
            className="site-page-header-ghost-wrapper"
            style={{ marginTop: "10px" }}
          ></div>
        </Content>
      ) : (
        <div>
          <Content style={{ margin: "0 16px", backgroundColor: "white" }}>
            <div
              className="site-page-header-ghost-wrapper"
              style={{ marginTop: "10px" }}
            >
              <PageHeader
                ghost={false}
                onBack={() => window.history.back()}
                title={
                  loading ? (
                    <Skeleton.Input
                      style={{ width: 100 }}
                      active={true}
                      size={"small"}
                    />
                  ) : (
                    state.name
                  )
                }
                tags={
                  loading ? (
                    <Skeleton.Input
                      style={{ width: 100 }}
                      active={true}
                      size={"small"}
                    />
                  ) : (
                    <Tag color="blue" onClick={() => HandleChangeStatus()}>
                      {state.status.replace("_", " ")}
                    </Tag>
                  )
                }
                extra={[
                  <Dropdown overlay={menu}>
                    <Button
                      className="ant-dropdown-link"
                      onClick={(e) => e.preventDefault()}
                    >
                      Actions <DownOutlined />
                    </Button>
                  </Dropdown>,
                ]}
              >
                <PageHeaderComponent state={state} />
              </PageHeader>
            </div>
            {!processing && <PageContent state={state}></PageContent>}
          </Content>
        </div>
      )}
    </div>
  );
};

export default ProcessingDetail;
