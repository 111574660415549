import { Table } from "ant-table-extensions";
import {
  Button,
  Col,
  Divider,
  Progress,
  Row,
  Statistic
} from "antd";
import moment from 'moment';
import React, { useMemo, useState } from "react";
import AddCreditModal from "../../../Accounts/AccountDetail/AddCreditModal";
// const { Title } = Typography;

const currencyFormat = (value) => {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value);
};

const ContractImageUsageEntries = ({
  contractId,
  imageEntries,
  aggregate,
  quota
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  console.log({contractId, imageEntries})

  const total = useMemo(() => (aggregate?.sum?.credit ?? 0) - (aggregate?.sum?.debit ?? 0), [aggregate]);

  const usageDescription = (text, record) => {
    switch (text) {
      case 'creditDescription':
        return 'Monthly Images Quota';
      case 'processingDescription':
        return `Debit - Processing ID: ${record.processingId}`;
      case 'expireCreditDescription':
        return 'Credit Expired due to the end of contract';
      default:
        return `Unknown description: ${record.description}`
    }
  }

  const columns = [
    {
      title: "Description",
      dataIndex: "description",
      render: usageDescription,
    },
    {
      title: "Debit",
      dataIndex: "debit",
    },
    {
      title: "Credit",
      dataIndex: "credit",
    },
    {
      title: "Entry Date",
      dataIndex: "entryDate",
      render: text => moment(text).format('DD/MM/YYYY'),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      render: text => moment(text).format('DD/MM/YYYY \\a\\s HH:mm:ss'),
    }
  ];

  return (
    <div>
      <Divider />

      <Row gutter={16}>
        <Col className="gutter-row" span={6}>
          <div
            style={{
              display: "flex",
              justifyContent: "left",
              alignItems: "left",
            }}
          >
            <Button onClick={() => setIsModalVisible(true)}>
              Add Quota Entry
            </Button>
          </div>
        </Col>
        <Col className="gutter-row" span={6}></Col>
        <Col className="gutter-row" span={6}></Col>
        <Col className="gutter-row" span={6}>
          <Statistic
            title="Saldo Atual"
            value={total}
            decimalSeparator=","
            groupSeparator=""
            suffix={`/ ${quota}`}
          />
          <Progress
            style={{ width: "90%" }}
            percent={'0.000'}
            size="small"
          />
        </Col>
      </Row>
      <Divider />
      <Table
        dataSource={imageEntries}
        bordered={true}
        columns={columns}
        pagination={false}
        size="small"
        rowKey="id"
        className="components-table-demo-nested"
      />
      <Divider />
      <AddCreditModal isOpen={isModalVisible} setIsOpen={setIsModalVisible} contractId={contractId} />
    </div>
  );
};

export default ContractImageUsageEntries;
