import { CheckCircleTwoTone, CloseCircleOutlined } from "@ant-design/icons";
import { Table } from "ant-table-extensions";
import _ from "lodash";
import moment from "moment";
import React from 'react';

const AccountUsers = ({ sourceData }) => {
  const columns = [
    {
      title: "Name",
      render: (data) => <div>{data.user.name}</div>,
    },
    {
      title: "Email",
      render: (data) => <div>{data.user.email}</div>,
    },
    {
      title: "invitation_accepted_at",
      align: "center",
      width: 180,
      render: (data) => (
        <div>
          {data.user.invitation_accepted_at === null
            ? "-"
            : moment(data.user.invitation_accepted_at)
                .add(-3, "hour")
                .format("DD-MM-YYYY HH:mm:ss")}
        </div>
      ),
    },
    {
      title: "Role",
      align: "center",
      width: 180,
      render: (data) => <div>{data.role}</div>,
    },
    {
      title: "Added At",
      align: "center",
      width: 180,
      render: (data) => (
        <div>
          {data.createdAt === null
            ? "-"
            : moment(data.createdAt)
                .add(-3, "hour")
                .format("DD-MM-YYYY HH:mm:ss")}
        </div>
      ),
    },
    {
      title: "Updated At",
      align: "center",
      width: 180,
      render: (data) => (
        <div>
          {data.updatedAt === null
            ? "-"
            : moment(data.updatedAt)
                .add(-3, "hour")
                .format("DD-MM-YYYY HH:mm:ss")}
        </div>
      ),
    },
    {
      title: "Removed At",
      align: "center",
      width: 180,
      render: (data) => (
        <div>
          {data.deletedAt === null
            ? "-"
            : moment(data.deletedAt)
                .add(-3, "hour")
                .format("DD-MM-YYYY HH:mm:ss")}
        </div>
      ),
    },
    {
      title: "Can Export",
      align: "center",
      width: 180,
      render: (data) => (
        <div>
          {!data.canExport && <CloseCircleOutlined />}
          {data.canExport && (
            <div>
              <CheckCircleTwoTone twoToneColor="#52c41a" />
            </div>
          )}
        </div>
      ),
    },
  ];

  const setOrder = (paramsArr) => {
    const ordered = _.orderBy(paramsArr, ["updatedAt"], ["desc"]);
    return ordered;
  };

  return (
    <div>
      <div>
        <Table
          dataSource={setOrder(sourceData)}
          columns={columns}
          size="small"
          bordered={true}
          pagination={{ pageSize: "50" }}
          rowKey={(data) => data?.user?.email}
        />
      </div>
    </div>
  );
};

export default AccountUsers;
