import { gql } from "@apollo/client";

export const PROCESSING_MUTATION = gql`
  subscription MyQuery($id: uuid) {
    processings(where: { id: { _eq: $id } }) {
      cameraModel
      contract_id
      createdAt
      created_by_id
      deletedAt
      deleted_by_id
      flownAt
      gcpCustomHeight
      gcpHorizontalCrs
      hasGcp
      gcpVerticalCrs
      id
      lastReason
      location
      name
      processAfterUpload
      status
      submittedAt
      submitted_by_id
      supportNotes
      totalBytes
      totalImages
      totalPixels
      updatedAt
      updated_by_id
      userLocation
      imageFiles_aggregate(where: { uploaded: { _eq: true } }) {
        aggregate {
          count
        }
      }
      layers {
        createdAt
        deletedAt
        dimensionType
        formatType
        id
        layerType
        layerableId
        layerableType
        data
      }
      submittedBy {
        name
        role
        email
      }
      processingGcps {
        createdAt
        deletedAt
        gcp
        id
        label
        processing_id
        updatedAt
        x
        y
        z
      }
      processingActivities {
        processingId
        processingTaskId
        sendToClient
        typeOfActivity
      }
      processingStatus {
        description
        value
      }
      spatial_ref_sys {
        auth_name
        auth_srid
        proj4text
        srid
        srtext
      }
      processingTasks {
        area
        createdAt
        crsGcp
        crsImages
        crsOutput
        deletedAt
        endedAt
        gsd
        hasGcp
        id
        image_processing_output
        image_processing_slack_thread
        preProcess
        processingArea
        processingCost
        processingEndedAt
        processingId
        qaStatus
        rmsMean
        rmsX
        rmsY
        rmsZ
        seqId
        startedAt
        storageCost
        submittedById
        totalStorage
        taskSteps {
          deletedAt
          endedAt
          id
          processingTaskId
          seq
          startedAt
          status
          taskGroup
          taskName
        }
      }
    }
  }
`;
