import { gql } from "@apollo/client";

export const ADD_CREDIT_CONTRACT = gql`
  mutation AddCreditToContract($entryDate: date!, $credit: Int!, $contractId: uuid!) {
    insertOneImageUsageEntries(object: {entryDate: $entryDate, debit: 0, credit: $credit, contractId: $contractId, description: "creditDescription"}) {
      __typename
      id
    }
  }
`;

export const CREATE_CONTRACT = gql`
  mutation CreateNewContract($object: contracts_insert_input!, $statusId: uuid!, $accountId: uuid!) {
    insertContractsOne(object: $object) {
      __typename
      id
    }
    updateByPkAccountStatuses(pk_columns: {id: $statusId}, _set: {deleted_at: "now()"}) {
      deleted_at
      deleted_by_id
    }
    insertAccountStatusesOne(object: {is_active: true, reason: "Contract signed", account_id: $accountId}) {
      __typename
      id
    }
  }
`;

export const DESTROY_CONTRACT = gql`
  mutation DestroyContract($id: uuid!, $statusId: uuid!, $accountId: uuid!) {
    updateByPkContracts(pk_columns: {id: $id}, _set: {ended_at: "now()"}) {
      id
    }
    updateByPkAccountStatuses(pk_columns: {id: $statusId}, _set: {deleted_at: "now()"}) {
      deleted_at
      deleted_by_id
    }
    insertAccountStatusesOne(object: {is_active: false, reason: "Contract finished", account_id: $accountId}) {
      __typename
      id
    }
  }
`;

export const UPDATE_STATUS = gql`
  mutation UpdateAccountStatus($id: uuid! $accountId: uuid!, $isActive: Boolean!, $reason: String!, $userId: uuid!) {
    updateByPkAccountStatuses(pk_columns: {id: $id}, _set: {deleted_at: "now()", deleted_by_id: $userId}) {
      deleted_at
      deleted_by_id
    }
    insertAccountStatusesOne(object: {is_active: $isActive, reason: $reason, account_id: $accountId}) {
      __typename
      id
    }
  }
`;
