import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Col, Descriptions, Divider, Dropdown, Menu, Modal, PageHeader, Row, Skeleton, Tag } from "antd";
import React, { useCallback, useMemo, useState } from "react";

import { CopyOutlined, DownOutlined } from "@ant-design/icons";
import { Card, Collapse } from "antd";
import { Content } from "antd/lib/layout/layout";
import CopyToClipboard from "react-copy-to-clipboard";
import { Link } from "react-router-dom";
import { ACCOUNT_DETAIL_SUBSCRIPTION, LOGOUT_ALL_OF_ACCOUNT } from "../AccountsQueries";
import AccountProcessings from "./AccountProcessings";
import AccountSalesTeam from "./AccountSalesTeam/AccountSalesTeam";
import AccountUsers from "./AccountUsers";

import { Tabs } from "antd";

import { message } from "antd";

import { useMutation, useSubscription } from "@apollo/client";
import { useLocation } from "react-router";
import AddCreditModal from "./AddCreditModal";

import moment from 'moment';
import NewContract from "../../Contracts/NewContract/NewContract";
import AccountStatuses from "./AccountStatuses";
import ChangeAccountStatusModal from "./ChangeAccountStatusModal";

const { TabPane } = Tabs;
const { Panel } = Collapse;

const AccountContracts = ({ contractsData, account }) => {
  function callback(key) {
    console.log(key);
  }

  const [newContractModalIsOpen, setNewContractModalIsOpen] = useState(false);

  const menuCardContracts = (
    <Menu>
      <Menu.Item key="rule_assingment">
        <div onClick={() => setNewContractModalIsOpen(true)}>
          Adicionar Novo Contrato
        </div>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="other_rule" disabled>
        Example（disabled）
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      <Card
        title={"Contracts"}
        extra={[
          <Dropdown key="action2" overlay={menuCardContracts}>
            <Button
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
              Ações <DownOutlined />
            </Button>
          </Dropdown>,
        ]}
      >
        <Collapse defaultActiveKey={["1"]} onChange={callback}>
          {(contractsData ?? []).map((item) => (
            <React.Fragment key={item.id}>
              <Panel header={item.identifier} key={item.id}>
                <Button>
                  <Link
                    to={{
                      pathname: `/view-contract-details/${item.id}`,
                      state: { contract: item.id },
                    }}
                  >
                    More Info
                  </Link>
                </Button>
              </Panel>
            </React.Fragment>
          ))}
        </Collapse>
      </Card>
      <NewContract newContractModalVisible={newContractModalIsOpen} setNewContractModalVisible={setNewContractModalIsOpen} account={account} />
    </div>
  );
};

const PageContent = ({ sourceData }) => {
  const initialStyle = { padding: "24px" };
  function callback(key) {
    console.log(key);
  }
  return (
    <div style={initialStyle}>
      <Tabs defaultActiveKey="1" onChange={callback}>
        <TabPane tab="Contracts" key="account_contracts">
          <AccountContracts contractsData={sourceData?.contracts} account={sourceData} />
        </TabPane>
        <TabPane tab="Processings" key="account_processings">
          <AccountProcessings sourceData={sourceData} />
        </TabPane>
        <TabPane tab="Users" key="account_users">
          <AccountUsers sourceData={sourceData?.members} />
        </TabPane>
        <TabPane tab="Statuses" key="account_statuses">
          <AccountStatuses sourceData={sourceData?.accountStatuses} />
        </TabPane>
        <TabPane tab="Sales Team" key="account_sales_team">
          <AccountSalesTeam accountId={sourceData?.id} />
        </TabPane>
      </Tabs>
    </div>
  );
};

const AccountDetailHeader = ({ sourceData }) => {
  const info = (data) => {
    message.info(data);
  };

  const activeContract = useMemo(() => sourceData?.active_contract.length > 0 ? sourceData?.active_contract[0] : null, [sourceData]);
  const imagesUsageSum = useMemo(() => activeContract?.imageUsageEntries_aggregate?.aggregate?.sum, [activeContract]);

  return <div>
    <Row>
      <Descriptions size="small" column={3} colon={false}>
        <Descriptions.Item label={sourceData?.id}>
          <CopyToClipboard text={sourceData?.id}>
            <Button
              icon={<CopyOutlined />}
              size="small"
              onClick={() =>
                info(`O ID do contrato foi copiado com sucesso!`)
              }
            />
          </CopyToClipboard>
        </Descriptions.Item>
      </Descriptions>
    </Row>
    <Divider />
    <Row style={{ marginBottom: "10px" }}>
      <Col span={6}>
        <dl>
          <dt>Account Name</dt>
          {sourceData?.name}
        </dl>
      </Col>
      <Col span={6}>
        <dl>
          <dt>Organization</dt>
          {sourceData?.organization?.name}
        </dl>
      </Col>
      <Col span={6}>
        <dl>
          <dt>Period</dt>
          {activeContract ?
            <>
              {moment(activeContract.started_at).format('DD/MM/YYYY')}&nbsp;-&nbsp;
              {moment(activeContract.started_at).add(activeContract.period_in_months, 'months').format("DD/MM/YYYY")}
              <br />
              {moment.duration(activeContract.period_in_months, 'months').asMonths()} months
            </>
          :
            "-"
          }

        </dl>
      </Col>
      <Col span={6}>
        <dl>
          <dt>Balance</dt>
          {activeContract ?
            <>
              {(imagesUsageSum.credit ?? 0) - (imagesUsageSum.debit ?? 0)}&nbsp;/&nbsp;
              {activeContract.processing_quota ?? 0}
            </>
          :
            "-"
          }
        </dl>
      </Col>
    </Row>
  </div>;
};

const AccountDetail = () => {
  const sectionTitle = "Account Detail";
  const [showAddCredit, setShowAddCredit] = useState(false);
  const accountId = useLocation().pathname.replace(
    "/view-account-details/",
    ""
  );
  const [showReasonPrompt, setShowReasonPrompt] = useState(null);
  const {data, loading} = useSubscription(ACCOUNT_DETAIL_SUBSCRIPTION, {variables: { id: accountId }});
  const [logoutAccount, logoutResult] = useMutation(LOGOUT_ALL_OF_ACCOUNT);

  const account = useMemo(() => data?.accountsByPk, [data]);

  const currentStatus = useMemo(() => account?.current_status.length > 0 && account?.current_status[0], [account]);

  // console.log(account);

  // const toggleAccount = (active, reason) => {

  // }

  const logoutFn = useCallback(() => {
    logoutAccount({
      variables: {
        accountId: accountId
      }
    })
  }, [accountId]);

  const modalConfirm = () => {
    Modal.confirm({
      title: 'Atenção',
      icon: <ExclamationCircleOutlined />,
      content: 'Você quer mesmo fazer o logout dos usuários desta conta?',
      okText: 'Sim',
      cancelText: 'Não',
      onOk: () => {
        return logoutFn();
      }
    });
  }

  const menu = (
    <Menu>
      <Menu.Item key="add_credit" disabled={account?.active_contract.length <= 0}>
        <div onClick={() => setShowAddCredit(true)}>Adicionar Crédito</div>
      </Menu.Item>
      <Menu.Item key="toggle_status">
        <div onClick={() => setShowReasonPrompt(!currentStatus.is_active)}>{currentStatus.is_active ? 'Desativar' : 'Reativar'} Conta</div>
      </Menu.Item>
      <Menu.Divider key="divider"/>
        <Menu.Item key="logout" danger>
        <div onClick={() => modalConfirm()}>Logout dos usuários da conta</div>
        </Menu.Item>
    </Menu>
  );
  return (
    <div>
      <Content style={{ margin: "0 16px", backgroundColor: "white" }}>
        <div
          className="site-page-header-ghost-wrapper"
          style={{ marginTop: "10px" }}
        >
          <PageHeader
            ghost={false}
            onBack={() => window.history.back()}
            title={sectionTitle}
            tags={<Tag color={currentStatus.is_active ? "success" : "error"}>{currentStatus.is_active ? 'Ativa' : 'Inativa'}</Tag>}
            extra={[
              <Dropdown key="action1" overlay={menu}>
                <Button
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}
                >
                  Ações <DownOutlined />
                </Button>
              </Dropdown>,
            ]}
          >
            {loading ? <Skeleton /> : <AccountDetailHeader sourceData={account} />}
          </PageHeader>
        </div>
        {loading ? <Skeleton /> : <PageContent sourceData={account}></PageContent>}
      </Content>
      {account?.active_contract.length > 0 && <AddCreditModal isOpen={showAddCredit} setIsOpen={setShowAddCredit} contractId={account?.active_contract.length > 0 && account?.active_contract[0].id} />}
      <ChangeAccountStatusModal isOpen={showReasonPrompt !== null} setIsOpen={setShowReasonPrompt} newStatus={showReasonPrompt} account={account} />
    </div>
  );
};

export default AccountDetail;
