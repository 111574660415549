import { CopyOutlined, DownOutlined } from "@ant-design/icons";
import { useSubscription } from "@apollo/client";
import {
  Button,
  Col,
  Descriptions, Dropdown, Menu,
  PageHeader,
  Row,
  Skeleton,
  Statistic,
  Tabs,
  Tag,
  message
} from "antd";
import { Content } from "antd/lib/layout/layout";
import moment from 'moment';
import Rainbow from "rainbowvis.js";
import React, { useMemo, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useLocation } from "react-router-dom";
import ContractConsumption from "./ContractConsumption/ContractConsumption";
import { CONTRACT_DETAIL_SUBSCRIPTION } from "./ContractDetailsQueries";
import ContractImageUsageEntries from "./ContractImageEntries/ContractImageEntries";
import ContractInstallments from "./ContractInstallments/ContractInstallments";
import ContractItems from "./ContractItems/ContractItems";

const { TabPane } = Tabs;

const colormap = new Rainbow();
colormap.setSpectrum("#52c41a", "#ffec3d", "#ff4d4f");

const PageContent = ({ state }) => {
  const [, setTabState] = useState();
  const initialStyle = { padding: "24px" };
  function callbackTabsChange(key) {
    setTabState(key);
  }
  return (
    <div style={initialStyle}>
      <div>
        <Tabs
          defaultActiveKey="image_usage"
          onChange={callbackTabsChange}
          style={{ borderTop: "1px solid #d9d9d9" }}
        >
          <TabPane tab="Image Usage" key="image_usage">
            <ContractImageUsageEntries
              contractId={state.id}
              imageEntries={state?.imageUsageEntries}
              aggregate={state?.imageUsageEntries_aggregate.aggregate}
              quota={state?.processing_quota}
            />
          </TabPane>
          <TabPane tab="Contract Items" key="ctr_items">
            <ContractItems
              initialState={state.contractItems}
              contractId={state.id}
            />
          </TabPane>
          <TabPane tab="Contract Installments" key="ctr_installments">
            <ContractInstallments
              initialInstallments={state.contractInstallments}
              initialContractItems={state.contractItems}
              contractId={state.id}
            />
          </TabPane>
          <TabPane tab="Consumption Table" key="ctr_consumption">
            <ContractConsumption contractRawData={state} />
          </TabPane>
          <TabPane tab="General Contacts" key="general_contacts"></TabPane>
        </Tabs>
      </div>
    </div>
  );
};

const TotalContractValueStats = ({ aggregate }) => {

  return (
    <div>
      <Statistic
        title={"Total Contract Value"}
        value={0}
        prefix={"R$"}
        precision={2}
      />
    </div>
  );
};

const PageHeaderComponent = ({ state }) => {
  const info = (data) => {
    message.info(data);
  };

  return (
    <div>
      <Row style={{ marginBottom: "10px" }}>
        <Descriptions size="small" column={3} colon={false}>
          <Descriptions.Item label={state.id}>
            <CopyToClipboard text={state.id}>
              <Button
                icon={<CopyOutlined />}
                size="small"
                onClick={() =>
                  info(`O ID do contrato foi copiado com sucesso!`)
                }
              />
            </CopyToClipboard>
          </Descriptions.Item>
        </Descriptions>
      </Row>
      <Row style={{ marginBottom: "10px" }}>
        <Col span={8}>
          <dl>
            <dt>Account Name</dt>
            {state?.account?.name}
          </dl>
        </Col>
        <Col span={8}>
          <dl>
            <dt>Organization</dt>
            {state?.account?.organization?.name}
          </dl>
        </Col>
        <Col span={8}>
          <dl>
            <dt>Period</dt>
            {moment(state?.started_at).format('DD/MM/YYYY')}&nbsp;-&nbsp;
            {moment(state?.started_at).add(state?.period_in_months, 'months').format("DD/MM/YYYY")}
            <br/>
            {moment.duration(state?.period_in_months, 'months').asMonths()} months
          </dl>
        </Col>
      </Row>
      <Row>
        <TotalContractValueStats contractItems={state?.contractItems} />
      </Row>
    </div>
  );
};

const ContractDetail = () => {
  const contractId = useLocation().pathname.replace(
    "/view-contract-details/",
    ""
  );

  // const [elapseTime, setElapseTime] = useState("");
  const [, setIsVisible] = useState(false);
  const { loading, data } = useSubscription(CONTRACT_DETAIL_SUBSCRIPTION, {variables: { id: contractId }});

  const contract = useMemo(() => data?.contractsByPk, [data]);

  const menu = (
    <Menu>
      <Menu.Item key="rule_assingment">
        <div onClick={() => setIsVisible(true)}>Open GCS Folder</div>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="other_rule" disabled>
        3rd menu item（disabled）
      </Menu.Item>
    </Menu>
  );

  const HandleChangeStatus = () => {};
  return (
    <div>
      {loading ? (
        <Content style={{ margin: "0 16px" }}>
          <div
            className="site-page-header-ghost-wrapper"
            style={{ marginTop: "10px" }}
          ></div>
        </Content>
      ) : (
        <div>
          <Content style={{ margin: "0 16px", backgroundColor: "white" }}>
            <div
              className="site-page-header-ghost-wrapper"
              style={{ marginTop: "10px" }}
            >
              <PageHeader
                ghost={false}
                onBack={() => window.history.back()}
                title={
                  loading ? (
                    <Skeleton.Input
                      style={{ width: 100 }}
                      active={true}
                      size={"small"}
                    />
                  ) : (
                    contract?.identifier
                  )
                }
                tags={
                  loading ? (
                    <Skeleton.Input
                      style={{ width: 100 }}
                      active={true}
                      size={"small"}
                    />
                  ) : (
                    <Tag color="blue" onClick={() => HandleChangeStatus()}>
                      {contract?.contractStatus?.description}
                    </Tag>
                  )
                }
                extra={[
                  <Dropdown overlay={menu} key="menu">
                    <Button
                      className="ant-dropdown-link"
                      onClick={(e) => e.preventDefault()}
                    >
                      Actions <DownOutlined />
                    </Button>
                  </Dropdown>,
                ]}
              >
                  <PageHeaderComponent state={contract} />
              </PageHeader>
            </div>
              {!loading && <PageContent state={contract}></PageContent>}
          </Content>
        </div>
      )}
    </div>
  );
};

export default ContractDetail;
