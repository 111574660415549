import { gql } from "@apollo/client";

export const PROCESSING_LOGS_COUNT = gql`
  subscription ProcessingLogsCount {
    processingsAggregate(where: {deletedAt: {_is_null: true}}) {
      aggregate {
        count
      }
    }
  }
`;

export const PROCESSINGS_MUTATION_LOGS = gql`
  subscription ProcessingLogs($limit: Int!, $offset: Int!) {
    processings(order_by: {updatedAt: desc}, limit: $limit, offset: $offset) {
      submittedBy {
        name
        email
      }
      cameraModel
      contract_id
      createdAt
      created_by_id
      deletedAt
      deleted_by_id
      flownAt
      gcpCustomHeight
      gcpHorizontalCrs
      hasGcp
      gcpVerticalCrs
      id
      lastReason
      location
      name
      processAfterUpload
      status
      submittedAt
      submitted_by_id
      supportNotes
      totalBytes
      totalImages
      totalPixels
      updatedAt
      updated_by_id
      userLocation
      # imageFiles_aggregate(where: { uploaded: { _eq: true } }) {
      #   aggregate {
      #     count
      #   }
      # }
      contract {
        account_id
        account {
          legal_name
          document_number
          name
          organization_id
          id
        }
      }
      processingTasks {
        seqId
      }
    }
  }
`;
