const APPVERSION = require('../package.json').version;

const Config = {
  APPVERSION,
  ENV: process.env.REACT_APP_ENV,
  BACKEND_ENDPOINT: process.env.REACT_APP_BACKEND_ENDPOINT,
  GRAPHQL_HASURA_ENDPOINT: process.env.REACT_APP_GRAPHQL_HASURA_ENDPOINT,
  GRAPHQL_ENDPOINT: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  WEBSOCKET_HASURA_ENDPOINT: process.env.REACT_APP_WEBSOCKET_HASURA_ENDPOINT,
  STORAGE_BUCKET: process.env.REACT_APP_STORAGE_BUCKET,
  MISSIONS_COLLECTION: process.env.REACT_APP_MISSIONS_COLLECTION,
  DRONES_COLLECTION: process.env.REACT_APP_DRONES_COLLECTION,
  MAPBOX_ACCESS_TOKEN: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
  IPLOCATION_KEY: process.env.REACT_APP_IPLOCATION_KEY,
  FIREBASE_CONFIG: {
    apiKey: process.env.REACT_APP_FIREBASE_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
  },
  debug: process.env.REACT_APP_DEBUG,
  debugLevel: process.env.REACT_APP_DEBUG_LEVEL,
  // ...process.env
}

export default Config;
