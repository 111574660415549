import { CheckCircleTwoTone, CloseCircleOutlined } from "@ant-design/icons";
import { Button, Card, Col, Divider, Row, Statistic, Tag } from "antd";
import React from "react";

import { Table } from "ant-table-extensions";
import _ from "lodash";
import moment from "moment";
import { Link } from "react-router-dom";

const GCPCell = ({ data }) => {
  return (
    <div>
      {!data.hasGcp && <CloseCircleOutlined />}
      {data.hasGcp && <CheckCircleTwoTone twoToneColor="#52c41a" />}
    </div>
  );
};

const ProcessingStatusCell = ({ status }) => {
  const getProcessingTagProperties = (statusString) => {
    const statusDict = [
      {
        statusString: "UPLOAD_PAUSED",
        tagColor: "#5cdbd3",
        fontColor: "#FFFFFF",
      },
      { statusString: "UPLOADING", tagColor: "#5cdbd3", fontColor: "#FFFFFF" },
      {
        statusString: "UPLOAD_CANCELED",
        tagColor: "#f5f5f5",
        fontColor: "#FFFFFF",
      },
      {
        statusString: "UPLOAD_FINISHED",
        tagColor: "#f5f5f5",
        fontColor: "#333333",
      },
      {
        statusString: "PROCESSING_QUEUE",
        tagColor: "#91d5ff",
        fontColor: "#FFFFFF",
      },
      {
        statusString: "PRE_PROCESSING",
        tagColor: "#91d5ff",
        fontColor: "#FFFFFF",
      },
      {
        statusString: "INTERVENTION",
        tagColor: "#ffec3d",
        fontColor: "#333333",
      },
      { statusString: "PROCESSING", tagColor: "#40a9ff", fontColor: "#FFFFFF" },
      {
        statusString: "PROCESSING_CANCELED",
        tagColor: "#ffbb96",
        fontColor: "#FFFFFF",
      },
      {
        statusString: "PROCESSING_FAILED",
        tagColor: "#ff7a45",
        fontColor: "#FFFFFF",
      },
      {
        statusString: "DECLARE_FAILURE",
        tagColor: "#ff4d4f",
        fontColor: "#FFFFFF",
      },
      {
        statusString: "PROCESSING_DONE",
        tagColor: "#73d13d",
        fontColor: "#FFFFFF",
      },
    ];
    var tagProperties = statusDict
      .filter((item) => item.statusString === statusString)
      .map((item) => item)[0];
    return tagProperties;
  };

  return (
    <div>
      <Tag color={getProcessingTagProperties(status).tagColor}>
        <div style={{ color: getProcessingTagProperties(status).fontColor }}>
          {status.replace("_", " ")}
        </div>
      </Tag>
    </div>
  );
};

const AccountProcessings = ({ sourceData }) => {
  // const [grouped, setGrouped] = useState([]);
  const columns = [
    {
      title: "Processing Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Total Images",
      align: "center",
      width: 120,
      render: (data) => <div>{data.totalImages}</div>,
    },
    {
      title: "GCP",
      align: "center",
      render: (data) => <GCPCell data={data} />,
    },
    {
      title: "Flown At",
      dataIndex: "flownAt",
      align: "center",
      width: 120,
      key: "flownAt",
      render: (data) => moment(data).format("DD/MM/YYYY"),
    },
    {
      title: "Submitted At",
      dataIndex: "submittedAt",
      key: "submittedAt",
      render: (data) => moment(data).format("DD/MM/YYYY"),
    },
    {
      title: "Status",
      align: "center",
      render: (data) => <ProcessingStatusCell status={data.status} />,
    },
    {
      title: "Deleted At",
      align: "center",
      width: 180,
      render: (data) => (
        <div>
          {data.deletedAt === null
            ? "-"
            : moment(data.deletedAt)
                .add(-3, "hour")
                .format("DD-MM-YYYY HH:mm:ss")}
        </div>
      ),
    },
    {
      title: "Action",
      align: "center",
      width: 140,
      render: (data) => (
        <Button size="small" style={{ fontSize: "12px" }}>
          <Link
            to={{
              pathname: `/view-processing-details/${data.id}`,
              state: { contract: data.id },
            }}
          >
            More Info
          </Link>
        </Button>
      ),
    },
  ];

  const processStatusGroups = (dataArr) => {
    const finalArr = [];
    var groupedByStatus = _.groupBy(dataArr, "status");
    console.log(groupedByStatus);
    for (const key in groupedByStatus) {
      const processingGroupedObj = {
        status: key,
        sumImages: _.sumBy(
          groupedByStatus[key],
          "totalImages"
        ),
        data: groupedByStatus[key],
        count: groupedByStatus[key].length,
      };
      finalArr.push(processingGroupedObj);
    }
    return finalArr;
  };

  const initialProcessing = (paramsArr) => {
    const finalRecords = [];
    (paramsArr?.contracts ?? []).forEach((contract) => {
      var identifier = contract.identifier;
      contract.processings.forEach((processing) => {
        processing.key = processing.id;
        processing.contractIdentifier = identifier;
        finalRecords.push(processing);
      });
    });
    const groupedProcessings = processStatusGroups(finalRecords);
    const ordered = _.orderBy(finalRecords, ["createdAt"], ["desc"]);
    console.log(ordered);
    const sumImages = _.sumBy(
      finalRecords,
      "totalImages"
    );
    return [ordered, groupedProcessings, sumImages];
  };

  return (
    <div>
      <div>
        <div>
          <Row gutter={24}>
            {initialProcessing(sourceData)[1].map((item) => (
              <React.Fragment key={item.id}>
                <Col span={4}>
                  <Card size="small" title={item.status}>
                    <dd>
                      <b>Flights Count: </b>
                      {item.count}
                    </dd>
                    <dd>
                      <b>Total Images: </b>
                      {item.sumImages}
                    </dd>
                  </Card>
                </Col>
              </React.Fragment>
            ))}
            <Col span={2}>
              <Statistic
                title={"TOTAL IMAGES"}
                value={initialProcessing(sourceData)[2]}
              />
            </Col>
          </Row>
        </div>
        <Divider />
        <Table
          dataSource={initialProcessing(sourceData)[0]}
          columns={columns}
          size="small"
          bordered={true}
          pagination={{ pageSize: "50" }}
        />
      </div>
    </div>
  );
};

export default AccountProcessings;
