import { gql, useMutation } from "@apollo/client";
import { Button, Card, Divider, Form, Input, notification } from "antd";
import React, { useCallback, useState } from "react";

import MaplyLogo from "./assets/LogoMaply_BB.svg";
import { useAuth } from "./Auth";

const LOGIN_MUTATION = gql`
  mutation BackofficeLogin($email: String!, $password: String!, $captchaToken: String = "") {
    backofficeLogin(email: $email, password: $password, captchaToken: $captchaToken) {
      # jwt
      user {
        # __typename
        id
        name
        email
        # token
        # avatarId

        # timeZone
        # language
        # coordinateSystem
        # systemUnit
        # inclination_type
        # industry
        # tags
      }

      successful
      errors {
        message
        path
      }
    }
  }
`;

const Login = () => {
  const [api, contextHolder] = notification.useNotification();
  const {login} = useAuth();
  const [isLoading, setLoading] = useState(false);
  const [, setShowCaptcha] = useState(false);
  const [loginMutation] = useMutation(LOGIN_MUTATION, {});
  // const { data, error } = loginResult;

  const onFinish = useCallback((data) => {
    const {email, password, captchaToken, rememberMe} = data;
    setLoading(true);
    loginMutation({ variables: { email, password, captchaToken } }).then(({ data: { backofficeLogin: { user, errors, successful }, token } }) => {
      if (successful) {
        // console.log('Login with', { user });
        return login({ token, rememberMe });
      } else {
        // console.error('Login mutation Errors', errors);
        setShowCaptcha(true);
        let msg;
        if (errors[0].message === "user_locked") {
          msg = "Numero de tentativas excedido e usuário bloqueado. Enviamos um email com instruções para o desbloqueio";
        } else if (errors[0].message === "invalid_user_or_password") {
          msg = "Ocorreu um problema com as suas credenciais, por favor verifique seu usuário/senha";
        } else {
          msg = "Erro Interno";
        }
        api.error({
          message: 'Houve um Problema',
          description: msg,
          placement: 'topLeft',
        });
        setLoading(false);
      }
    }).catch(error => {
      console.log(error);
      setLoading(false);
      api.error({
        message: 'Houve um Problema',
        description: 'Houve um problema no acesso aos servidores. Por favor tente novamente.',
        placement: 'topLeft',
      });
    });

    // try {
    //   await app.auth().signInWithEmailAndPassword(data.email, data.password);
    //   history.push("/dashboard");
    //   setLoading(false);
    // } catch (error) {
    //   alert(error);
    // }
  }, [api, login, loginMutation]);

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div style={{ width: '100%', justifyContent: 'center', height: '100%', display: 'flex', alignItems: 'center', background: '#001529' }}>
      {contextHolder}
      <Card
        title={
          <img
            src={MaplyLogo}
            alt="React Logo"
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              display: "block",
              width: "150px",
              padding: "10px",
            }}
          />
        }
        style={{width: '350px'}}
      >
        <Form
          layout="vertical"
          name="login"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your E-mail!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Divider />
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading}
              style={{ width: "100%" }}
            >
              Login
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default Login;
