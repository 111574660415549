export const getStatusString = (statusCode) => {
  const DictStatuses = [
    {
      statusCode: 0,
      statusString: "requested",
      statusStringDisplay: "Requested",
      tagColor: "orange",
    },
    {
      statusCode: 1,
      statusString: "requested",
      statusStringDisplay: "Processing",
      tagColor: "blue",
    },
    {
      statusCode: 2,
      statusString: "success",
      statusStringDisplay: "Success",
      tagColor: "green",
    },
    {
      statusCode: 3,
      statusString: "failed",
      statusStringDisplay: "Failed",
      tagColor: "red",
    },
  ];
  var statusObj = DictStatuses.filter(
    (item) => item.statusCode === statusCode
  ).map((item) => item)[0];
  return statusObj;
};
