import { gql } from "@apollo/client";

export const CURRENT_USER = gql`
  query CurrentUser($id: uuid!) {
    usersByPk(id: $id) {
      __typename
      id
      name
      email
      appearance
      coordinateSystem
      dateFormat
      timeFormat
      language
      systemUnit
      inclination_type
      timezone
      industry
      avatar
    }
  }
`;
