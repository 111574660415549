import { Alert, Button, Dropdown, Menu, Modal, notification } from "antd";
import React, { useEffect, useState } from "react";

import { DownOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import { MUTATION_REMOVE_INSTALLMENT_AND_INSTALLMENT_ITEMS } from "./InstallmentsUtils";

const ModalConfirmDelete = ({
  isModalVisible,
  setIsModalVisible,
  clearStates,
  installmentToDelete,
}) => {
  const [removeInstallment] = useMutation(
    MUTATION_REMOVE_INSTALLMENT_AND_INSTALLMENT_ITEMS
  );
  const [isLoading, setLoading] = useState(false);
  const openNotification = () => {
    notification.open({
      message: "Sucesso!",
      type: "success",
      description: "A parcela foi removida com sucesso!",
      onClick: () => {
        console.log("Notification Clicked!");
      },
    });
  };
  const handleSubmitRemoval = (installmentId) => {
    if (
      installmentId === "" ||
      installmentId === null ||
      installmentId === undefined
    ) {
      console.log("ocorreu um erro");
    } else {
      setLoading(true);
      var deleteObj = { id: installmentId };
      removeInstallment({ variables: deleteObj })
        .then(({ data }) => {
          console.log(data);
          clearStates();
          setIsModalVisible(false);
          openNotification(data);
          setLoading(false);
        })
        .catch((error) => {
          console.warn("there was an error sending the query", error);
          clearStates();
          setLoading(false);
        });
    }
  };

  const handleOk = () => {
    var installmentId = installmentToDelete.id;
    handleSubmitRemoval(installmentId);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        title="Confirma remoção da parcela"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Retornar
          </Button>,

          <Button
            type="primary"
            loading={isLoading}
            onClick={() => handleOk()}
            danger
          >
            Confirmar
          </Button>,
        ]}
      >
        <Alert
          message="Atenção"
          description="Confirma a remoção da parcela? Todos os items agendados serão
          desvinculados tambem"
          type="error"
        />
      </Modal>
    </>
  );
};

const InstallmentOptions = ({ dataSource, contractData }) => {
  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);
  const [generationDisabled, setGenerationDisabled] = useState(false);
  const [installmentToDelete, setInstallmentToDelete] = useState("");
  const [, setInstallmentToInvoice] = useState([]);
  const [, seIsModalInvoiceVisible] = useState(false);
  const clearStates = () => {
    setIsModalDeleteVisible(false);
    setInstallmentToDelete([]);
  };

  const handleDeleteInstallment = (data) => {
    setInstallmentToDelete(data);
    setIsModalDeleteVisible(true);
  };

  const handleInvoiceGeneration = (data) => {
    setInstallmentToInvoice(data);
    seIsModalInvoiceVisible(true);
  };

  useEffect(() => {
    var statusCode = dataSource.statusCode;
    if (statusCode >= 3) {
      setGenerationDisabled(true);
    } else {
      setGenerationDisabled(false);
    }
    console.log(statusCode);
  }, [dataSource]);

  const menu = (
    <Menu>
      <Menu.Item
        key="issue_invoice"
        disabled={generationDisabled}
        onClick={() => handleInvoiceGeneration(dataSource)}
      >
        Emitir Fatura
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key="delete_installment"
        danger
        disabled={generationDisabled} /// todo
        onClick={() => handleDeleteInstallment(dataSource)}
      >
        Remover Parcela
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      <Dropdown overlay={menu} trigger={["click"]}>
        <Button icon={<DownOutlined />} size="small">
          Ações
        </Button>
      </Dropdown>
      <ModalConfirmDelete
        isModalVisible={isModalDeleteVisible}
        setIsModalVisible={setIsModalDeleteVisible}
        clearStates={clearStates}
        installmentToDelete={installmentToDelete}
      />
    </div>
  );
};

export default InstallmentOptions;
