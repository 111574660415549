import React, { useEffect, useState } from "react";
import MapGL, { Popup } from "react-map-gl";
import {
    GCPInfo,
    ImageInfo,
    gcpsProcessing,
    mappingImagesProcessing
} from "./MapUtils";

import { useSubscription } from "@apollo/client";
import { PageHeader } from "antd";
import { Content } from "antd/lib/layout/layout";
import { useLocation } from "react-router";
import MarkersGcps from "./MarkersGcps";
import MarkersPins from "./MarkersPins";
import { PROCESSING_MAP_MUTATION } from "./ProcessingMapQueries";

const MAPBOX_ACCESS_TOKEN =
  "pk.eyJ1IjoiZ2Vvcmdpb3MtdWJlciIsImEiOiJjanZidTZzczAwajMxNGVwOGZrd2E5NG90In0.gdsRu_UeU_uPi9IulBruXA";

const PageContent = ({ dataSource, mappingImages, gcps }) => {
  const initialStyle = { padding: "24px" };
  const [viewport, setViewport] = useState({
    latitude: -9.04656825,
    longitude: -41.73461594444444,
    zoom: 16,
    bearing: 0,
    pitch: 0,
  });
  useEffect(() => {
    if (mappingImages.length > 0) {
      const newViewPort = {
        latitude: mappingImages[0].latitude,
        longitude: mappingImages[0].longitude,
        zoom: 16,
        bearing: 0,
        pitch: 0,
      };
      setViewport(newViewPort);
    }
  }, [mappingImages]);

  const [popupInfo, setPopupInfo] = useState(null);
  const [popupInfoGcps, setPopupInfoGcps] = useState(null);
  return (
    <div style={initialStyle}>
      <MapGL
        {...viewport}
        width="100%"
        height="800px"
        mapStyle="mapbox://styles/mapbox/satellite-v9"
        onViewportChange={setViewport}
        mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
      >
        <MarkersPins data={mappingImages} onClick={setPopupInfo} />{" "}
        <MarkersGcps data={gcps} onClick={setPopupInfoGcps} />{" "}
        {popupInfo && (
          <Popup
            tipSize={5}
            anchor="top"
            longitude={popupInfo.longitude}
            latitude={popupInfo.latitude}
            closeOnClick={false}
      onClose={setPopupInfo}
          >
            <ImageInfo info={popupInfo} />
          </Popup>
        )}
        {popupInfoGcps && (
          <Popup
            tipSize={5}
            anchor="top"
            longitude={popupInfoGcps.longitude}
            latitude={popupInfoGcps.latitude}
            closeOnClick={false}
      onClose={setPopupInfoGcps}
          >
            <GCPInfo info={popupInfoGcps} />
          </Popup>
        )}
      </MapGL>
    </div>
  );
};

const ProcessingMap = () => {
  const [state, setstate] = useState([]);
  const [pageTitle, setPageTitle] = useState("");
  const [, setProcessing] = useState(false);
  const [mappingImages, setMappingImages] = useState([]);
  const [gcps, setGcps] = useState([]);
  const processingId = useLocation().pathname.replace(
    "/view-processing-map/",
    ""
  );

  useSubscription(PROCESSING_MAP_MUTATION, {
    variables: { id: processingId },
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      console.log(data);
      const processing = data.processings[0];
      const pageName = data.processings[0].name;
      setPageTitle(pageName);
      const markersArr = mappingImagesProcessing(processing.layers);
      const gcpsArr = gcpsProcessing(processing.layers);
      setMappingImages(markersArr);
      setGcps(gcpsArr);
      setstate(processing);
      setProcessing(false);
    },
  });

  return (
    <div>
      <Content style={{ margin: "0 16px", backgroundColor: "white" }}>
        <div
          className="site-page-header-ghost-wrapper"
          style={{ marginTop: "10px" }}
        >
          <PageHeader
            ghost={false}
            onBack={() => window.history.back()}
            title={pageTitle}
          ></PageHeader>
          <PageContent
            dataSource={state}
            mappingImages={mappingImages}
            gcps={gcps}
          />
        </div>
      </Content>
    </div>
  );
};

export default ProcessingMap;
