import { gql } from "@apollo/client";

export const USERS_SUBSCRIPTION = gql`
  subscription users {
    users(order_by: {current_sign_in_at: desc_nulls_last}) {
      appearance
      avatar
      coordinateSystem
      createdAt
      created_by_id
      current_sign_in_at
      current_sign_in_ip
      dateFormat
      deletedAt
      deleted_by_id
      email
      inclination_type
      id
      industry
      invitation_accepted_at
      invitation_created_at
      invitation_limit
      invitation_sent_at
      invitations_count
      invited_by_id
      invited_by_type
      name
      remember_created_at
      reset_password_sent_at
      reset_password_token
      role
      sign_in_count
      systemUnit
      timeFormat
      timezone
      updatedAt
      updated_by_id
      userAccounts_aggregate {
        aggregate {
          count
        }
      }
      userAccounts {
        account {
          name
          id
        }
      }
    }
  }
`;

export const LOGOUT_USERS = gql`
  mutation LogoutUsers($userIds: [uuid!]!) {
    logoutUsers(user_ids: $userIds)
  }
`;

export const LOGOUT_ALL_USERS = gql`
  mutation LogoutUsers {
    logoutUsers
  }
`;
