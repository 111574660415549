import moment from "moment";

export const initialProcessing = (grouped) => {
  const groupObj = {
    UPLOAD_PAUSED:
      grouped.UPLOAD_PAUSED === undefined ? [] : grouped.UPLOAD_PAUSED,
    UPLOADING: grouped.UPLOADING === undefined ? [] : grouped.UPLOADING,
    UPLOAD_CANCELED:
      grouped.UPLOAD_CANCELED === undefined ? [] : grouped.UPLOAD_CANCELED,
    UPLOAD_FINISHED:
      grouped.UPLOAD_FINISHED === undefined ? [] : grouped.UPLOAD_FINISHED,
    PROCESSING_QUEUE:
      grouped.PROCESSING_QUEUE === undefined ? [] : grouped.PROCESSING_QUEUE,
    PRE_PROCESSING:
      grouped.PRE_PROCESSING === undefined ? [] : grouped.PRE_PROCESSING,
    INTERVENTION:
      grouped.INTERVENTION === undefined ? [] : grouped.INTERVENTION,
    PROCESSING: grouped.PROCESSING === undefined ? [] : grouped.PROCESSING,
    PROCESSING_CANCELED:
      grouped.PROCESSING_CANCELED === undefined
        ? []
        : grouped.PROCESSING_CANCELED,
    PROCESSING_FAILED:
      grouped.PROCESSING_FAILED === undefined ? [] : grouped.PROCESSING_FAILED,

    QUALITY_ASSURANCE:
      grouped.QUALITY_ASSURANCE === undefined ? [] : grouped.QUALITY_ASSURANCE,
    DECLARE_FAILURE:
      grouped.QUALITY_ASSURANCE === undefined ? [] : grouped.DECLARE_FAILURE,
    PROCESSING_DONE:
      grouped.PROCESSING_DONE === undefined ? [] : grouped.PROCESSING_DONE,
  };
  return groupObj;
};
/*  enum status: {
    # Uploading status
    upload_paused: "UPLOAD_PAUSED",
    uploading: "UPLOADING",
    upload_canceled: "UPLOAD_CANCELED",
    upload_finished: "UPLOAD_FINISHED",

    # After uploding is done
    processing_queue: "PROCESSING_QUEUE",
    pre_processing: "PRE_PROCESSING",
    intervention: "INTERVENTION",
    processing: "PROCESSING",

    # if want to interrupt the processing
    processing_canceled: "PROCESSING_CANCELED",

    # if processing failed
    processing_failed: "PROCESSING_FAILED",
    declare_failure: "DECLARE_FAILURE",

    # if processing is done
    processing_done: "PROCESSING_DONE",
  }*/
export const roundDecimals = (num) => {
  return Math.round((num + Number.EPSILON) * 100) / 100;
};

export function durationAsString(start, end = moment.now()) {
  const duration = moment.duration(end.diff(start));
  //Get Days
  const days = Math.floor(duration.asDays()); // .asDays returns float but we are interested in full days only
  const daysFormatted = days ? `${days}` : "0"; // if no full days then do not display it at all
  //Get Hours
  const hours = duration.hours();
  const hoursFormatted = hours ? `${hours}` : "0";
  //Get Minutes
  const minutes = duration.minutes();
  const minutesFormatted = minutes ? `${minutes}` : "0";
  //Get Minutes
  const seconds = duration.seconds();
  const secondsFormatted = seconds ? `${seconds}` : "0";
  var elapseTimeObj = {
    d: daysFormatted,
    h: hoursFormatted,
    m: minutesFormatted,
    s: secondsFormatted,
  };
  return elapseTimeObj;
}
