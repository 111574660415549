import moment from "moment";

export const getStatusInfo = (statusIn) => {
  var processingStates = [
    { statusString: "UPLOAD_PAUSED", billable: false },
    { statusString: "UPLOADING", billable: false },
    { statusString: "UPLOAD_CANCELED", billable: false },
    { statusString: "UPLOAD_FINISHED", billable: false },
    { statusString: "PROCESSING_QUEUE", billable: false },
    { statusString: "PRE_PROCESSING", billable: false },
    { statusString: "INTERVENTION", billable: false },
    { statusString: "PROCESSING", billable: false },
    { statusString: "PROCESSING_CANCELED", billable: false },
    { statusString: "PROCESSING_FAILED", billable: false },
    { statusString: "DECLARE_FAILURE", billable: false },
    { statusString: "PROCESSING_DONE", billable: true },
  ];

  return processingStates
    .filter((item) => item.statusString === statusIn)
    .map((item) => item);
};

export function defineCycleStatus(start_date, end_date) {
  var dateNow = moment();
  if (start_date <= dateNow && dateNow <= end_date) {
    var isBetween = true;
  } else {
    isBetween = false;
  }
  var diff = end_date.diff(dateNow, "days");
  if (isBetween === true) {
    var cycleStatus = "ABERTO";
  } else {
    if (diff < 0) {
      cycleStatus = "FECHADO";
    } else {
      cycleStatus = "PROGRAMADO";
    }
  }
  return cycleStatus;
}
