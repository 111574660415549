import { gql } from "@apollo/client";

export const PROCESSING_MAP_MUTATION = gql`
  subscription MyQuery($id: uuid) {
    processings(where: { id: { _eq: $id } }) {
      layers {
        id
        data
        layerType
        layerableType
        layerableId
        formatType
        dimensionType
        deletedAt
        createdAt
      }
      name
      location
      processAfterUpload
      status
      submittedAt
      submitted_by_id
      supportNotes
      totalBytes
      totalImages
      totalPixels
      updated_by_id
      updatedAt
      userLocation
      lastReason
      id
      hasGcp
      gcpVerticalCrs
      gcpHorizontalCrs
      gcpCustomHeight
      flownAt
      deleted_by_id
      deletedAt
      created_by_id
      createdAt
      contract_id
      cameraModel
    }
  }
`;
