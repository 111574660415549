import { CopyOutlined, DownOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Descriptions,
  Divider,
  Dropdown,
  Menu,
  PageHeader,
  Row,
  Skeleton,
  Tabs,
  Tag,
  message,
} from "antd";
import moment from "moment";

import { useSubscription } from "@apollo/client";
import { Content } from "antd/lib/layout/layout";
import React, { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import ReactJson from "react-json-view";
import { useLocation } from "react-router";
import { getStatusString } from "../ExportUtils";
import MapExports from "../MapExports/MapExports";
import { EXPORT_DETAIL_SUBSCRIPTION } from "./ExportDetailQuery";

const { TabPane } = Tabs;

const PageContent = ({ state }) => {
  function callback(key) {
    console.log(key);
  }
  const info = (data) => {
    message.info(data);
  };
  const initialStyle = { padding: "24px" };

  const calculateProcessingTime = (params) => {
    console.log(params);
    var processedAt = params.processedAt;
    var createdAt = params.createdAt;
    var diffTime = moment(processedAt).diff(moment(createdAt));
    var f = moment.utc(diffTime).format("HH:mm:ss");
    return f;
  };
  return (
    <div style={initialStyle}>
      <Tabs defaultActiveKey="exports-data" onChange={callback}>
        <TabPane tab="Data" key="exports-data">
          <Row>
            <Descriptions size="small" column={2} colon={false} bordered>
              <Descriptions.Item label={"Request ID"} span={3}>
                <Row gutter={16}>
                  <Col className="gutter-row"> {state.id}</Col>
                  <Col className="gutter-row">
                    <CopyToClipboard text={state.id}>
                      <Button
                        icon={<CopyOutlined />}
                        size="small"
                        onClick={() =>
                          info(`O ID do export foi copiado com sucesso!`)
                        }
                      />
                    </CopyToClipboard>
                  </Col>
                </Row>
              </Descriptions.Item>
              <Descriptions.Item label="Created At" span={3}>
                {moment(state.createdAt).format("DD/MM/YYYY - HH:mm:ss")}
              </Descriptions.Item>
              <Descriptions.Item label="Type" span={3}>
                {state.data}
              </Descriptions.Item>
              <Descriptions.Item label="Format" span={3}>
                {state.format}
              </Descriptions.Item>
              <Descriptions.Item label="Resolution" span={3}>
                {state.resolution == null ? "Native" : state.resolution}
              </Descriptions.Item>
              <Descriptions.Item label="Projection" span={3}>
                {state.projection}
              </Descriptions.Item>
              <Descriptions.Item label="Emails" span={3}>
                {state.emails.join(", ")}
              </Descriptions.Item>
              <Descriptions.Item label="Requested By" span={3}>
                <div>
                  <div>{state.requestedBy.name}</div>
                  <div>{state.requestedBy.email}</div>
                </div>
              </Descriptions.Item>
              <Descriptions.Item label="Processed At" span={3}>
                {state.processedAt == null
                  ? "-"
                  : moment(state.processedAt).format("DD/MM/YYYY - HH:mm:ss")}
              </Descriptions.Item>
              <Descriptions.Item label="Status" span={3}>
                {getStatusString(state.status).statusStringDisplay}
              </Descriptions.Item>
              <Descriptions.Item label="Processing Time (hh:mm:ss)" span={3}>
                {calculateProcessingTime(state)}
              </Descriptions.Item>
              <Descriptions.Item label="Public Url" span={3}>
                <CopyToClipboard text={state.publicUrl}>
                  <Button
                    icon={<CopyOutlined />}
                    size="small"
                    onClick={() => info(`URL copied with success!`)}
                  />
                </CopyToClipboard>
              </Descriptions.Item>
              <Descriptions.Item label="Expires At" span={3}>
                {state.processedAt == null
                  ? "-"
                  : moment(state.expiresAt).format("DD/MM/YYYY - HH:mm:ss")}
              </Descriptions.Item>
              <Descriptions.Item label="Process Error" span={3}>
                {state.processError == null ? "None" : state.processError}
              </Descriptions.Item>
              <Descriptions.Item label="DEM Min" span={3}>
                {state.demMin == null ? "-" : state.processError}
              </Descriptions.Item>
              <Descriptions.Item label="DEM Max" span={3}>
                {state.demMax == null ? "-" : state.processError}
              </Descriptions.Item>

              <Descriptions.Item label={"Project ID"} span={3}>
                <Row gutter={16}>
                  <Col className="gutter-row"> {state.projectId}</Col>
                  <Col className="gutter-row">
                    <CopyToClipboard text={state.projectId}>
                      <Button
                        icon={<CopyOutlined />}
                        size="small"
                        onClick={() =>
                          info(`O ID do Projeto foi copiado com sucesso!`)
                        }
                      />
                    </CopyToClipboard>
                  </Col>
                </Row>
              </Descriptions.Item>
              <Descriptions.Item label={"Processing ID"} span={3}>
                <Row gutter={16}>
                  <Col className="gutter-row"> {state.processingId}</Col>
                  <Col className="gutter-row">
                    <CopyToClipboard text={state.processingId}>
                      <Button
                        icon={<CopyOutlined />}
                        size="small"
                        onClick={() =>
                          info(`O ID do processing foi copiado com sucesso!`)
                        }
                      />
                    </CopyToClipboard>
                  </Col>
                </Row>
              </Descriptions.Item>
              <Descriptions.Item label="Contract Info ID" span={3}>
                {state.processing.contract.account.name == null
                  ? "None"
                  : state.processing.contract.account.name}
              </Descriptions.Item>
            </Descriptions>
            <Divider />
          </Row>
        </TabPane>
        <TabPane tab="Data Raw" key="exports-raw-data">
          {<ReactJson src={state} />}
        </TabPane>
        <TabPane tab="Map" key="exports-map">
          <MapExports dataSource={state} />
        </TabPane>
      </Tabs>
    </div>
  );
};

const PageHeaderComponent = ({ state }) => {
  return <div></div>;
};

const ExportsDetail = () => {
  const exportId = useLocation().pathname.replace("/view-export-details/", "");
  const [dataLoaded, setdataLoaded] = useState(false);
  const [exportsData, setExportsData] = useState([]);

  useSubscription(EXPORT_DETAIL_SUBSCRIPTION, {
    variables: { id: exportId },
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      const processing = data.exports[0];
      console.log(processing);
      setExportsData(processing);
      setdataLoaded(true);
    },
  });

  const menu = (
    <Menu>
      <Menu.Item key="other_rule" disabled>
        3rd menu item（disabled）
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      {!dataLoaded === true ? (
        <Content style={{ margin: "0 16px" }}>
          <div
            className="site-page-header-ghost-wrapper"
            style={{ marginTop: "10px" }}
          ></div>
        </Content>
      ) : (
        <div>
          <Content style={{ margin: "0 16px", backgroundColor: "white" }}>
            <div
              className="site-page-header-ghost-wrapper"
              style={{ marginTop: "10px" }}
            >
              <PageHeader
                ghost={false}
                onBack={() => window.history.back()}
                title={
                  !dataLoaded ? (
                    <Skeleton.Input
                      style={{ width: 100 }}
                      active={true}
                      size={"small"}
                    />
                  ) : (
                    "Export Processing"
                  )
                }
                tags={
                  !dataLoaded ? (
                    <Skeleton.Input
                      style={{ width: 100 }}
                      active={true}
                      size={"small"}
                    />
                  ) : (
                    <Tag color="blue">
                      {getStatusString(exportsData.status).statusStringDisplay}
                    </Tag>
                  )
                }
                extra={[
                  <Dropdown overlay={menu}>
                    <Button
                      className="ant-dropdown-link"
                      onClick={(e) => e.preventDefault()}
                    >
                      Actions <DownOutlined />
                    </Button>
                  </Dropdown>,
                ]}
              >
                <PageHeaderComponent state={exportsData} />
              </PageHeader>
            </div>
            {dataLoaded && <PageContent state={exportsData}></PageContent>}
          </Content>
        </div>
      )}
    </div>
  );
};

export default ExportsDetail;
