import { CheckCircleTwoTone, CloseCircleOutlined } from "@ant-design/icons";
import { Button, Divider, PageHeader, Skeleton, Statistic, Tag } from "antd";

import { useSubscription } from "@apollo/client";
import { Table } from "ant-table-extensions";
import { Content } from "antd/lib/layout/layout";
import _ from "lodash";
import moment from "moment";
import PropTypes from 'prop-types';
import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { PROCESSINGS_MUTATION_LOGS, PROCESSING_LOGS_COUNT } from "./ProcessingLogsQueries";

const GCPCell = ({ data }) => {
  return (
    <div>
      {!data.hasGcp && <CloseCircleOutlined />}
      {data.hasGcp && <CheckCircleTwoTone twoToneColor="#52c41a" />}
    </div>
  );
};

GCPCell.propTypes = {
  data: PropTypes.object.isRequired
};

const ProcessingStatusCell = ({ status }) => {
  const getProcessingTagProperties = (statusString) => {
    const statusDict = [
      {
        statusString: "UPLOAD_PAUSED",
        tagColor: "#5cdbd3",
        fontColor: "#FFFFFF",
      },
      { statusString: "UPLOADING", tagColor: "#5cdbd3", fontColor: "#FFFFFF" },
      {
        statusString: "UPLOAD_CANCELED",
        tagColor: "#f5f5f5",
        fontColor: "#FFFFFF",
      },
      {
        statusString: "UPLOAD_FINISHED",
        tagColor: "#f5f5f5",
        fontColor: "#333333",
      },
      {
        statusString: "PROCESSING_QUEUE",
        tagColor: "#91d5ff",
        fontColor: "#FFFFFF",
      },
      {
        statusString: "PRE_PROCESSING",
        tagColor: "#91d5ff",
        fontColor: "#FFFFFF",
      },
      {
        statusString: "INTERVENTION",
        tagColor: "#ffec3d",
        fontColor: "#333333",
      },
      { statusString: "PROCESSING", tagColor: "#40a9ff", fontColor: "#FFFFFF" },
      {
        statusString: "PROCESSING_CANCELED",
        tagColor: "#ffbb96",
        fontColor: "#FFFFFF",
      },
      {
        statusString: "PROCESSING_FAILED",
        tagColor: "#ff7a45",
        fontColor: "#FFFFFF",
      },
      {
        statusString: "DECLARE_FAILURE",
        tagColor: "#ff4d4f",
        fontColor: "#FFFFFF",
      },
      {
        statusString: "PROCESSING_DONE",
        tagColor: "#73d13d",
        fontColor: "#FFFFFF",
      },
    ];
    var tagProperties = statusDict
      .filter((item) => item.statusString === statusString)
      .map((item) => item)[0];
    return tagProperties;
  };

  return (
    <div>
      <Tag color={getProcessingTagProperties(status).tagColor}>
        <div style={{ color: getProcessingTagProperties(status).fontColor }}>
          {status.replace("_", " ")}
        </div>
      </Tag>
    </div>
  );
};

ProcessingStatusCell.propTypes = {
  status: PropTypes.string.isRequired
};

const ProcessingLogsTable = ({ dataSource, filterSourceData, currentPage, setCurrentPage, pageSize, setPageSize, loading, total }) => {
  const columns = [
    {
      title: "Processing ID",
      width: 400,
      render: (data) => <div>{data.id}<br/>{data.name}</div>,
    },

    {
      title: "Total Images",
      align: "center",
      width: 120,
      render: (data) => <div>{data.totalImages}</div>,
    },
    {
      title: "Account",
      filters: filterSourceData.filterAccounts,
      onFilter: (value, record) =>
        record.contract.account.name.startsWith(value),
      filterSearch: true,
      render: (data) => <div>{data.contract.account.name}</div>,
    },
    {
      title: "Submmited By",
      filters: filterSourceData.filterEmails,
      onFilter: (value, record) => record.submittedBy.email.startsWith(value),
      filterSearch: true,
      render: (data) => <div>{data.submittedBy.email}</div>,
    },
    {
      title: "GCP",
      align: "center",
      render: (data) => <GCPCell data={data} />,
    },
    {
      title: "Flown At",
      dataIndex: "flownAt",
      align: "center",
      width: 120,
      key: "flownAt",
      render: (data) => moment(data).format("DD/MM/YYYY"),
    },
    {
      title: "Submitted At",
      dataIndex: "submittedAt",
      key: "submittedAt",
      align: "center",
      render: (data) => moment(data).format("DD/MM/YYYY"),
    },
    {
      title: "Status",
      align: "center",
      render: (data) => <ProcessingStatusCell status={data.status} />,
    },
    {
      title: "Action",
      align: "center",
      render: (data) => (
        <Button size="small" style={{ fontSize: "12px" }}>
          <Link
            to={{
              pathname: `/view-processing-details/${data.id}`,
              state: { contract: data.id },
            }}
          >
            More Info
          </Link>
        </Button>
      ),
    },
  ];

  const onChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
    if (extra.action === 'paginate') {
      setPageSize(pagination.pageSize)
      setCurrentPage(pagination.current)
    }
  };
  return (
    <div>
      {loading && <div>Loading...</div>}
      <Table
        dataSource={dataSource}
        columns={columns}
        size="small"
        pagination={{ current: currentPage, pageSize: pageSize, total }}
        bordered
        onChange={onChange}
        rowKey={"id"}
      />
    </div>
  );
};

ProcessingLogsTable.propTypes = {
  dataSource: PropTypes.array.isRequired,
  filterSourceData: PropTypes.object.isRequired,
  currentPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
  setPageSize: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  total: PropTypes.number.isRequired,
};

const ProcessingLogs = () => {
  const sectionTitle = "Processing Logs";
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const { data: dataCount } = useSubscription(PROCESSING_LOGS_COUNT);
  const processingsTotal = dataCount?.processingsAggregate?.aggregate?.count || 0;
  const offset = useMemo(() => (currentPage - 1) * pageSize, [currentPage, pageSize]);
  const { loading, data } = useSubscription(PROCESSINGS_MUTATION_LOGS, {variables: {limit: pageSize, offset}});

  const processings = useMemo(() => data?.processings, [data]);
  const filterData = useMemo(() => {
    const accounts = processings?.map(item => ({
      text: item.contract.account.name,
      value: item.contract.account.name,
      key: item.contract.account.name,
    }));
    const emails = processings?.map(item => ({
      text: item.submittedBy.email,
      value: item.submittedBy.email,
      key: item.submittedBy.email,
    }));
    var filterPayload = {
      filterAccounts: _.uniqBy(accounts, "text"),
      filterEmails: _.uniqBy(emails, "text"),
    };
    return filterPayload;
  }, [processings]);

  return (
    <div>
      <Content style={{ margin: "0 16px" }}>
        <div
          className="site-page-header-ghost-wrapper"
          style={{ marginTop: "10px" }}
        >
          <PageHeader
            ghost={false}
            onBack={() => window.history.back()}
            title={sectionTitle}
          >
            {loading && <Skeleton />}
            {!loading && (
              <Statistic
                value={processingsTotal}
                title={"Total Processings"}
              />
            )}
            <Divider />
            {loading && <Skeleton />}
            {!loading && (
              <ProcessingLogsTable
                dataSource={processings}
                filterSourceData={filterData}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                total={processingsTotal}
                loading={loading}
              />
            )}
          </PageHeader>
        </div>
      </Content>
    </div>
  );
};

export default ProcessingLogs;
