import { Content } from "antd/lib/layout/layout";
import { PageHeader } from "antd";
import React from "react";

const FlightPlans = () => {
  const sectionTitle = "Flight Plans";
  return (
    <div>
      <Content style={{ margin: "0 16px" }}>
        <div
          className="site-page-header-ghost-wrapper"
          style={{ marginTop: "10px" }}
        >
          <PageHeader
            ghost={false}
            onBack={() => window.history.back()}
            title={sectionTitle}
          >
            TO-DO
          </PageHeader>
        </div>
      </Content>
    </div>
  );
};

export default FlightPlans;
