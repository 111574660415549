import { gql } from "@apollo/client";

export const CONTRACT_DETAIL_SUBSCRIPTION = gql`
  subscription CONTRACT_DETAIL_SUBSCRIPTION($id: uuid!) {
    contractsByPk(id: $id) {
      account_id
      created_at
      created_by_id
      deleted_at
      deleted_by_id
      ended_at
      exceeded_image_value
      exceeded_seat_value
      exceeded_storage_value
      id
      identifier
      period_in_months
      processing_quota
      seats_quota
      started_at
      storage_quota
      updated_at
      imageUsageEntries(order_by: [{entryDate: asc}, {createdAt: asc}]) {
        __typename
        id
        processingId
        description
        debit
        credit
        entryDate
        createdAt
      }
      imageUsageEntries_aggregate(where: {entryDate: {_lte: "now()"}}) {
        __typename
        aggregate {
          sum {
            credit
            debit
          }
        }
      }
      contractInstallments {
        contractId
        id
        installmentNumber
        invoiceCode
        period
        periodEnd
        periodStart
        reportCloseDate
        statusCode
        scheduledItems {
          contractId
          contractInstallmentId
          contractItemId
          id
          scheduledQuantity
          contractItem {
            contractId
            createdAt
            description
            details
            id
            imageQuota
            isSubscription
            quantity
            unit
            unitValue
            updatedAt
            addedAt
          }
        }
        status {
          code
          color
          description
          id
          # modifiable
        }
      }
      contractItems {
        addedAt
        contractId
        createdAt
        description
        details
        id
        imageQuota
        isSubscription
        quantity
        unit
        unitValue
        updatedAt
      }
      processings {
        cameraModel
        contract_id
        createdAt
        created_by_id
        deletedAt
        deleted_by_id
        flownAt
        gcpCustomHeight
        gcpHorizontalCrs
        gcpVerticalCrs
        hasGcp
        id
        lastReason
        location
        name
        processAfterUpload
        status
        submittedAt
        submitted_by_id
        supportNotes
        totalBytes
        totalImages
        totalPixels
        updatedAt
        updated_by_id
        userLocation
      }
      contractStatus {
        code
        color
        description
        id
      }
      account {
        legal_name
        id
        name
        organization_id
        state
        organization {
          name
          id
        }
      }
    }
  }
`;
