import { gql } from "@apollo/client";

export const INSERT_ITEMS_CONTRACT = gql`
  mutation MyMutation(
    $addedAt: date
    $contractId: uuid
    $description: String
    $details: String
    $quantity: numeric
    $unit: String
    $unitValue: numeric
    $imageQuota: Int
    $isSubscription: Boolean
  ) {
    insertContractItems(
      objects: {
        addedAt: $addedAt
        contractId: $contractId
        description: $description
        details: $details
        quantity: $quantity
        unit: $unit
        unitValue: $unitValue
        imageQuota: $imageQuota
        isSubscription: $isSubscription
      }
    ) {
      affected_rows
    }
  }
`;

export const EDIT_ITEMS_CONTRACT = gql`
  mutation MyMutation($quantity: numeric, $id: uuid) {
    updateContractItems(
      where: { id: { _eq: $id } }
      _set: { quantity: $quantity }
    ) {
      affected_rows
    }
  }
`;
