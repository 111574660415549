import React, { useEffect, useState } from "react";

import { useApolloClient } from "@apollo/client";
import { Spin } from "antd";
import "firebase/auth";
import jwt_decode from 'jwt-decode';
import { CURRENT_USER } from "./Auth/queries";

export const AuthContext = React.createContext();

export const LoadingApp = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        minHeight: "100vh",
      }}
    >
      <Spin />
    </div>
  );
};

export async function getToken(auth) {
  if (auth.currentUser) {
    return await auth.currentUser.getIdToken();
  }
  return null;
}


export const AuthProvider = ({ children, resetStore, clearStore, wsLink, firebaseApp }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [serverUser, setServerUser] = useState(null);
  const [pending, setPending] = useState(true);
  const [, setCurrentSubscription] = useState(null);
  const client = useApolloClient();

  const login = ({ token, rememberMe = false }) => {
    // const decodedToken = jwt_decode(token);
    // console.log(decodedToken, "SERVER");

    if (rememberMe) {
      firebaseApp.auth().setPersistence('local');
    } else {
      firebaseApp.auth().setPersistence('session');
    }

    // auth.tenantId = decodedToken['claims']['accountId'];
    return resetStore()
      .then(() => firebaseApp.auth().signInWithCustomToken(token))
      .then((userCredential) => {
        console.log(userCredential.user.uid)
      })
      .catch((error) => {
        // const errorCode = error.code;
        // const errorMessage = error.message;
        alert(error.message)
      });

    /* TODO: Send error to stack trace collector and only add a error log in console */
    // return resetStore().catch(console.error);
  };

  // const logoutFirebase = () => firebaseApp.auth().signOut()

  const logout = () => {
    // currentSubscription && !currentSubscription.closed && currentSubscription.unsubscribe();
    // console.log('token removed');
    return clearStore().then(() => firebaseApp.auth().signOut()).then(() => {
      wsLink.subscriptionClient.close(true);
      window.location.href = '/auth/login';
      // promiseDelay(500).then(() => {

      // });
    }).catch(console.error);
    /* TODO: Send error to stack trace collector and only add a error log in console */
    // return promiseDelay(500).then(() => {
    //   return clearStore().catch(console.error).then(() => {
    //   });
    // });
  };

  const getUser = async (auth) => {
    try {
      const decodedToken = jwt_decode(await getToken(auth));
      // const now = Math.round((new Date()).getTime() / 1000);
      const {
        "x-hasura-user-id": userId,
      } = decodedToken["https://hasura.io/jwt/claims"]
      if (userId) {
        const query = client.watchQuery({ query: CURRENT_USER, variables: { id: userId } });
        // setCurrentWatch(query);
        setCurrentSubscription(query.subscribe(({ data }) => {
          if (!data.usersByPk) {
            return logout();
          }
          const user = { ...data.usersByPk };
          setServerUser(user);
          // Sentry.setUser({ email: user.email, id: user.id, username: user.name });
        }, (error) => {
          console.log(error);
          logout();
        }));
      } else {
        console.log(error);
        logout();
      }
    } catch (error) {
      console.error(error);
      // logout();
    }
  }

  useEffect(() => {
    const unsubs = firebaseApp.auth().onAuthStateChanged((user) => {
      console.log(user);
      if (user) {
        getUser(firebaseApp.auth());
      }
      setCurrentUser(user);
      // setLoggedUser(user);
      setTimeout(() => {
        setPending(false);
      }, 2000);
    });

    return () => unsubs();
  }, [firebaseApp]);

  if (pending) {
    return (
      <>
        <LoadingApp />
      </>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        serverUser,
        login,
        logout,
        pending
      }}
    >
      {children({ user: currentUser, token: null, loading: pending })}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return React.useContext(AuthContext);
};
