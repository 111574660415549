import { useMutation } from "@apollo/client";
import { Button, Form, Input, Modal, Popconfirm } from "antd";
import React, { useState } from "react";
import { CREATE_ORGANIZATION } from "./OrganizationsQuery";

const NewOrganizationForm = ({ organizationName, setOrganizationName }) => {
  return (
    <div>
      <Form layout={"vertical"}>
        <Form.Item label="Organization Name">
          <Input
            placeholder="Organization Name"
            onChange={(e) => setOrganizationName(e.target.value)}
          />
        </Form.Item>
      </Form>
    </div>
  );
};

const NewOrganization = ({ newOrgModalVisible, setNewOrgModalVisible }) => {
  const [organizationName, setOrganizationName] = useState("");
  const [mutationLoading, setMutationLoading] = useState(false);
  const [, setConfirmVisible] = useState(false);
  const [createOrganization, ] = useMutation(CREATE_ORGANIZATION);
  // const clearStates = () => {
  //   setOrganizationName("");
  // };

  const handleSubmit = (mutationVariables) => {
    alert("Add Mutation ->" + JSON.stringify(mutationVariables));
    setMutationLoading(true);
    createOrganization({
      variables: mutationVariables,
    }).then(ret => {
      alert("SUCCESS");
    }).finally(() => {
      setMutationLoading(false);
      setNewOrgModalVisible(false);
    });
  };

  const handleOk = () => {
    setNewOrgModalVisible(false);
    const mutationVariables = {
      name: organizationName,
    };
    handleSubmit(mutationVariables);
  };

  const handleCancel = () => {
    setNewOrgModalVisible(false);
  };
  return (
    <div>
      <Modal
        title="Adicionar Nova Organização"
        visible={newOrgModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={() => handleCancel()}>
            Return
          </Button>,
          <Popconfirm
            title="Are you sure you want to create this Organization?"
            onConfirm={() => handleOk()}
            onCancel={() => setConfirmVisible(false)}
            okText="Yes"
            cancelText="No"
          >
            <Button
              type="primary"
              loading={mutationLoading}
              onClick={() => setConfirmVisible(true)}
            >
              Submit
            </Button>
          </Popconfirm>,
        ]}
      >
        <NewOrganizationForm
          organizationName={organizationName}
          setOrganizationName={setOrganizationName}
        />
      </Modal>
    </div>
  );
};

export default NewOrganization;
