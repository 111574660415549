import { useQuery } from "@apollo/client";
import { Table } from "ant-table-extensions";
import { Card, Descriptions, Divider, Drawer, Skeleton, Typography } from "antd";
import moment from "moment";
import PropTypes from 'prop-types';
import React, { useMemo } from "react";
import IpPopover from "./IpPopover";
import { USER_DETAIL_SUBSCRIPTION } from "./UsersDetailQueries";

const { Title } = Typography;
const DrawerContent = ({ userData }) => {

  if (!userData) {
    return "WAITING...";
  }

  const columns = [
    {
      title: "IP",
      dataIndex: "currentIp",
      render: (data, record) => {
        // console.log(data, record);
        return <IpPopover ip={data} locationData={record?.locationData}/>
      }
    },
    {
      title: "Last Time",
      dataIndex: "updatedAt",
      render: (data) => {
        return moment(data).utcOffset('-0600').format("DD/MM/YYYY - HH:mm:ss")
      },
    }
  ]

  return (
    <div>
      <Descriptions title="General Info" bordered size="small">
        <Descriptions.Item label="Name" span={4}>
          {userData?.name}
        </Descriptions.Item>
        <Descriptions.Item label="Email" span={4}>
          {userData?.email}
        </Descriptions.Item>
        <Descriptions.Item label="Created At" span={4}>
          {userData?.createdAt}
        </Descriptions.Item>
        <Descriptions.Item label="Industry" span={4}>
          {userData?.industry}
        </Descriptions.Item>
        <Descriptions.Item label="Appearance" span={4}>
          {userData?.appearance}
        </Descriptions.Item>
        <Descriptions.Item label="Selected CRS (EPSG)" span={4}>
          {userData?.coordinateSystem}
        </Descriptions.Item>
        <Descriptions.Item label="Language" span={4}>
          {userData?.language}
        </Descriptions.Item>
        <Descriptions.Item label="Date Format" span={4}>
          {userData?.dateFormat}
        </Descriptions.Item>
        <Descriptions.Item label="Unit System" span={4}>
          {userData?.systemUnit}
        </Descriptions.Item>
        <Descriptions.Item label="Time Format" span={4}>
          {userData?.timeFormat}
        </Descriptions.Item>
        <Descriptions.Item label="Time Zone" span={4}>
          {userData?.timezone}
        </Descriptions.Item>
      </Descriptions>
      <Divider />
      <Title level={5}>User Accounts</Title>
      {(userData?.userAccounts ?? []).map((item, index) => (
        // Sem a `key`, React irá disparar um aviso
        <Card key={index}
          size="small"
          title={item?.account?.name}
          style={{ marginBottom: "10px" }}
        >
          <div>{item?.account?.id}</div>
          <div>{item?.role}</div>
          <div>{item?.account?.document_number}</div>
        </Card>
      ))}
      <Divider />
      <Title level={5}>IP Logins</Title>
      <Table
        dataSource={userData?.trackings}
        columns={columns}
        rowKey="createdAt"
        size="small"
        bordered={true}
        pagination={{ pageSize: "10" }}
      />
    </div>
  );
};

DrawerContent.propTypes = {
  userData: PropTypes.object.isRequired
}

const UserDetail = ({ isDrawerVisible, setDrawerVisible, userId }) => {
  // const [dataLoaded, setdataLoaded] = useState(false);
  // const [userData, setUserData] = useState([]);
  const { loading, error, data } = useQuery(USER_DETAIL_SUBSCRIPTION, {
    variables: { id: userId },
  });

  const onClose = () => {
    setDrawerVisible(false);
    // setdataLoaded(false);
  };

  const user = useMemo(() => data?.users[0], [data]);
  console.log(error);

  return (
    <div>
      <Drawer
        title="User Detailed Information"
        placement="right"
        onClose={onClose}
        width={640}
        visible={isDrawerVisible}
      >
        {loading && (
          <div>
            <Skeleton />
          </div>
        )}
        {!loading && <DrawerContent userData={user} />}
      </Drawer>
    </div>
  );
};

UserDetail.propTypes = {
  isDrawerVisible: PropTypes.bool.isRequired,
  setDrawerVisible: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
}

export default UserDetail;
