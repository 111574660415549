import { Button, Divider, Dropdown, Menu, PageHeader, Skeleton } from "antd";

import { DownOutlined } from "@ant-design/icons";
import { useSubscription } from "@apollo/client";
import { Table } from "ant-table-extensions";
import { Content } from "antd/lib/layout/layout";
import _ from "lodash";
import moment from "moment";
import React, { useMemo, useState } from "react";
import NewOrganization from "./NewOrganization";
import OrganizationDetail from "./OrganizationDetail/OrganizationDetail";
import { ORGANIZATIONS_SUBSCRIPTION } from "./OrganizationsQuery";

const OrganizationsTable = ({ dataSource }) => {
  const [isDrawerVisible, setDrawerVisible] = useState(false);
  const [selectedOrganizationId, setSelectedOrganizationId] = useState(null);

  const handleClickedOrganization = (params) => {
    setSelectedOrganizationId(params);
    setDrawerVisible(true);
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      align: "center",
      render: (data) => moment(data).format("DD/MM/YYYY - HH:mm:ss"),
    },
    {
      title: "Action",
      align: "center",
      width: 100,
      render: (data) => (
        <Button size="small" onClick={() => handleClickedOrganization(data.id)}>
          More Info
        </Button>
      ),
    },
  ];
  return (
    <div>
      <Table
        dataSource={dataSource}
        columns={columns}
        size="small"
        pagination={{ pageSize: "50" }}
      />
      {selectedOrganizationId && (
        <OrganizationDetail
          isDrawerVisible={isDrawerVisible}
          setDrawerVisible={setDrawerVisible}
          organizationId={selectedOrganizationId}
        />
      )}
    </div>
  );
};

const Organizations = () => {
  const sectionTitle = "Organizations";
  const [newOrgModalVisible, setNewOrgModalVisible] = useState(false);
  const { loading, data } = useSubscription(ORGANIZATIONS_SUBSCRIPTION);

  const organizations = useMemo(() => _.orderBy(data?.organizations ?? [], ["createdAt"], ["desc"]).map(item => ({...item, key: item.id})), [data]);

  const menu = (
    <Menu>
      <Menu.Item key="rule_assingment">
        <div onClick={() => setNewOrgModalVisible(true)}>
          Adicionar Nova Organização
        </div>
      </Menu.Item>
      <Menu.Divider key="divider1" />
      <Menu.Item key="other_rule" disabled>
        Example（disabled）
      </Menu.Item>
    </Menu>
  );
  return (
    <div>
      <Content style={{ margin: "0 16px" }}>
        <div
          className="site-page-header-ghost-wrapper"
          style={{ marginTop: "10px" }}
        >
          <PageHeader
            ghost={false}
            onBack={() => window.history.back()}
            title={sectionTitle}
            extra={[
              <Dropdown overlay={menu}>
                <Button
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}
                >
                  Ações <DownOutlined />
                </Button>
              </Dropdown>,
            ]}
          >
            <Divider />
            {loading && <Skeleton />}
            {!loading && <OrganizationsTable dataSource={organizations} />}
          </PageHeader>
        </div>
        <NewOrganization
          newOrgModalVisible={newOrgModalVisible}
          setNewOrgModalVisible={setNewOrgModalVisible}
        />
      </Content>
    </div>
  );
};

export default Organizations;
