import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Switch
} from "antd";
import React, { useEffect, useState } from "react";

import { useMutation } from "@apollo/client";
import moment from "moment";
import NumberFormat from "react-number-format";
import { INSERT_ITEMS_CONTRACT } from "./UtilsItems";

const AddContractItems = ({ visible, onClose, contractId }) => {
  const [insertItemsContact] = useMutation(
    INSERT_ITEMS_CONTRACT
  );
  const [description, setDescription] = useState("");
  const [details, setDetails] = useState("");
  const [unit, setUnit] = useState("");
  const [imageQuota, setimageQuota] = useState(0);
  const [isSubscription, setisSubscription] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const [unitValue, setUnitValue] = useState(0);
  const [totalValue, setTotalValue] = useState(0);
  const [addedAt] = useState(moment().format("YYYY-MM-DD"));
  const [formValidated, setFormValidated] = useState(false);
  const [mutationLoading, setMutationLoading] = useState(false);
  const [, setAlertState] = useState({
    message: "",
    type: "",
    visible: false,
    description: "",
  });
  const handleCancel = () => {
    onClose(false);
  };
  const clearAll = () => {
    setDescription("");
    setDetails("");
    setUnit("");
    setQuantity(0);
    setUnitValue(0);
    setisSubscription(false);
    setimageQuota(0);
  };

  const confirm = () => {
    var newItemObj = {
      addedAt: addedAt,
      contractId: contractId,
      description: description.trim(),
      details: details,
      quantity: quantity,
      unit: unit,
      unitValue: unitValue,
      isSubscription: isSubscription,
      imageQuota: imageQuota,
    };
    console.log(newItemObj);

    performMutation(newItemObj);
  };

  function performMutation(newItemObj) {
    setMutationLoading(true);
    insertItemsContact({ variables: newItemObj })
      .then(() => {
        var alertObj = {
          message: "Sucesso na Criação",
          type: "success",
          visible: true,
          description: "Item Criado com Sucesso!",
        };
        setAlertState(alertObj);
        setTimeout(() => {
          var alertObj = {
            message: "",
            type: "",
            visible: false,
            description: "",
          };
          setAlertState(alertObj);
          setMutationLoading(false);
          clearAll();
          handleCancel();
        }, 3000);
      })
      .catch((error) => {
        console.warn("there was an error sending the query", error);
        var alertObj = {
          message: "Erro na criação do Contato",
          type: "warning",
          visible: true,
          description: error.toString(),
        };
        setAlertState(alertObj);
        setTimeout(() => {
          // var alertObj = {
          //   message: "",
          //   type: "",
          //   visible: false,
          //   description: "",
          // };
          setMutationLoading(false);
        }, 3000);
      });
  }

  const options = [
    { label: "mensal", value: "mensal" },
    { label: "verba", value: "verba" },
    { label: "unidade", value: "unidade" },
  ];

  useEffect(() => {
    if (
      description !== "" &&
      unit !== [] &&
      quantity > 0 &&
      totalValue > 0 &&
      unitValue > 0
    ) {
      setFormValidated(true);
    } else {
      setFormValidated(false);
    }
  }, [quantity, unitValue, description, totalValue, visible, unit]);

  useEffect(() => {
    var vt = quantity * unitValue;
    if (isNaN(vt)) {
      setTotalValue(0);
    } else {
      setTotalValue(vt);
    }
  }, [quantity, unitValue]);

  const onChangeSwitch = () => {
    if (isSubscription) {
      setisSubscription(false);
      setimageQuota(0);
    } else {
      setisSubscription(true);
      setimageQuota(1000);
    }
  };

  var headerStyle = {
    display: "flex",
    justifyContent: "right",
    alignItems: "right",
    padding: "2px",
  };

  return (
    <div>
      <Modal
        title="Editar Item"
        visible={visible}
        width={800}
        onCancel={() => handleCancel()}
        okText="Editar Item"
        cancelText="Cancelar"
        footer={[
          <Button key="back" onClick={() => handleCancel()}>
            Voltar
          </Button>,
          <Popconfirm
            disabled={!formValidated}
            title="Confirma a Criação do Item do Contrato"
            onConfirm={confirm}
            onVisibleChange={() => console.log("visible change")}
          >
            <Button
              type="primary"
              disabled={!formValidated}
              loading={mutationLoading}
            >
              Submeter Novo Item
            </Button>
          </Popconfirm>,
        ]}
      >
        <div style={headerStyle}>
          <Button size="small" onClick={() => clearAll()} danger>
            Limpar tudo
          </Button>
        </div>
        <Form layout={"vertical"} autoComplete="off">
          <Form.Item label="Descrição do Item">
            <Input
              placeholder="Digite a Descrição"
              id="description"
              name="description"
              value={description}
              onChange={(e) => setDescription(e.target.value.toUpperCase())}
            />
          </Form.Item>

          <Row>
            <Col span={12}>
              <Form.Item label="É assinatura">
                <Switch checked={isSubscription} onChange={onChangeSwitch} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Cota de Imagens"
                style={{
                  margin: 0,
                }}
              >
                <NumberFormat
                  id={"imageQuota"}
                  customInput={Input}
                  thousandSeparator="."
                  decimalSeparator=","
                  max={50000}
                  min={1000}
                  disabled={!isSubscription}
                  decimalScale={2}
                  value={imageQuota}
                  style={{ textAlign: "right" }}
                  onValueChange={(values) => {
                    const { value } = values;
                    setimageQuota(parseFloat(value));
                  }}
                  on
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item label="Detalhes do item">
            <Input
              placeholder="Digite os detalhes"
              id="details"
              name="details"
              value={details}
              onChange={(e) => setDetails(e.target.value.toUpperCase())}
            />
          </Form.Item>
          <Row gutter={16}>
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Unit"
                style={{
                  margin: 0,
                }}
              >
                <Select
                  id="unit"
                  name="unit"
                  options={options}
                  onChange={(e) => setUnit(e)}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Quantity"
                style={{
                  margin: 0,
                }}
              >
                <NumberFormat
                  id={"quantity"}
                  customInput={Input}
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={2}
                  value={quantity}
                  style={{ textAlign: "right" }}
                  onValueChange={(values) => {
                    const { value } = values;
                    setQuantity(parseFloat(value));
                  }}
                  on
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label="Valor Unitário"
                style={{
                  margin: 0,
                }}
                rules={[
                  {
                    required: true,
                    message: `Favor Digitar a quantidade`,
                  },
                ]}
              >
                <NumberFormat
                  id={"unitValue"}
                  customInput={Input}
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={2}
                  value={unitValue}
                  prefix={"R$ "}
                  style={{ textAlign: "right" }}
                  onValueChange={(values) => {
                    const { value } = values;
                    setUnitValue(parseFloat(value));
                  }}
                  on
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Input
            style={{ textAlign: "right" }}
            addonBefore=" VALOR TOTAL"
            value={new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(totalValue)}
            disabled
          />
        </Form>
      </Modal>
    </div>
  );
};

export default AddContractItems;
