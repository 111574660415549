import { Button, Dropdown, Menu } from "antd";


import { Card } from "antd";

import { DownOutlined } from "@ant-design/icons";
import React from "react";

const AccountSalesTeam = ({ accountId, accountSalesTeam }) => {
  const menuCardContracts = (
    <Menu>
      <Menu.Item key="rule_assingment">
        <div onClick={() => console.log("Clicado nova Org")}>
          Adicionar Novo Contrato
        </div>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="other_rule" disabled>
        Example（disabled）
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      <Card
        title={"Sales Team"}
        extra={[
          <Dropdown overlay={menuCardContracts}>
            <Button
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
              Ações <DownOutlined />
            </Button>
          </Dropdown>,
        ]}
      ></Card>
    </div>
  );
};

export default AccountSalesTeam;
