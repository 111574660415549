import { get } from "lodash";
import React, { useContext, useMemo } from 'react';
import { Redirect, useLocation } from "react-router";
import { AuthContext } from "../Auth";
import { HOME } from "./constants";

function RequireUnauthentication({ children }) {
  const { currentUser, pending } = useContext(AuthContext);
  const location = useLocation();

  const path = useMemo(() => {
    if (!!currentUser) {
      let to = get(location, 'state.from', HOME);
      if (to.pathname && to.pathname === "/") {
        return "/";
      } else {
        return to.pathname;
      }
    }
    return "/"
  }, [currentUser, location]);

  if (pending) {
    return null;
  }

  return !!currentUser ? <Redirect
    to={{
      pathname: path
    }}
  /> : children;
}

export default RequireUnauthentication;
