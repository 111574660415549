import { gql, useMutation } from "@apollo/client";
import { Button, Col, InputNumber, Row } from "antd";
import React, { useEffect, useState } from "react";

import { DatePicker, notification } from "antd";
import Modal from "antd/lib/modal/Modal";
import moment from "moment";
import { v5 as uuidv5 } from "uuid";

const ADD_INSTALLMENT_MUTATION = gql`
  mutation MyMutation(
    $contractId: uuid
    $id: uuid
    $installmentNumber: Int
    $invoiceCode: String
    $period: String
    $periodEnd: date
    $periodStart: date
    $reportCloseDate: date
    $statusCode: Int
  ) {
    insertContractInstallments(
      objects: {
        id: $id
        installmentNumber: $installmentNumber
        invoiceCode: $invoiceCode
        period: $period
        periodStart: $periodStart
        periodEnd: $periodEnd
        reportCloseDate: $reportCloseDate
        statusCode: $statusCode
        contractId: $contractId
      }
    ) {
      affected_rows
    }
  }
`;

const AddInstallmentModal = ({
  isModalVisible,
  setIsModalVisible,
  contractId,
  invoicesInstallments,
  contractItems,
}) => {
  const [insertNewInstallment] = useMutation(
    ADD_INSTALLMENT_MUTATION
  );
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [mutationLoading, setMutationLoading] = useState(false);
  const [invoiceCode, setInvoiceCode] = useState();
  const [installmentNumber, setInstallmentNumber] = useState(0);
  function leadingZeros(num, places) {
    var zero = places - num.toString().length + 1;
    return Array(+(zero > 0 && zero)).join("0") + num;
  }
  // useEffect(() => {
  //   var totalInvInstalls = invoicesInstallments.length;
  //   setInstallmentNumber(totalInvInstalls + 1);
  //   setInvoiceCode("MED-" + leadingZeros(installmentNumber, 3));
  //   if (totalInvInstalls === 0) {
  //     setStartDate(moment());
  //   } else {
  //     const prevStartDate = invoicesInstallments.sort(
  //       (a, b) => b.installmentNumber - a.installmentNumber
  //     )[0].periodStart;

  //     setStartDate(moment(prevStartDate).add(1, "month"));
  //   }
  //   var tempEndDate = moment(startDate).add(1, "month").subtract("1", "day");
  //   setEndDate(tempEndDate);
  // }, [invoicesInstallments, isModalVisible, installmentNumber, startDate]);

  const insertInstallment = () => {
    const NAMESPACE_CASH_FLOW_INSTALLMENT =
      "6aa1789d-a15a-47d4-9ef9-22cf5b492be6";
    var id_installment = contractId + "-" + installmentNumber;
    var id = uuidv5(id_installment, NAMESPACE_CASH_FLOW_INSTALLMENT);
    var invoiceInstallment = {
      id: id,
      key: id,
      installmentNumber: installmentNumber,
      periodStart: startDate.format("YYYY-MM-DD"),
      periodEnd: endDate.format("YYYY-MM-DD"),
      period: `${startDate.format("DD/MM/YYYY")} - ${endDate.format(
        "DD/MM/YYYY"
      )}`,
      contractId: contractId,
      reportCloseDate: moment(endDate).add(1, "day").format("YYYY-MM-DD"),
      invoiceCode: invoiceCode,
      statusCode: 0,
    };
    submitMutation(invoiceInstallment);
    //insertInstallmentCallBack(invoiceInstallment)
    //setIsModalVisible(false);
  };

  const resetStates = () => {
    setMutationLoading(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const openNotification = (data) => {
    notification.open({
      message: "Sucesso!",
      type: "success",
      description: "A parcela foi inserida com sucesso!",
      onClick: () => {
        console.log("Notification Clicked!");
      },
    });
  };
  const submitMutation = (invoiceInstallment) => {
    setMutationLoading(true);
    console.log(invoiceInstallment);
    var invoiceInstallmentObj = {
      id: invoiceInstallment.id,
      installmentNumber: invoiceInstallment.installmentNumber,
      periodStart: invoiceInstallment.periodStart,
      periodEnd: invoiceInstallment.periodEnd,
      period: invoiceInstallment.period,
      contractId: invoiceInstallment.contractId,
      reportCloseDate: invoiceInstallment.reportCloseDate,
      invoiceCode: invoiceInstallment.invoiceCode,
      statusCode: invoiceInstallment.statusCode,
    };
    console.log(invoiceInstallmentObj);
    insertNewInstallment({ variables: invoiceInstallmentObj })
      .then(({ data }) => {
        setTimeout(() => {
          setMutationLoading(false);
          setIsModalVisible(false);
          resetStates();
          openNotification(data);
        }, 500);
      })
      .catch((error) => {
        console.warn("there was an error sending the query", error);
        resetStates();
      });
  };

  useEffect(() => {
    var tempEndDate = moment(startDate).add(1, "month").subtract("1", "day");
    setEndDate(tempEndDate);
  }, [startDate]);

  return (
    <div>
      <Modal
        title="Adicionando Parcelas"
        visible={isModalVisible}
        footer={[
          <Button key="back" onClick={() => handleCancel()}>
            Return
          </Button>,

          <Button
            loading={mutationLoading}
            type="primary"
            onClick={() => insertInstallment()}
          >
            Inserir parcela
          </Button>,
        ]}
      >
        <Row gutter={16}>
          <Col className="gutter-row" span={8}>
            <div>Periodo</div>
            <InputNumber
              value={invoicesInstallments.length + 1}
              disabled={true}
              onChange={(e) => setInstallmentNumber(e)}
              style={{ width: "100%" }}
            />
          </Col>
          <Col className="gutter-row" span={8}>
            <div>Data Início</div>
            <DatePicker
              value={startDate}
              format={"DD-MM-YYYY"}
              allowClear={false}
              onChange={(e) => setStartDate(e)}
            />
          </Col>
          <Col className="gutter-row" span={8}>
            <div>Data Término</div>
            <DatePicker
              value={endDate}
              format={"DD-MM-YYYY"}
              allowClear={false}
              disabled={true}
              onChange={(e) => setEndDate(e)}
            />
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default AddInstallmentModal;
