import { gql } from "@apollo/client";

export const PROCESSINGS_MUTATION = gql`
  mutation UpdateProcessingStatus($id: uuid!, $pId: uuid!, $status: processing_statuses_enum!, $reason: String!) {
    updateByPkProcessings(pk_columns: {id: $id}, _set: {status: $status}) {
      __typename
      id
    }
    updateByPkProcessingTasks(pk_columns: {id: $pId}, _set: {reason: $reason}) {
      __typename
      id
    }
  }
`;

export const PROCESSINGS_MUTATION_MONITORING = gql`
  subscription MyQuery {
    processings(where: { deletedAt: {_is_null: true }, status: {_in: [UPLOADING, UPLOAD_PAUSED, UPLOAD_FINISHED, PROCESSING_QUEUE, INTERVENTION, PROCESSING, PROCESSING_FAILED]} }) {
      cameraModel
      contract_id
      createdAt
      created_by_id
      deletedAt
      deleted_by_id
      flownAt
      gcpCustomHeight
      gcpHorizontalCrs
      hasGcp
      gcpVerticalCrs
      id
      lastReason
      location
      name
      processAfterUpload
      status
      submittedAt
      submitted_by_id
      supportNotes
      totalBytes
      totalImages
      totalPixels
      updatedAt
      updated_by_id
      userLocation
      imageFiles_aggregate(where: { uploaded: { _eq: true } }) {
        aggregate {
          count
        }
      }
      contract {
        account_id
        account {
          legal_name
          document_number
          name
          organization_id
          id
        }
      }
      processingTasks(limit: 1, order_by: {createdAt: desc}, where: {deletedAt: {_is_null: true}, startedAt: {_is_null: false}}) {
        seqId
        id
        startedAt
        endedAt
      }
    }
  }
`;
