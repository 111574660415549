import {
  ApartmentOutlined,
  DownOutlined,
  GlobalOutlined
} from "@ant-design/icons";
import {
  Badge,
  Button,
  Col,
  Collapse,
  Divider,
  Dropdown,
  Menu,
  Row,
  Steps, Tag,
  Tooltip
} from "antd";
import React, { useEffect, useState } from "react";

import _ from "lodash";
import moment from "moment";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import TaskInfo from "./TaskInfo";

const { Panel } = Collapse;
// const { TabPane } = Tabs;
const { Step } = Steps;

const TaskSteps = ({ taskSteps }) => {
  const [dataProcessed, setDataProcessed] = useState(false);
  const [groupedData, setGroupedData] = useState([]);
  const processTasksSteps = (taskSteps) => {
    var grouped = _.groupBy(taskSteps, "taskGroup");
    setGroupedData(grouped);
    setDataProcessed(true);
    return taskSteps;
  };

  useEffect(() => {
    processTasksSteps(taskSteps);
  }, [taskSteps]);
  // const style = { background: "#0092ff", padding: "8px 0" };

  const statusTranslate = (searchedValue) => {
    const statusDict = [
      { dbName: "NOT_STARTED", badgeStatus: "default" },
      { dbName: "SUCCESS", badgeStatus: "success" },
      { dbName: "NOT_STARTED", badgeStatus: "" },
      { dbName: "NOT_STARTED", badgeStatus: "" },
    ];
    const result = statusDict
      .filter((item) => item.dbName === searchedValue)
      .map((item) => item);
    if (result.length === 0) {
      var retorno = "default";
    } else {
      retorno = result[0].badgeStatus;
    }

    return retorno;
  };

  const calculateDuration = (start, end) => {
    const duration = moment.duration(moment(end).diff(moment(start)));
    //Get Days
    const days = Math.floor(duration.asDays()); // .asDays returns float but we are interested in full days only
    const daysFormatted = days ? `${days}` : "0"; // if no full days then do not display it at all
    //Get Hours
    const hours = duration.hours();
    const hoursFormatted = hours ? `${hours}` : "0";
    //Get Minutes
    const minutes = duration.minutes();
    const minutesFormatted = minutes ? `${minutes}` : "0";
    //Get Minutes
    const seconds = duration.seconds();
    const secondsFormatted = seconds ? `${seconds}` : "0";
    var elapseTimeObj = {
      d: daysFormatted,
      h: hoursFormatted,
      m: minutesFormatted,
      s: secondsFormatted,
    };
    return elapseTimeObj;
  };

  const StepsSummary = ({ sourceData }) => {
    const [current, setcurrent] = useState(null);
    useEffect(() => {
      let pp = _.countBy(
        sourceData.PRE_PROCESS,
        (o) => o.status === "SUCCESS"
      ).true;
      if (pp >= 2) {
        setcurrent(0);
      }
      var step1 = _.countBy(
        sourceData.STEP1,
        (o) => o.status === "SUCCESS"
      ).true;
      if (step1 >= 3) {
        setcurrent(1);
      }
      var step2 = _.countBy(
        sourceData.STEP2,
        (o) => o.status === "SUCCESS"
      ).true;
      if (step2 >= 3) {
        setcurrent(2);
      }
      var step3 = _.countBy(
        sourceData.STEP3,
        (o) => o.status === "SUCCESS"
      ).true;
      if (step3 >= 4) {
        setcurrent(3);
      }
    }, [sourceData]);
    return (
      <div style={{ padding: "30px" }}>
        <Steps progressDot current={current} size="small">
          <Step title={"Step 0"} description={"Pre-Processing"} />
          <Step title={"Step 1"} description={"Calibation"} />
          <Step title={"Step 2"} description={"Densification"} />
          <Step title={"Step 3"} description={"Rasters"} />
        </Steps>
      </div>
    );
  };

  const TooltipStep = ({ tooltipData }) => {
    const calcTotalTime = (data) => {
      if (data.startedAt === null || data.endedAt === null) {
        var totalDuration = "-";
      } else {
        var durationObj = calculateDuration(data.startedAt, data.endedAt);
        totalDuration = `${durationObj.h}h ${durationObj.m}m ${durationObj.s}s`;
      }
      return totalDuration;
    };
    const dividerStyle = {
      borderTop: "1px solid #999999",
      marginBottom: "5px",
      marginTop: "5px",
    };

    const DateTimeFormat = "DD/MM/YYYY - HH:mm:ss";
    const tooltipBodyStyle = { padding: "5px" };

    const statusStringColor = (params) => {
      switch (params) {
        case "SUCCESS":
          var colorString = "green";
          break;
        case "NOT_STARTED":
          colorString = "default";
          break;
        default:
          colorString = "default";
          break;
      }
      return colorString;
    };

    const labelStyle = { fontSize: "10px", color: "#999999" };
    return (
      <div style={tooltipBodyStyle}>
        <div style={labelStyle}>Status</div>
        <Tag color={statusStringColor(tooltipData.status)}>
          {tooltipData.status}
        </Tag>
        <div style={dividerStyle}></div>
        <div style={labelStyle}>Started At</div>
        <div>
          {tooltipData.startedAt === null
            ? "-"
            : moment(tooltipData.startedAt).format(DateTimeFormat)}
        </div>
        <div style={dividerStyle}></div>
        <div style={labelStyle}>Ended At</div>
        <div>
          {tooltipData.startedAt === null
            ? "-"
            : moment(tooltipData.endedAt).format(DateTimeFormat)}
        </div>
        <div style={dividerStyle}></div>
        <div style={{ fontSize: "10px", color: "#999999" }}>Total Time</div>
        <div>{calcTotalTime(tooltipData)}</div>
      </div>
    );
  };
  const stepTitleStyle = {
    fontSize: "16px",
    marginBottom: "10px",
  };

  return (
    <div>
      {dataProcessed && (
        <div>
          <Divider />
          <StepsSummary sourceData={groupedData} />
          <Divider />
          <div>
            <div style={stepTitleStyle}>Pre Processing</div>
            <Row gutter={24}>
              {_.orderBy(groupedData.PRE_PROCESS, ["seq"], ["asc"]).map(
                (step) => (
                  <React.Fragment key={step.id}>
                    <Col className="gutter-row" span={6}>
                      <Tooltip title={<TooltipStep tooltipData={step} />}>
                        <Badge
                          status={statusTranslate(step.status)}
                          text={step.taskName}
                        />
                      </Tooltip>
                    </Col>
                  </React.Fragment>
                )
              )}
            </Row>
          </div>
          <Divider />
          <div>
            <div style={stepTitleStyle}>Step 1</div>
            <Row gutter={24}>
              {_.orderBy(groupedData.STEP1, ["seq"], ["asc"]).map((step) => (
                <React.Fragment key={step.id}>
                  <Col className="gutter-row" span={6}>
                    <Tooltip
                      title={<TooltipStep tooltipData={step} />}
                      style={{ width: "600px" }}
                    >
                      <Badge
                        status={statusTranslate(step.status)}
                        text={step.taskName}
                      />
                    </Tooltip>
                  </Col>
                </React.Fragment>
              ))}
            </Row>
          </div>
          <Divider />
          <div>
            <div style={stepTitleStyle}>Step 2</div>
            <Row gutter={24}>
              {_.orderBy(groupedData.STEP2, ["seq"], ["asc"]).map((step) => (
                <React.Fragment key={step.id}>
                  <Col className="gutter-row" span={6}>
                    <Tooltip
                      title={<TooltipStep tooltipData={step} />}
                      style={{ width: "600px" }}
                    >
                      <Badge
                        status={statusTranslate(step.status)}
                        text={step.taskName}
                      />
                    </Tooltip>
                  </Col>
                </React.Fragment>
              ))}
            </Row>
          </div>
          <Divider />
          <div style={stepTitleStyle}>Step 3</div>
          <Row gutter={24}>
            {_.orderBy(groupedData.STEP3, ["seq"], ["asc"]).map((step) => (
              <React.Fragment key={step.id}>
                <Col className="gutter-row" span={6}>
                  <Tooltip
                    title={<TooltipStep tooltipData={step} />}
                    style={{ width: "600px" }}
                  >
                    <Badge
                      status={statusTranslate(step.status)}
                      text={step.taskName}
                    />
                  </Tooltip>
                </Col>
              </React.Fragment>
            ))}
          </Row>
          <Divider />
        </div>
      )}
    </div>
  );
};

TaskSteps.propTypes = {
  taskSteps: PropTypes.object.isRequired
};

const ProcessingTasks = ({ sourceData, callback }) => {
  // const [dataProcessed, setDataProcessed] = useState(false);

  const openInNewTab = (idWorkFlow) => {
    var url = `https://argo-st2.maply.io/workflows/default/${idWorkFlow}?tab=workflow`;
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const TasksActionsDropDown = ({ task }) => {
    return (
      <div>
        <Menu>
          <Menu.Item key="rule_assingment" icon={<GlobalOutlined />}>
            <Link
              to={{
                pathname: `/view-processing-map/${sourceData.id}`,
                state: { contract: sourceData.id },
              }}
            >
              View Map
            </Link>
          </Menu.Item>
          <Menu.Item
            key="_argo_workflow"
            icon={<ApartmentOutlined />}
            onClick={() =>
              openInNewTab(task.image_processing_output.metadata.name)
            }
          >
            Open Argo WorkFlow
          </Menu.Item>
        </Menu>
      </div>
    );
  };

  TasksActionsDropDown.propTypes = {
    task: PropTypes.object.isRequired
  };

  return (
    <div>
      <Collapse
        defaultActiveKey={["1"]}
        onChange={callback}
        expandIconPosition={"right"}
      >
        {_.orderBy(sourceData.processingTasks, ["item.seqId"], ["asc"]).map(
          (task) => (
            <React.Fragment key={task.id}>
              <Panel
                header={`Task ${task.seqId}`}
                key={task.id}
                extra={[
                  <Dropdown key="arrItem" overlay={<TasksActionsDropDown task={task} />}>
                    <Button
                      size="small"
                      className="ant-dropdown-link"
                      onClick={(event) => {
                        // If you don't want click extra trigger collapse, you can prevent this:
                        event.stopPropagation();
                      }}
                    >
                      Actions <DownOutlined />
                    </Button>
                  </Dropdown>,
                ]}
              >
                <TaskInfo taskData={task} />
                <TaskSteps taskSteps={task.taskSteps} />
              </Panel>
            </React.Fragment>
          )
        )}
      </Collapse>
    </div>
  );
};

ProcessingTasks.propTypes = {
  sourceData: PropTypes.object.isRequired,
  callback: PropTypes.func
};


export default ProcessingTasks;
