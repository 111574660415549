import { gql } from "@apollo/client";

export const EXPORTS_SUBSCRIPTION = gql`
  subscription ExportsSubscription {
    exports(order_by: { createdAt: desc }) {
      contourBase
      createdAt
      cropArea
      crop_area_geometry
      data
      deleteAt
      demMax
      demMin
      downloadsAmount
      emails
      expiresAt
      fileSize
      format
      heightInterval
      id
      minimumVertices
      preeempted
      processError
      processedAt
      processingId
      projectId
      projection
      projection2
      publicUrl
      requestId
      resolution
      sourceType
      status
      updated_at
      requestedById
      processing {
        cameraModel
        contract_id
        createdAt
        created_by_id
        deletedAt
        deleted_by_id
        flownAt
        gcpCustomHeight
        gcpHorizontalCrs
        gcpVerticalCrs
        hasGcp
        id
        name
        userLocation
        updatedAt
        totalPixels
        updated_by_id
        totalImages
        totalBytes
        supportNotes
        submitted_by_id
        submittedAt
        status
        contract {
          identifier
          account {
            legal_name
            id
            name
            organization {
              name
              id
            }
          }
        }
      }
      requestedBy {
        email
        name
      }
    }
  }
`;
